import ApiManager from '../api/ApiManager'
import { AxiosResponse } from 'axios'
import { HttpMethods } from '../enums/HttpMethodsEnum'
import { PrescriptionMonthlyCost } from '../interface/prescriptionDrug/PrescriptionMonthlyCost'
import UrlConstants from '../constants/UrlConstants'
import customerStore from '../datastore/CustomerStore'
import { encrypt } from '../utils/encryptionUtils'

export const getDrugNamesForPrefix = async (payload: any) => {
  const usecase = UrlConstants.GET_RX_DRUG_NAME_SF.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpMethods.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in  ${usecase}:`, error)
        reject(error)
      })
  })
}

export const getDosageForDrugName = async (payload: any) => {
  const usecase = UrlConstants.GET_RX_DOSAGE_FOR_DRUG_NAME_SF.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpMethods.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in  ${usecase}:`, error)
        reject(error)
      })
  })
}

export const addPrescription = async (payload: any) => {
  const usecase = UrlConstants.ADD_PRESCRIPTION_DRUG_SF.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpMethods.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in  ${usecase}:`, error)
        reject(error)
      })
  })
}

export const getPrescriptions = async (payload: any) => {
  const usecase = UrlConstants.GET_PRESCRIPTION_DRUG_SF.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpMethods.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in  ${usecase}:`, error)
        reject(error)
      })
  })
}
export const updatePrescription = async (payload: any) => {
  const usecase = UrlConstants.UPDATE_PRESCRIPTION_DRUG_SF.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpMethods.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in  ${usecase}:`, error)
        reject(error)
      })
  })
}

export const deletePrescription = async (payload: any) => {
  const usecase = UrlConstants.DELETE_PRESCRIPTION_DRUG_SF.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpMethods.DELETE_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in  ${usecase}:`, error)
        reject(error)
      })
  })
}

export const isMillimanResponseProcessing: () => Promise<boolean> =
  async () => {
    return new Promise((resolve, reject) => {
      const checkStatus = () => {
        const usecase =
          UrlConstants.GET_MILLIMAN_PRESCRIPTION_RESPONSE_STATUS.USECASE
        ApiManager.makeApiCallWithAuthentication(
          usecase,
          HttpMethods.GET_METHOD
        )
          .then((response) => {
            if (response.status === 200) {
              customerStore.setMillimanRequestStatus(response.data.data)
              if (response.data.data) {
                setTimeout(() => {
                  checkStatus()
                }, 10000)
              } else {
                resolve(false)
              }
            } else if (response.status === 401) {
              reject(
                'User logged out, hence unable to fetch Milliman Request Status.'
              )
            } else {
              reject('Unable to fetch Prescriptions status')
            }
          })
          .catch((error) => {
            reject(error)
          })
      }
      checkStatus()
    })
  }

export const makeMillimanPrescriptionRequest = async (payload: {
  ssn: string
  firstName: string
  lastName: string
  dateOfBirth: string
}) => {
  let requestBody: any = payload
  let formattedDateOfBirth = new Date(payload.dateOfBirth)
    .toISOString()
    .split('T')[0]
  requestBody.dateOfBirth = formattedDateOfBirth
  requestBody.prefillDrugsRequested = true
  requestBody.signature = 'ESIG'
  requestBody.ssn = encrypt(payload.ssn.replace(/-/g, ''))

  const usecase = UrlConstants.MAKE_MILLIMAN_PRESCRIPTION_ORDER_REQUEST.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpMethods.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in  ${usecase}:`, error)
        reject(error)
      })
  })
}

export const fetchMillimanPrefilledPrescriptions = () => {
  const usecase = UrlConstants.GET_MILLIMAN_PRESCRIPTION_DRUG.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(usecase, HttpMethods.GET_METHOD)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in  ${usecase}:`, error)
        reject(error)
      })
  })
}

export const bulkInsertPrescriptions = (payload: any) => {
  const usecase = UrlConstants.BULK_INSERT_PRESCRIPTION_DRUG.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpMethods.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in  ${usecase}:`, error)
        reject(error)
      })
  })
}

export const fetchPrescriptionMonthlyCostForPlan = (
  planId: string,
  planYear: string,
  forSpouse: boolean
): Promise<PrescriptionMonthlyCost[]> => {
  return new Promise((resolve, reject) => {
    console.log('planId', planId)
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PRESCRIPTION_MONTHLY_COST_PLAN.USECASE,
      HttpMethods.GET_METHOD,
      {
        $planId: planId,
        $planYear: planYear,
        $forSpouse: forSpouse,
      }
    )
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else {
          reject(response.data)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}
