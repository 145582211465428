import { ActionContext } from '../../cra'
import AddSpouseQuestionnaire from './AddSpouseSteps/AddSpouseQuestionnaire'
import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`

const AddSpouse = () => {
  const { trackCurrentPage } = React.useContext(ActionContext)

  React.useEffect(() => {
    trackCurrentPage('Profile - Add Spouse/Partner')
  }, [trackCurrentPage])

  return (
    <Container>
      <AddSpouseQuestionnaire />
    </Container>
  )
}

export default AddSpouse
