import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { CgProfile } from 'react-icons/cg'
import { FiMapPin } from 'react-icons/fi'
import { FiMenu } from 'react-icons/fi'
import { GiHeartPlus } from 'react-icons/gi'
import { IconButton } from '@mui/material'
import { ImFileText2 } from 'react-icons/im'
import LeftNavbar from './LeftNavbar.component'
import PharmacyIcon from '../common/Icon/PharmacyIcon.coponent'
import PrescriptionIcon from '../common/Icon/PrescriptionIcon.component'
import ProviderIcon from '../common/Icon/ProviderIcon.component'
import React from 'react'
import RouteConstants from '../../constants/RouteConstants'
import SecureComponent from '../../pages/common/SecureComponent'
import SettingIcon from '../common/Icon/SettingIcon.component'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useCurrentSubrouteContext } from '../../pages/ProfilePage/ProfilePage'
import { withRouter } from '../common/WithRouter/WithRouter.component'

interface MyProfileSectionContainerProps {
  display?: 'block' | 'none'
  location?: {
    state: {
      from: {
        pathname: string
      }
    }
  }
}

interface MyProfileSectionProps extends MyProfileSectionContainerProps { }

const Container = styled.div<MyProfileSectionContainerProps>`
  display: ${(props) => props.display};
`
const ContentContainer = styled.div`
  display: flex;
`
const Wrapper = styled.div`
  width: 100%;
  padding: 20px 80px;

  @media screen and (max-width: 950px) {
    padding: 20px 40px;
  }

  @media screen and (max-width: 600px) {
    padding: 10px 20px;
  }
`

const SectionHeading = styled.div`
  color: ${theme.colors.textDark};
  display: flex;
  align-items: center;
  position: sticky;
  top: 85px;
  z-index: 500;
  background-color: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.borderColor};
  padding: 10px 0px;
  h2 {
    font-size: 24px;
    font-weight: 600;
  }
  .nav-toggle {
    display: none;
  }
  @media screen and (max-width: 950px) {
    .nav-toggle {
      display: flex;
    }
  }
  @media screen and (max-width: 600px) {
    top: 95px;
    padding: 0px;
    h2 {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 400px) {
    top: 85px;
  }
`

const MyProfileSection: React.FC<MyProfileSectionProps> = (
  props: MyProfileSectionProps
) => {
  const { setCurrentSubRoute, currentSubRoute } = useCurrentSubrouteContext()
  const [activeNav, setActiveNav] = React.useState(currentSubRoute)
  const [showLeftNav, setShowLeftNav] = React.useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  const navbarProps = {
    navLinks: [
      {
        label: 'Profile',
        link: 'profile',
        icon: <CgProfile />,
      },
      {
        label: "Medicare Info",
        link: 'medicare-info',
        icon: <ImFileText2 />
      },
      // {
      //   label: 'Contact & Address',
      //   link: 'contact',
      //   icon: <FiMapPin />,
      // },
      {
        label: 'Health',
        link: 'health',
        icon: <GiHeartPlus />,
      },
      {
        label: 'Prescriptions',
        link: 'prescriptions',
        icon: <PrescriptionIcon color={activeNav === 'prescriptions' ? '#FFFFFF' : undefined} />,
      },
      {
        label: 'Pharmacy',
        link: 'pharmacy',
        icon: <PharmacyIcon color={activeNav === 'pharmacy' ? '#FFFFFF' : undefined} />,
      },
      {
        label: 'Doctors',
        link: 'doctors',
        icon: (
          <ProviderIcon
            color={activeNav === 'doctors' ? '#FFFFFF' : undefined}
          ></ProviderIcon>
        ),
      },
      {
        label: 'Settings',
        link: 'settings',
        icon: (
          <SettingIcon
            color={activeNav === 'settings' ? '#FFFFFF' : undefined}
          ></SettingIcon>
        ),
      },

      //{
      //   label: 'Preferences',
      //   link: 'preferences',
      //   icon: <AiOutlineSetting />,
      // },
    ],
  }

  const activeNavHandler = (link: string) => {
    setShowLeftNav(false)
    setActiveNav(link)
    setCurrentSubRoute(link)
    navigate(link, {
      state: {
        from:
          (props.location &&
            props.location.state &&
            props.location.state.from) ||
          RouteConstants.DASHBOARD,
      },
    })
  }

  React.useEffect(() => {
    const currentScreenLink =
      location.pathname.split('/')[location.pathname.split('/').length - 1]
    if (currentScreenLink !== activeNav) activeNavHandler(currentScreenLink)
  }, [location])

  const showLeftNavHandler = () => {
    setShowLeftNav(!showLeftNav)
  }

  return (
    <Container display={props.display}>
      <ContentContainer>
        <LeftNavbar
          {...navbarProps}
          activeNav={activeNav}
          onClick={activeNavHandler}
          showLeftNavHandler={showLeftNavHandler}
          showLeftNav={showLeftNav}
          isSpouse={false}
        />
        <Wrapper>
          <SectionHeading>
            <IconButton
              className='nav-toggle'
              onClick={() => setShowLeftNav(!showLeftNav)}
            >
              <FiMenu style={{ color: `${theme.colors.primary}` }} />
            </IconButton>
            <h2>
              {
                navbarProps.navLinks.find(
                  (iterator) => iterator.link === activeNav
                )?.label
              }
            </h2>
          </SectionHeading>
          <Outlet />
        </Wrapper>
      </ContentContainer>
    </Container>
  )
}

export default SecureComponent(withRouter(observer(MyProfileSection)))
