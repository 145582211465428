import RadioGroup from '../../../common/RadioGroup/RadioGroup.component'
import React from 'react'
import theme from '../../../../global/theme'

interface RadioQuestionI {
  id: any
  options: any
  value: any
  handleResponseSelection: (id: any, responseArray: any) => void
  radioGroupName?: string
  radioButtonType?: 'squared' | undefined
  subDetails?: string[]
  customerLabel?: React.ReactNode
}

const RadioQuestion: React.FC<RadioQuestionI> = ({
  id,
  options,
  value,
  handleResponseSelection,
  radioGroupName = '',
  radioButtonType = undefined,
  subDetails = [],
  customerLabel = undefined,
}) => {
  const handleRadioChange = (e: any) => {
    handleResponseSelection(id, [e.target.value])
  }

  return (
    <>
      <RadioGroup
        radioButtons={[...options.map((option: any) => option.option)]}
        onChange={handleRadioChange}
        value={value}
        radioGroupName={radioGroupName}
        subDetails={subDetails}
        radioButtonStyle={{
          borderRadius: radioButtonType === 'squared' ? '4px' : '36px',
          background:
            radioButtonType === 'squared'
              ? theme.colors.backgroundColorContainer
              : '',
        }}
        customerLabel={customerLabel}
      />
    </>
  )
}

export default RadioQuestion
