import {
  BenefitSection,
  PackageBenefit,
} from '../../interface/quotes/mNonSupplementPlans/common/PackageBenefit'
import {
  IconButton,
  Rating,
  Tab,
  Table,
  TableBody,
  TableHead,
  Tabs,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { SlArrowDown, SlArrowUp } from 'react-icons/sl'

import Button from '../common/Button/Button.component'
import CarrierLogo from '../common/CarrierLogo'
import CloseIconButton from '../common/Button/CloseIconButton.component'
import { FiMenu } from 'react-icons/fi'
import { MSPlanGenericDisplayDetail } from '../../interface/common/MSPlanGenericDisplayDetail'
import { MSPlansRiderType } from '../../interface/quotes/mSupplementPlans/MSPlansRiderType'
import { MedicareQuotesType } from '../../enums/MedicareQuoteTypeEnum'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableRow from '@mui/material/TableRow'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { RiderTypesEnum } from '../../enums/RiderTypesEnum'
import { convertToUSCurrency } from '../../utils/CurrencyConverterUtil'
import eipLogo from '../../assets/vectors/eipLogo.svg'
import styled from '@emotion/styled'
import theme from '../../global/theme'

interface LeftNavbarContainerProps {
  showNav?: boolean
}

const Wrapper = styled.div`
  .burger-wrapper {
    display: none;
    margin-bottom: 20px;
    @media screen and (max-width: 950px) {
      display: flex;
      align-items: center;
      padding: 5px 20px 5px 0px;
      position: sticky;
      top: 85px;
      h3 {
        font-size: 14px;
      }
      z-index: 100;
      background-color: ${theme.colors.white};
      width: 100%;
      color: ${theme.colors.primary};
    }

    @media print {
      display: none;
    }
  }
`

const Overlay = styled.div<LeftNavbarContainerProps>`
  @media screen and (max-width: 950px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: ${({ showNav }) => (showNav ? 'block' : 'none')};
    transition: background-color 0.3s ease;
  }
  @media print {
    display: none;
  }
`

const Container = styled.div<LeftNavbarContainerProps>`
  display: flex;
  min-height: 100vh;
  .sidebar-wrapper {
    width: 350px;
    box-shadow: 10px 0px 20px rgba(0, 0, 0, 0.05);
    background-color: ${theme.colors.white};
    height: calc(100vh - 279px);
    position: sticky;
    top: 85px;
    overflow-y: auto;
    .sidebar {
      padding: 20px;
    }

    @media screen and (max-width: 950px) {
      position: fixed;
      display: ${({ showNav }) => (showNav ? 'block' : 'none')};
      min-width: 0px;
      max-width: 80%;
      width: 80%;
      z-index: 1001;
      top: 0;
      height: 100%;
      padding-top: 50px;
    }

    @media print {
      display: none;
    }
  }
  .content-wrapper {
    flex: 1;
    padding: 50px;
    height: calc(100vh - 279px); /* Adjust based on header/footer height */
    overflow: auto;

    @media screen and (max-width: 950px) {
      padding: 20px;
    }
  }
`
const PrintWrapper = styled.div`
  display:none;
  flex: 1;
  padding: 50px;
  height: calc(100vh - 279px); /* Adjust based on header/footer height */
  overflow: auto;

  /* Media query for screen sizes <= 950px */
  @media screen and (max-width: 950px) {
    padding: 20px;
  }

  @media print {
    @page {
      margin:20mm;
    }
  }


`
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 0px;
  display: none;
  @media screen and (max-width: 900px) {
    display: block;
  }
  @media print {
    display: none;
  }
`

const SectionWrapper = styled.div`
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 30px;
  h3 {
    background-color: ${theme.colors.primary};
    color: ${theme.colors.textLight};
    padding: 15px 16px;
  }
`

const TableRow = styled(MuiTableRow)`
  &:nth-of-type(odd) {
    background-color: ${theme.colors.backgroundColorContainer};
  }
`

const TableCell = styled(MuiTableCell)`
  align-content: start;
  &.MuiTableCell-root {
    width: ${(props) => (props.width ? props.width : '50%')};
  }
`

interface TableRecordType {
  key: string
  value: string
}

const DetailSection: React.FC<{
  overview: any
  planBenefits: PackageBenefit[] | undefined
  msPlanGenericDetails: MSPlanGenericDisplayDetail | undefined
  planType: MedicareQuotesType
  riders?: MSPlansRiderType[]
  planName?: string
  carrierLogoName: string
  printComponentRef: React.MutableRefObject<null>
}> = ({
  overview,
  planBenefits,
  msPlanGenericDetails,
  planType,
  riders,
  planName,
  carrierLogoName,
  printComponentRef
}) => {
    const [overviewTableRecords, setOverviewTableRecords] = useState<
      TableRecordType[]
    >([])
    const [medicalBenfits, setMedicalBenefits] = useState<BenefitSection[]>([])
    const [dvhBenefits, setDvhBenefits] = useState<BenefitSection[]>([])
    const [addonBenefits, setAddonBenefits] = useState<BenefitSection[]>([])
    const [prescriptionDrugBenefits, setPrescriptionDrugBenefits] = useState<any>(
      []
    )
    const [currentTab, setCurrentTab] = React.useState<any>(0)
    const [valueType, setValueType] = React.useState<string>('30')
    const ridersEntries = Object.entries(RiderTypesEnum)
    const [showLeftNav, setShowLeftNav] = React.useState(false)
    const [activeSection, setActiveSection] = useState<string | null>(
      'section-overview'
    )
    const [showMedicalHeaders, setShowMedicalHeaders] = useState(false)
    const [showDvhHeaders, setShowDvhHeaders] = useState(false)
    const [showAddonHeaders, setShowAddonHeaders] = useState(false)

    const medicalHeadersSF = useMemo(
      () => [
        'Ambulance Services',
        'Diagnostic Tests, Lab and Radiology Services, and X-Rays',
        'Renal Dialysis',
        'Doctor Office Visits',
        'Doctor Specialty Visit',
        'Emergency Care',
        'Urgently Needed Care',
        'Inpatient Hospital Care',
        'Durable Medical Equipment',
        'Outpatient Mental Health Care',
        'Inpatient Mental Health Care',
        'Outpatient Rehabilitation Services',
      ],
      []
    )

    const dvhHeadersSF = useMemo(
      () => ['Hearing Services', 'Dental Services', 'Vision Services'],
      []
    )

    const addonHeadersSF = useMemo(
      () => [
        'Transportation',
        'Preventive Services and Wellness/Education Programs',
        'Additional Services',
        'Chiropractic Services',
        'Diabetes Supplies and Services',
        'Home Health Care',
        'Outpatient Prescription Drugs',
        'Outpatient Services/Surgery',
        'Outpatient Substance Abuse',
        'Over-the-Counter Items',
        'Podiatry Services',
        'Prosthetic Devices',
        'Skilled Nursing Facility (SNF)',
      ],
      []
    )

    const scrollToSection = (sectionId: string) => {
      const section = document.getElementById(sectionId)
      const contentWrapper = document.querySelector('.content-wrapper')

      if (section && contentWrapper) {
        // Calculate the position of the section relative to the scrollable container
        const sectionOffsetTop =
          section.getBoundingClientRect().top -
          contentWrapper.getBoundingClientRect().top +
          contentWrapper.scrollTop // Include current scroll position of the container

        contentWrapper.scrollTo({ top: sectionOffsetTop, behavior: 'smooth' })
      }
    }

    const createPlanOverview = (overview: any) => {
      const tempOverviewTableRecords: { key: string; value: string }[] = []
      if (overview.monthlyPremium) {
        tempOverviewTableRecords.push({
          key: 'Monthly Premium',
          value: convertToUSCurrency(overview.monthlyPremium),
        })
      }
      if (overview.fmtPremium) {
        tempOverviewTableRecords.push({
          key: 'Monthly plan premium',
          value: overview.fmtPremium,
        })
      }
      if (overview.fmtMedicalDeductible) {
        tempOverviewTableRecords.push({
          key: 'Medical deductible',
          value: overview.fmtMedicalDeductible,
        })
      }
      if (overview.oonMoop) {
        tempOverviewTableRecords.push({
          key: 'Out-of-network maximum out-of-pocket',
          value:
            overview.oonMoop === -1
              ? 'N/A'
              : convertToUSCurrency(overview.oonMoop),
        })
      }
      if (overview.moop) {
        tempOverviewTableRecords.push({
          key: 'In-network maximum out-of-pocket',
          value:
            overview.moop === -1 ? 'N/A' : convertToUSCurrency(overview.moop),
        })
      }
      if (overview.combMoop) {
        tempOverviewTableRecords.push({
          key: 'Combined maximum out-of-pocket',
          value:
            overview.combMoop === -1
              ? 'N/A'
              : convertToUSCurrency(overview.combMoop),
        })
      }
      if (overview.fmtDeductible) {
        tempOverviewTableRecords.push({
          key: 'Drug deductible',
          value: overview.fmtDeductible,
        })
      }
      if (overview.initialCoverageLimit) {
        tempOverviewTableRecords.push({
          key: 'Initial coverage limit',
          value: convertToUSCurrency(overview.initialCoverageLimit),
        })
      }
      if (overview.catastrophicLimit) {
        tempOverviewTableRecords.push({
          key: 'Catastrophic coverage limit',
          value: convertToUSCurrency(overview.catastrophicLimit),
        })
      }
      if (overview.rating) {
        tempOverviewTableRecords.push({
          key: 'Overall Stars',
          value: overview.rating,
        })
      }
      return tempOverviewTableRecords
    }

    const createPlanBenefits = (planBenefits: PackageBenefit[]) => {
      planBenefits.sort((a: any, b: any) => {
        if (a.type < b.type) {
          return -1
        }
        if (a.type > b.type) {
          return 1
        }
        return 0
      })

      let DrugBenefitsArray = planBenefits.filter(
        (item: any) => item.type === 'drug-cost-sharing'
      )
      let transformedDrugBenefits: any = []

      DrugBenefitsArray.forEach((item: any) => {
        let nameSplit = item.name.split(' - ')
        let drugBenefitName = nameSplit[0]
        let subname = nameSplit[1]

        let existingDrugBenefitName = transformedDrugBenefits.find(
          (obj: any) => obj.name === drugBenefitName
        )
        if (!existingDrugBenefitName) {
          existingDrugBenefitName = {
            name: drugBenefitName,
            tiers: [],
          }
          transformedDrugBenefits.push(existingDrugBenefitName)
        }

        item.benefits.forEach((benefit: any) => {
          let existingTier = existingDrugBenefitName.tiers.find(
            (tier: any) => tier.name === benefit.categoryName
          )
          if (!existingTier) {
            existingTier = {
              name: benefit.categoryName,
              categoryDesc: benefit.categoryDesc,
              subnames: [],
            }
            existingDrugBenefitName.tiers.push(existingTier)
          }

          let existingDrugBenefitSubname = existingTier.subnames.find(
            (sub: any) => sub.name === subname
          )
          if (!existingDrugBenefitSubname) {
            existingDrugBenefitSubname = {
              name: subname,
              benefits: [],
            }
            existingTier.subnames.push(existingDrugBenefitSubname)
          }

          existingDrugBenefitSubname.benefits.push(benefit)
        })
        existingDrugBenefitName.tiers.sort((a: any, b: any) => {
          const tierA = parseInt(a.name.replace('Tier ', ''))
          const tierB = parseInt(b.name.replace('Tier ', ''))
          return tierA - tierB
        })
      })

      const medicalBenefits: BenefitSection[] = []
      const dvhBenefits: BenefitSection[] = []
      const addonBenefits: BenefitSection[] = []

      planBenefits.forEach((planBenefit: any) => {
        if (planBenefit.type === 'benefit') {
          planBenefit.benefits.forEach((benefit: any) => {
            if (medicalHeadersSF.includes(benefit.categoryName)) {
              const existingBenefit = medicalBenefits.find(
                (medicalBenefit: any) =>
                  medicalBenefit.categoryName === benefit.categoryName
              )
              if (existingBenefit) {
                ; (existingBenefit as any).value += benefit.value
              } else {
                medicalBenefits.push(benefit)
              }
            } else if (dvhHeadersSF.includes(benefit.categoryName)) {
              const existingBenefit = dvhBenefits.find(
                (dvhBenefit: any) =>
                  dvhBenefit.categoryName === benefit.categoryName
              )
              if (existingBenefit) {
                ; (existingBenefit as any).value += benefit.value
              } else {
                dvhBenefits.push(benefit)
              }
            } else {
              const existingBenefit = addonBenefits.find(
                (addonBenefit: any) =>
                  addonBenefit.categoryName === benefit.categoryName
              )
              if (existingBenefit) {
                ; (existingBenefit as any).value += benefit.value
              } else {
                addonBenefits.push(benefit)
              }
            }
          })
        }
      })

      medicalBenefits.sort((a: any, b: any) =>
        a.categoryName.localeCompare(b.categoryName)
      )

      dvhBenefits.sort((a: any, b: any) =>
        a.categoryName.localeCompare(b.categoryName)
      )

      addonBenefits.sort((a: any, b: any) =>
        a.categoryName.localeCompare(b.categoryName)
      )

      return {
        medicalBenefits,
        dvhBenefits,
        addonBenefits,
        transformedDrugBenefits,
      }
    }

    const handleChangeDaySupply = (e: any, newValue: number) => {
      setCurrentTab(newValue)
      newValue === 0
        ? setValueType('30')
        : newValue === 1
          ? setValueType('60')
          : setValueType('90')
    }

    useEffect(() => {
      const tempOverviewTableRecords = createPlanOverview(overview)
      setOverviewTableRecords(tempOverviewTableRecords)
    }, [overview])

    useEffect(() => {
      if (planBenefits) {
        const {
          medicalBenefits,
          dvhBenefits,
          addonBenefits,
          transformedDrugBenefits,
        } = createPlanBenefits(planBenefits)
        setMedicalBenefits(medicalBenefits)
        setDvhBenefits(dvhBenefits)
        setAddonBenefits(addonBenefits)
        setPrescriptionDrugBenefits(transformedDrugBenefits)
      }
    }, [planBenefits])

    // useEffect(() => {
    //   setTimeout(() => {
    //     const sectionElements = [
    //       'overview',
    //       'plan-benefit',
    //       ...medicalHeadersSF,
    //       ...dvhHeadersSF,
    //       ...addonHeadersSF,
    //     ].map((header) =>
    //       document.getElementById(`section-${header.replace(/\s+/g, '-')}`)
    //     )

    //     const observer = new IntersectionObserver(
    //       (entries) => {
    //         entries.forEach((entry) => {
    //           if (entry.isIntersecting) {
    //             setActiveSection(entry.target.id)
    //           }
    //         })
    //       },
    //       {
    //         root: null,
    //         rootMargin: '0px 0px -100px 0px',
    //         threshold: 0.5,
    //       }
    //     )

    //     sectionElements.forEach((section) => {
    //       if (section) {
    //         observer.observe(section)
    //       }
    //     })

    //     return () => {
    //       sectionElements.forEach((section) => {
    //         if (section) {
    //           observer.unobserve(section)
    //         }
    //       })
    //     }
    //   }, 100)
    // }, [addonHeadersSF, dvhHeadersSF, medicalHeadersSF])

    return (
      <Wrapper>
        <Overlay showNav={showLeftNav} />
        <div className='burger-wrapper'>
          <IconButton
            className='burger'
            onClick={() => setShowLeftNav(!showLeftNav)}
          >
            <FiMenu style={{ color: `${theme.colors.primary}` }} />
          </IconButton>
          <h3>{planName}</h3>
        </div>
        <Container showNav={showLeftNav}>
          <div className='sidebar-wrapper'>
            <div className='sidebar'>
              <CloseButtonWrapper>
                <CloseIconButton
                  fontSize='32px'
                  onClick={() => setShowLeftNav(!showLeftNav)}
                />
              </CloseButtonWrapper>
              <Button
                width='100%'
                borderRadius='4px'
                justifyContent='flex-start'
                variant={
                  activeSection === `section-overview` ? 'contained' : 'text'
                }
                onClick={() => {
                  scrollToSection('section-overview')
                  setActiveSection('section-overview')
                  setShowLeftNav(!showLeftNav)
                }}
                bold={false}
              >
                <span style={{ textAlign: 'left' }}>Overview</span>
              </Button>
              {addonBenefits.find(
                (benefit: any) => benefit.categoryName === 'Plan Notes'
              ) && (
                  <Button
                    width='100%'
                    borderRadius='4px'
                    justifyContent='flex-start'
                    variant={
                      activeSection === `section-Plan-Notes` ? 'contained' : 'text'
                    }
                    onClick={() => {
                      scrollToSection(`section-Plan-Notes`)
                      setActiveSection(`section-Plan-Notes`)
                      setShowLeftNav(!showLeftNav)
                    }}
                    bold={false}
                  >
                    <span style={{ textAlign: 'left' }}>Plan Notes</span>
                  </Button>
                )}
              {planType === MedicareQuotesType.MEDICARE_SUPPLEMENT ? (
                <Button
                  width='100%'
                  borderRadius='4px'
                  justifyContent='flex-start'
                  variant={
                    activeSection === `section-plan-benefit`
                      ? 'contained'
                      : 'text'
                  }
                  onClick={() => {
                    scrollToSection('section-plan-benefit')
                    setActiveSection('section-plan-benefit')
                    setShowLeftNav(!showLeftNav)
                  }}
                  bold={false}
                >
                  <span style={{ textAlign: 'left' }}>Plan Details</span>
                </Button>
              ) : (
                <>
                  {medicalBenfits.length > 0 && (
                    <Button
                      width='100%'
                      borderRadius='4px'
                      justifyContent='flex-start'
                      variant='text'
                      onClick={() => {
                        setShowMedicalHeaders(!showMedicalHeaders)
                      }}
                      bold={false}
                    >
                      <span
                        style={{
                          textAlign: 'left',
                          display: 'flex',
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        Medical{' '}
                        {showMedicalHeaders ? <SlArrowUp /> : <SlArrowDown />}
                      </span>
                    </Button>
                  )}
                  {showMedicalHeaders && (
                    <div style={{ paddingLeft: '20px' }}>
                      {medicalHeadersSF
                        .sort()
                        .filter((header) =>
                          medicalBenfits.find(
                            (benefit: any) => benefit.categoryName === header
                          )
                        )
                        .map((header) => (
                          <Button
                            width='100%'
                            borderRadius='4px'
                            justifyContent='flex-start'
                            variant={
                              activeSection ===
                                `section-${header.replace(/\s+/g, '-')}`
                                ? 'contained'
                                : 'text'
                            }
                            onClick={() => {
                              scrollToSection(
                                `section-${header.replace(/\s+/g, '-')}`
                              )
                              setActiveSection(
                                `section-${header.replace(/\s+/g, '-')}`
                              )
                              setShowLeftNav(!showLeftNav)
                            }}
                            bold={false}
                          >
                            <span style={{ textAlign: 'left' }}>{header}</span>
                          </Button>
                        ))}
                    </div>
                  )}
                  {dvhBenefits.length > 0 && (
                    <Button
                      width='100%'
                      borderRadius='4px'
                      justifyContent='flex-start'
                      variant='text'
                      onClick={() => {
                        setShowDvhHeaders(!showDvhHeaders)
                      }}
                      bold={false}
                    >
                      <span
                        style={{
                          textAlign: 'left',
                          display: 'flex',
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        DVH {showDvhHeaders ? <SlArrowUp /> : <SlArrowDown />}
                      </span>
                    </Button>
                  )}
                  {showDvhHeaders && (
                    <div style={{ paddingLeft: '20px' }}>
                      {dvhHeadersSF
                        .sort()
                        .filter((header) =>
                          dvhBenefits.find(
                            (benefit: any) => benefit.categoryName === header
                          )
                        )
                        .map((header) => (
                          <Button
                            width='100%'
                            borderRadius='4px'
                            justifyContent='flex-start'
                            variant={
                              activeSection ===
                                `section-${header.replace(/\s+/g, '-')}`
                                ? 'contained'
                                : 'text'
                            }
                            onClick={() => {
                              scrollToSection(
                                `section-${header.replace(/\s+/g, '-')}`
                              )
                              setActiveSection(
                                `section-${header.replace(/\s+/g, '-')}`
                              )
                              setShowLeftNav(!showLeftNav)
                            }}
                            bold={false}
                          >
                            <span style={{ textAlign: 'left' }}>{header}</span>
                          </Button>
                        ))}
                    </div>
                  )}
                  {addonBenefits.length > 1 && (
                    <Button
                      width='100%'
                      borderRadius='4px'
                      justifyContent='flex-start'
                      variant='text'
                      onClick={() => {
                        setShowAddonHeaders(!showAddonHeaders)
                      }}
                      bold={false}
                    >
                      <span
                        style={{
                          textAlign: 'left',
                          display: 'flex',
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        Add-Ons{' '}
                        {showAddonHeaders ? <SlArrowUp /> : <SlArrowDown />}
                      </span>
                    </Button>
                  )}
                  {showAddonHeaders && (
                    <div style={{ paddingLeft: '20px' }}>
                      {addonHeadersSF
                        .sort()
                        .filter((header) =>
                          addonBenefits.find(
                            (benefit: any) => benefit.categoryName === header
                          )
                        )
                        .map((header) => (
                          <Button
                            width='100%'
                            borderRadius='4px'
                            justifyContent='flex-start'
                            variant={
                              activeSection ===
                                `section-${header.replace(/\s+/g, '-')}`
                                ? 'contained'
                                : 'text'
                            }
                            onClick={() => {
                              scrollToSection(
                                `section-${header.replace(/\s+/g, '-')}`
                              )
                              setActiveSection(
                                `section-${header.replace(/\s+/g, '-')}`
                              )
                              setShowLeftNav(!showLeftNav)
                            }}
                            bold={false}
                          >
                            <span style={{ textAlign: 'left' }}>{header}</span>
                          </Button>
                        ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className='content-wrapper'>
            <SectionWrapper id='section-overview'>
              <h3>Overview</h3>
              <Table>
                {overviewTableRecords.map((row, index) => (
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell
                      key={'key' + index}
                      sx={{ fontWeight: 600, color: theme.colors.textDark }}
                    >
                      {row['key']}
                    </TableCell>
                    <TableCell
                      key={'value' + index}
                      sx={{ color: theme.colors.textGrey }}
                    >
                      {row['key'] === 'Overall Stars' ? (
                        <Rating
                          name='simple-controlled'
                          value={Number(row.value)}
                          precision={0.5}
                          readOnly
                        />
                      ) : (
                        row['value']
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </SectionWrapper>
            {planType === MedicareQuotesType.MEDICARE_SUPPLEMENT &&
              riders &&
              riders.length > 0 && (
                <SectionWrapper key={'Riders'} id={'Riders'}>
                  <ReactVisibilitySensor partialVisibility={false}>
                    <>
                      <h3>Riders</h3>
                      <Table>
                        <TableHead>
                          <MuiTableRow>
                            <MuiTableCell
                              sx={{
                                fontWeight: 700,
                                color: `${theme.colors.primary}`,
                                fontSize: '14px',
                                '&.MuiTableCell-root': {
                                  width: '50%',
                                },
                              }}
                            >
                              Type
                            </MuiTableCell>
                            <MuiTableCell
                              sx={{
                                backgroundColor: `${theme.colors.backgroundColorContainerDark}`,
                                fontWeight: 700,
                                color: `${theme.colors.primary}`,
                                fontSize: '14px',
                                '&.MuiTableCell-root': {
                                  width: '50%',
                                },
                              }}
                            >
                              Annual Cost
                            </MuiTableCell>
                          </MuiTableRow>
                        </TableHead>
                        {riders
                          .sort((a, b) => {
                            const name1 =
                              ridersEntries.find(
                                ([key, val]) => key === a.name
                              )?.[1] || a.name
                            const name2 =
                              ridersEntries.find(
                                ([key, val]) => key === b.name
                              )?.[1] || b.name
                            return name1.localeCompare(name2)
                          })
                          .map((row, index) => (
                            <MuiTableRow
                              key={'rider' + index}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                              }}
                            >
                              <MuiTableCell
                                key={'name' + index}
                                sx={{
                                  fontWeight: 700,
                                  color: `${theme.colors.textGrey}`,
                                  fontSize: '14px',
                                  '&.MuiTableCell-root': {
                                    width: '50%',
                                  },
                                }}
                              >
                                {ridersEntries.find(
                                  ([key, val]) => key === row['name']
                                )?.[1] || row['name']}
                              </MuiTableCell>
                              <MuiTableCell
                                key={'annualCost' + index}
                                sx={{
                                  backgroundColor: `${theme.colors.backgroundColorContainer}`,
                                  fontWeight: 700,
                                  color: `${theme.colors.textGrey}`,
                                  fontSize: '14px',
                                  '&.MuiTableCell-root': {
                                    width: '50%',
                                  },
                                }}
                              >
                                {convertToUSCurrency(row['annual'])}
                              </MuiTableCell>
                            </MuiTableRow>
                          ))}
                      </Table>
                    </>
                  </ReactVisibilitySensor>
                </SectionWrapper>
              )}
            {medicalBenfits.map((benefit: any, index) => {
              const planBenefitsElements = (
                <SectionWrapper
                  id={`section-${benefit.categoryName.replace(/\s+/g, '-')}`}
                >
                  <>
                    <h3>{benefit.categoryName}</h3>
                    <Table>
                      <TableRow
                        key={'benefits'}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                            background: theme.colors.white,
                          },
                        }}
                      >
                        {benefit.value !== 'N/A' && (
                          <TableCell
                            dangerouslySetInnerHTML={{
                              __html: benefit.value,
                            }}
                            sx={{
                              color: theme.colors.textGrey,
                            }}
                          />
                        )}
                      </TableRow>
                    </Table>
                  </>
                </SectionWrapper>
              )
              return planBenefitsElements
            })}
            {dvhBenefits.map((benefit: any, index) => {
              const planBenefitsElements = (
                <SectionWrapper
                  id={`section-${benefit.categoryName.replace(/\s+/g, '-')}`}
                >
                  <>
                    <h3>{benefit.categoryName}</h3>
                    <Table>
                      <TableRow
                        key={'benefits'}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                            background: theme.colors.white,
                          },
                        }}
                      >
                        {benefit.value !== 'N/A' && (
                          <TableCell
                            dangerouslySetInnerHTML={{
                              __html: benefit.value,
                            }}
                            sx={{
                              color: theme.colors.textGrey,
                            }}
                          />
                        )}
                      </TableRow>
                    </Table>
                  </>
                </SectionWrapper>
              )
              return planBenefitsElements
            })}
            {addonBenefits.map((benefit: any, index) => {
              const planBenefitsElements = (
                <SectionWrapper
                  id={`section-${benefit.categoryName.replace(/\s+/g, '-')}`}
                >
                  <>
                    <h3>{benefit.categoryName}</h3>
                    <Table>
                      <TableRow
                        key={'benefits'}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                            background: theme.colors.white,
                          },
                        }}
                      >
                        {benefit.value !== 'N/A' && (
                          <TableCell
                            dangerouslySetInnerHTML={{
                              __html: benefit.value,
                            }}
                            sx={{
                              color: theme.colors.textGrey,
                            }}
                          />
                        )}
                      </TableRow>
                    </Table>
                  </>
                </SectionWrapper>
              )
              return planBenefitsElements
            })}

            {prescriptionDrugBenefits.length > 0 && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Tabs
                  value={currentTab}
                  onChange={handleChangeDaySupply}
                  variant='scrollable'
                  scrollButtons='auto'
                >
                  {['30 Days Supply', '60 Days Supply', '90 Days Supply'].map(
                    (tabLabel, index) => (
                      <Tab
                        disableRipple
                        key={index}
                        label={tabLabel}
                        className={`fixed-width-tab ${index === currentTab ? '' : 'hide-mobile-tabs'
                          }`}
                      />
                    )
                  )}
                </Tabs>
              </div>
            )}
            {prescriptionDrugBenefits.length > 0 &&
              prescriptionDrugBenefits.map(
                (prescriptionBenefit: any, index: any) => {
                  const planBenefitsElements = (
                    <SectionWrapper style={{ marginTop: '20px' }}>
                      <>
                        <h3>{prescriptionBenefit.name}</h3>
                        <Table>
                          {prescriptionBenefit.tiers.length > 0 && (
                            <>
                              {prescriptionBenefit.tiers.map((tier: any) => {
                                return (
                                  <>
                                    <TableRow
                                      key={index}
                                      sx={{
                                        '&:last-child td, &:last-child th': {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        style={{
                                          textTransform: 'capitalize',
                                          fontWeight: 'bold',
                                          background: theme.colors.lightBack,
                                        }}
                                      >
                                        {tier.name}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          textTransform: 'capitalize',
                                          fontWeight: 'bold',
                                          background: theme.colors.lightBack,
                                        }}
                                      >
                                        {tier.categoryDesc}
                                      </TableCell>
                                    </TableRow>
                                    {tier.subnames.map((subname: any) => (
                                      <TableRow
                                        key={'benefits'}
                                        sx={{
                                          '&:last-child td, &:last-child th': {
                                            border: 0,
                                            background: theme.colors.white,
                                          },
                                        }}
                                      >
                                        <TableCell
                                          style={{
                                            textTransform: 'capitalize',
                                            fontWeight: 600,
                                            color: theme.colors.textDark,
                                          }}
                                        >
                                          {subname.name}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            textTransform: 'capitalize',
                                            color: theme.colors.textGrey,
                                          }}
                                        >
                                          {
                                            subname.benefits.find(
                                              (item: any) =>
                                                item.valueType === valueType
                                            ).value
                                          }
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </>
                                )
                              })}
                            </>
                          )}
                        </Table>
                      </>
                    </SectionWrapper>
                  )
                  return planBenefitsElements
                }
              )}
            {msPlanGenericDetails && (
              <SectionWrapper id='section-plan-benefit'>
                <ReactVisibilitySensor partialVisibility={false}>
                  <>
                    <h3>Plan Details</h3>
                    <Table>
                      <TableBody>
                        <TableRow>
                          {msPlanGenericDetails.planDetail.map(
                            (planDetails, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <div
                                        style={{
                                          width: '100%',
                                          color: theme.colors.primary,
                                          backgroundColor:
                                            theme.colors.contrastPrimary,
                                          fontWeight: '700',
                                          padding: '15px',
                                        }}
                                      >
                                        {planDetails.partType}
                                      </div>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <Table>
                                      {planDetails.partTypeDetails.map(
                                        (partTypeDetail) => (
                                          <TableRow>
                                            <TableCell
                                              sx={{
                                                fontWeight: 600,
                                                color: theme.colors.textDark,
                                              }}
                                            >
                                              {partTypeDetail.key}
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                color: theme.colors.textGrey,
                                              }}
                                            >
                                              {partTypeDetail.value}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </Table>
                                  </TableBody>
                                </Table>
                              </TableRow>
                            )
                          )}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </>
                </ReactVisibilitySensor>
              </SectionWrapper>
            )}
          </div>

          <PrintWrapper>
            <div ref={printComponentRef}>

              <div className='printable-header'>
                <div className='eip-logo' style={{ display: 'flex', justifyContent: 'end' }}>
                  <img src={eipLogo} alt='logo' />
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', gap: '20px', marginBottom: '20px' }} >
                <div>
                  <CarrierLogo height='40px' width='118px' carrier={carrierLogoName} useAltLogo={true} />
                </div>
                <h2>{planName}</h2>
              </div>

              <SectionWrapper id='section-overview'>
                <h3>Overview</h3>
                <Table>
                  {overviewTableRecords.map((row, index) => (
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell
                        key={'key' + index}
                        sx={{ fontWeight: 600, color: theme.colors.textDark }}
                      >
                        {row['key']}
                      </TableCell>
                      <TableCell
                        key={'value' + index}
                        sx={{ color: theme.colors.textGrey }}
                      >
                        {row['key'] === 'Overall Stars' ? (
                          <Rating
                            name='simple-controlled'
                            value={Number(row.value)}
                            precision={0.5}
                            readOnly
                          />
                        ) : (
                          row['value']
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </SectionWrapper>
              {planType === MedicareQuotesType.MEDICARE_SUPPLEMENT &&
                riders &&
                riders.length > 0 && (
                  <SectionWrapper key={'Riders'} id={'Riders'}>
                    <ReactVisibilitySensor partialVisibility={false}>
                      <>
                        <h3>Riders</h3>
                        <Table>
                          <TableHead>
                            <MuiTableRow>
                              <MuiTableCell
                                sx={{
                                  fontWeight: 700,
                                  color: `${theme.colors.primary}`,
                                  fontSize: '14px',
                                  '&.MuiTableCell-root': {
                                    width: '50%',
                                  },
                                }}
                              >
                                Type
                              </MuiTableCell>
                              <MuiTableCell
                                sx={{
                                  backgroundColor: `${theme.colors.backgroundColorContainerDark}`,
                                  fontWeight: 700,
                                  color: `${theme.colors.primary}`,
                                  fontSize: '14px',
                                  '&.MuiTableCell-root': {
                                    width: '50%',
                                  },
                                }}
                              >
                                Annual Cost
                              </MuiTableCell>
                            </MuiTableRow>
                          </TableHead>
                          {riders
                            .sort((a, b) => {
                              const name1 =
                                ridersEntries.find(
                                  ([key, val]) => key === a.name
                                )?.[1] || a.name
                              const name2 =
                                ridersEntries.find(
                                  ([key, val]) => key === b.name
                                )?.[1] || b.name
                              return name1.localeCompare(name2)
                            })
                            .map((row, index) => (
                              <MuiTableRow
                                key={'rider' + index}
                                sx={{
                                  '&:last-child td, &:last-child th': { border: 0 },
                                }}
                              >
                                <MuiTableCell
                                  key={'name' + index}
                                  sx={{
                                    fontWeight: 700,
                                    color: `${theme.colors.textGrey}`,
                                    fontSize: '14px',
                                    '&.MuiTableCell-root': {
                                      width: '50%',
                                    },
                                  }}
                                >
                                  {ridersEntries.find(
                                    ([key, val]) => key === row['name']
                                  )?.[1] || row['name']}
                                </MuiTableCell>
                                <MuiTableCell
                                  key={'annualCost' + index}
                                  sx={{
                                    backgroundColor: `${theme.colors.backgroundColorContainer}`,
                                    fontWeight: 700,
                                    color: `${theme.colors.textGrey}`,
                                    fontSize: '14px',
                                    '&.MuiTableCell-root': {
                                      width: '50%',
                                    },
                                  }}
                                >
                                  {convertToUSCurrency(row['annual'])}
                                </MuiTableCell>
                              </MuiTableRow>
                            ))}
                        </Table>
                      </>
                    </ReactVisibilitySensor>
                  </SectionWrapper>
                )}
              {medicalBenfits.map((benefit: any, index) => {
                const planBenefitsElements = (
                  <SectionWrapper
                    id={`section-${benefit.categoryName.replace(/\s+/g, '-')}`}
                  >
                    <>
                      <h3>{benefit.categoryName}</h3>
                      <Table>
                        <TableRow
                          key={'benefits'}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                              background: theme.colors.white,
                            },
                          }}
                        >
                          {benefit.value !== 'N/A' && (
                            <TableCell
                              dangerouslySetInnerHTML={{
                                __html: benefit.value,
                              }}
                              sx={{
                                color: theme.colors.textGrey,
                              }}
                            />
                          )}
                        </TableRow>
                      </Table>
                    </>
                  </SectionWrapper>
                )
                return planBenefitsElements
              })}
              {dvhBenefits.map((benefit: any, index) => {
                const planBenefitsElements = (
                  <SectionWrapper
                    id={`section-${benefit.categoryName.replace(/\s+/g, '-')}`}
                  >
                    <>
                      <h3>{benefit.categoryName}</h3>
                      <Table>
                        <TableRow
                          key={'benefits'}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                              background: theme.colors.white,
                            },
                          }}
                        >
                          {benefit.value !== 'N/A' && (
                            <TableCell
                              dangerouslySetInnerHTML={{
                                __html: benefit.value,
                              }}
                              sx={{
                                color: theme.colors.textGrey,
                              }}
                            />
                          )}
                        </TableRow>
                      </Table>
                    </>
                  </SectionWrapper>
                )
                return planBenefitsElements
              })}
              {addonBenefits.map((benefit: any, index) => {
                const planBenefitsElements = (
                  <SectionWrapper
                    id={`section-${benefit.categoryName.replace(/\s+/g, '-')}`}
                  >
                    <>
                      <h3>{benefit.categoryName}</h3>
                      <Table>
                        <TableRow
                          key={'benefits'}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                              background: theme.colors.white,
                            },
                          }}
                        >
                          {benefit.value !== 'N/A' && (
                            <TableCell
                              dangerouslySetInnerHTML={{
                                __html: benefit.value,
                              }}
                              sx={{
                                color: theme.colors.textGrey,
                              }}
                            />
                          )}
                        </TableRow>
                      </Table>
                    </>
                  </SectionWrapper>
                )
                return planBenefitsElements
              })}

              {prescriptionDrugBenefits.length > 0 && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Tabs
                    value={currentTab}
                    onChange={handleChangeDaySupply}
                    variant='scrollable'
                    scrollButtons='auto'
                  >
                    {['30 Days Supply', '60 Days Supply', '90 Days Supply'].map(
                      (tabLabel, index) => (
                        <Tab
                          disableRipple
                          key={index}
                          label={tabLabel}
                          className={`fixed-width-tab ${index === currentTab ? '' : 'hide-mobile-tabs'
                            }`}
                        />
                      )
                    )}
                  </Tabs>
                </div>
              )}
              {prescriptionDrugBenefits.length > 0 &&
                prescriptionDrugBenefits.map(
                  (prescriptionBenefit: any, index: any) => {
                    const planBenefitsElements = (
                      <SectionWrapper style={{ marginTop: '20px' }}>
                        <>
                          <h3>{prescriptionBenefit.name}</h3>
                          <Table>
                            {prescriptionBenefit.tiers.length > 0 && (
                              <>
                                {prescriptionBenefit.tiers.map((tier: any) => {
                                  return (
                                    <>
                                      <TableRow
                                        key={index}
                                        sx={{
                                          '&:last-child td, &:last-child th': {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell
                                          style={{
                                            textTransform: 'capitalize',
                                            fontWeight: 'bold',
                                            background: theme.colors.lightBack,
                                          }}
                                        >
                                          {tier.name}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            textTransform: 'capitalize',
                                            fontWeight: 'bold',
                                            background: theme.colors.lightBack,
                                          }}
                                        >
                                          {tier.categoryDesc}
                                        </TableCell>
                                      </TableRow>
                                      {tier.subnames.map((subname: any) => (
                                        <TableRow
                                          key={'benefits'}
                                          sx={{
                                            '&:last-child td, &:last-child th': {
                                              border: 0,
                                              background: theme.colors.white,
                                            },
                                          }}
                                        >
                                          <TableCell
                                            style={{
                                              textTransform: 'capitalize',
                                              fontWeight: 600,
                                              color: theme.colors.textDark,
                                            }}
                                          >
                                            {subname.name}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              textTransform: 'capitalize',
                                              color: theme.colors.textGrey,
                                            }}
                                          >
                                            {
                                              subname.benefits.find(
                                                (item: any) =>
                                                  item.valueType === valueType
                                              ).value
                                            }
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </>
                                  )
                                })}
                              </>
                            )}
                          </Table>
                        </>
                      </SectionWrapper>
                    )
                    return planBenefitsElements
                  }
                )}
              {msPlanGenericDetails && (
                <SectionWrapper id='section-plan-benefit'>
                  <ReactVisibilitySensor partialVisibility={false}>
                    <>
                      <h3>Plan Details</h3>
                      <Table>
                        <TableBody>
                          <TableRow>
                            {msPlanGenericDetails.planDetail.map(
                              (planDetails, index) => (
                                <TableRow
                                  key={index}
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <div
                                          style={{
                                            width: '100%',
                                            color: theme.colors.primary,
                                            backgroundColor:
                                              theme.colors.contrastPrimary,
                                            fontWeight: '700',
                                            padding: '15px',
                                          }}
                                        >
                                          {planDetails.partType}
                                        </div>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <Table>
                                        {planDetails.partTypeDetails.map(
                                          (partTypeDetail) => (
                                            <TableRow>
                                              <TableCell
                                                sx={{
                                                  fontWeight: 600,
                                                  color: theme.colors.textDark,
                                                }}
                                              >
                                                {partTypeDetail.key}
                                              </TableCell>
                                              <TableCell
                                                sx={{
                                                  color: theme.colors.textGrey,
                                                }}
                                              >
                                                {partTypeDetail.value}
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                      </Table>
                                    </TableBody>
                                  </Table>
                                </TableRow>
                              )
                            )}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </>
                  </ReactVisibilitySensor>
                </SectionWrapper>
              )}
            </div>
          </PrintWrapper>
        </Container>
      </Wrapper>
    )
  }

export default DetailSection
