import Label, { SubLabel } from '../Label/Label'

import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import React from 'react'
import { fireEvent } from '../../../cra'
import theme from '../../../global/theme'

const checkboxStyles = {
  backgroundColor: `${theme.colors.white}`,
  border: `1px solid ${theme.colors.borderColor}`,
  borderRadius: '4px',
  padding: '0px 20px 0px 5px',
  margin: 0,
  flex: 1,
}

const checkboxUnstyledStyles = {}

interface ChecboxGroupProps {
  formLabel?: string
  checkboxState: {
    id: string | number
    checked: boolean
    label: JSX.Element | number | string
    value?: string | number
    disabled?: boolean
  }[]
  row?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  width?: string
  type?: 'default' | 'unstyled'
  checkboxGroupName?: string
  addBottomBorderSeparator?: boolean
  readOnly?: boolean
  bottomPadding?: boolean
  label?: string
  subLabel?: string
  checkboxStyle?: React.CSSProperties
  optionsGap?: string
}

const CheckboxGroup: React.FC<ChecboxGroupProps> = ({
  formLabel,
  onChange,
  row,
  checkboxState,
  width,
  type = 'default',
  checkboxGroupName = '',
  addBottomBorderSeparator = false,
  readOnly = false,
  bottomPadding = true,
  label,
  subLabel,
  checkboxStyle,
  optionsGap = '10px',
}) => {
  // const checkboxes = Object.keys(checkboxState)

  // this handle change function may be used in parent and sent as a callback(onChange) to this component.
  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const eventValue = e.target.value
  //   const updatedCheckStatus = {
  //     ...checkStatus,
  //     [eventValue]: !checkStatus[eventValue],
  //   }
  //   setCheckStatus(updatedCheckStatus)
  //   console.log(updatedCheckStatus)
  // }
  return (
    <FormControl
      sx={
        readOnly
          ? {
              width: `${width}`,
              pointerEvents: 'none',
              paddingBottom: bottomPadding ? '20px' : 0,
            }
          : {
              width: `${width}`,
              paddingBottom: bottomPadding ? '20px' : 0,
            }
      }
    >
      {label && <Label style={{ paddingBottom: '5px' }}>{label}</Label>}
      {subLabel && (
        <SubLabel style={{ paddingBottom: '5px' }}>{subLabel}</SubLabel>
      )}
      <FormLabel
        sx={{
          fontStyle: 'italic',
          fontSize: '14px',
          color: theme.colors.secondary,
        }}
      >
        {formLabel}
      </FormLabel>
      <FormGroup
        onChange={onChange}
        row={row ? row : false}
        sx={{ gap: optionsGap }}
      >
        {checkboxState.map((checkbox, index) => (
          <FormControlLabel
            key={checkbox.id}
            control={
              <Checkbox
                onChange={(e) => {
                  fireEvent('checkboxInput', e, {
                    label: checkboxGroupName,
                    value: checkbox.value ? checkbox.value : checkbox.label,
                    description: `${
                      checkbox.checked ? 'Unchecked' : 'Checked'
                    } option ${checkbox.label} in ${checkboxGroupName}`,
                  })
                }}
                style={readOnly ? { color: theme.colors.disabled } : {}}
                size='small'
                disableRipple={readOnly}
              />
            }
            label={<span style={{ fontSize: '14px' }}>{checkbox.label}</span>}
            value={checkbox.value ? checkbox.value : checkbox.label}
            checked={checkbox.checked}
            sx={
              type === 'default'
                ? { ...checkboxStyles, ...checkboxStyle }
                : checkboxUnstyledStyles
            }
            style={
              addBottomBorderSeparator && index < checkboxState.length - 1
                ? {
                    borderBottom: `0.5px solid ${theme.colors.borderColor}`,
                    position: 'relative',
                  }
                : { position: 'relative' }
            }
            disabled={checkbox.disabled ? true : false}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

export default CheckboxGroup
