import React from 'react'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface TabLabelProps {
  tabLabel: tabLabel
}

type tabLabel = {
  heading: string
  subHeading?: string
  subHeading2?: string
}

const Container = styled.div`
  h4 {
    font-size: 16px;
    color: ${theme.colors.primary};
  }
  p {
    color: ${theme.colors.primary};
    padding-top: 5px;
    font-size: 12px;
    font-weight: 600;
    font-style: italic;
  }
`

const TabLabel: React.FC<TabLabelProps> = ({ tabLabel }) => {
  return (
    <Container>
      <h4>{tabLabel.heading}</h4>
      {tabLabel.subHeading && <p>{tabLabel.subHeading}</p>}
      {tabLabel.subHeading2 && <p>{tabLabel.subHeading2}</p>}
    </Container>
  )
}

export default TabLabel
