import BouncingDotsLoader from '../Loading/BouncingDotsLoader'
import React from 'react'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

const Container = styled.div`
  width: 100%;
  background-color: ${theme.colors.lightBack};
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: white;
  h3 {
    color: ${theme.colors.primary};
    text-align: center;
  }
`

const QuotesPulledStatus: React.FC = () => {
  return (
    <Container>
      <h3>Preparing quotes</h3>
      <BouncingDotsLoader />
    </Container>
  )
}

export default QuotesPulledStatus
