import { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { isEmpty, isNil, isNull } from 'lodash'

import AddMNSPlanDetails from '../../components/DashboardPage/AddMNSPlanDetails'
import AddSpouseConfirmationModal from '../../components/DashboardPage/Modals/AddSpouseConfirmationModal'
import ApiManager from '../../api/ApiManager'
import CompleteYourProfileNotification from '../../components/DashboardPage/Common/TopNotificationChipComponents/CompleteYourProfile'
import ContactCard from '../../components/common/ContactContainer'
import GeneralConstants from '../../constants/GeneralConstants'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import InPageHeader from '../../components/common/InPageHeader/InPageHeader.component'
import { InfoStepEnum } from '../../enums/InfoStepEnum'
import InfoStepsPrompt from '../../components/DashboardPage/Modals/InfoStepsPrompt'
import LayoutComponent from '../../components/common/Layout/Layout.component'
import { MNonSupplementPlan } from '../../interface/quotes/mNonSupplementPlans/MNonSupplementPlan'
import { MSPlan } from '../../interface/quotes/mSupplementPlans/MSPlan'
import MedicareAdvantageSection from '../../components/DashboardPage/PageSections/MedicareAdvantage/MedicareAdvantageSection'
import MedicarePrescription from '../../components/DashboardPage/PageSections/MedicarePrescription/MedicarePrescription'
import { MedicareQuotesType } from '../../enums/MedicareQuoteTypeEnum'
import MedicareSupplement from '../../components/DashboardPage/PageSections/MedicareSupplement/MedicareSupplement'
import ModalComponent from '../../components/common/Modal/Modal.component'
import NewPasswordModal from '../../components/DashboardPage/Modals/NewPasswordModal'
import NewPasswordNotification from '../../components/DashboardPage/Common/TopNotificationChipComponents/NewPasswordNotification'
import OverlayLoader from '../../components/common/OverlayLoader/OverlayLoader'
import PaginationConstants from '../../constants/PaginationConstants'
import { PreferenceCoverageShortNameEnum } from '../../enums/PreferenceCoverageShortNameEnum'
import { QuotesPullStatus } from '../../interface/quotes/QuotesPullStatus'
import RouteConstants from '../../constants/RouteConstants'
import { SFLeadInterface } from '../../interface/salesforce/SFLeadInterface'
import SecureComponent from '../common/SecureComponent'
import { SnackbarTypes } from '../../enums/SnackbarTypesEnum'
import StringConstants from '../../constants/StringConstants'
import ThankYouModal from '../../components/DashboardPage/Modals/ThankYouModal'
import TopNotificationChip from '../../components/common/Notification/TopNotificationChip.component'
import UrlConstants from '../../constants/UrlConstants'
import { addSpouseDetails } from '../../service/customer'
import customerStore from '../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../utils/StringUtils'
import { getPlanCount } from '../../service/quotes'
import { getPlanYear } from '../../utils/CommonUtils'
import mPartDPlansFilterStore from '../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import msPlanFiltersStore from '../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import { observer } from 'mobx-react'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import userActionStore from '../../datastore/UserActionStore'

const Container = styled.div`
  min-height: 100vh;
  background-color: ${theme.colors.white};
  position: relative;
`

const ThreeDotsWrapper = styled.div`
  display: flex;
  margin: 20px;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 20px 0px;
  }
`

const DashboardPage = () => {
  const [resPassModalActive, setResPassModalActive] = useState<boolean>(false)
  const [effectiveDateChanged, setEffectiveDateChanged] =
    useState<boolean>(false)
  const [infoStepsModal, setInfoStepsModal] = useState<{
    active: boolean
    redirect: string | null
  }>({
    active: false,
    redirect: null,
  })

  const [resPassChipActive, setResPassChipActive] = useState<boolean>(false)
  const [completeProfileChipActive, setCompleteProfileChipActive] =
    useState<boolean>(false)
  const [
    openEffectiveDateFilterConfirmationPopup,
    setOpenEffectiveDateFilterConfirmationPopup,
  ] = useState<boolean>(false)

  const [maPlans, setMAPlans] = useState<MNonSupplementPlan[]>([])
  const [maPlanTotalCount, setMATotalPlanCount] = useState<number | null>(null)
  const [msPlans, setMSPlans] = useState<MSPlan[]>([])
  const [msPlanTotalCount, setMSTotalPlanCount] = useState<number | null>(null)
  const [mPartDPlans, setMPartDPlans] = useState<MNonSupplementPlan[]>([])
  const [mPartDPlanTotalCount, setMPartDPlanCount] = useState<number | null>(
    null
  )
  const [showAddSpouseConfirmation, setShowAddSpouseConfirmation] =
    useState<boolean>(false)
  const [addSpouseDetailsLoader, setAddSpouseDetailsLoader] =
    useState<boolean>(false)
  const [thankYouModalActive, setThankYouModalActive] = useState(false)

  const handleMAPlansData = (data: MNonSupplementPlan[]) => {
    setMAPlans(data)
  }

  const handleMSPlansData = (data: MSPlan[]) => {
    setMSPlans(data)
  }

  const handleMPartDData = (data: MNonSupplementPlan[]) => {
    setMPartDPlans(data)
  }

  const [maPlanMessage, setMAPlanErrorMessage] = useState<string>('')
  const [msPlanMessage, setMSPlanErrorMessage] = useState<string>('')
  const [mPartDPlanMessage, setMPatDPlanErrorMessage] = useState<string>('')
  const [type, setType] = useState<string>('')
  const [isQuotesStatusPulled, setIsQuotesStatusPulled] =
    useState<boolean>(false)

  const defaultPaginationDetail: { page: number; size: number } = {
    page: PaginationConstants.DEFAULT_PAGE,
    size: PaginationConstants.DEFAULT_PAGE_SIZE,
  }

  const timerRef: { current: NodeJS.Timeout | null } = useRef(null)
  const isMounted = useRef(true)
  const initiateTimer = () => {
    timerRef.current = setTimeout(() => {
      fetchQuotesPullStatus(getPlanYear())
    }, 15000)
  }

  const [currentTab, setCurrentTab] = useState<number>(-1)

  const updatePriorityCallback = (message: string) => {
    const requestBody: SFLeadInterface = {
      callbackLogic: message,
      leadAppStage: 'Quoting Tool',
      leadAppSubstage: 'Dashboard',
    }

    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.SF_CALLBACK_LEAD_INFO_UPDATE.USECASE,
      HttpMethods.PATCH_METHOD,
      requestBody
    ).catch((err: Error) => {})
  }

  const fetchQuotesPullStatus = (planYear?: number) => {
    getPlanCount(planYear)
      .then((quotesPullStatus: QuotesPullStatus) => {
        // if (response.status === 200) {
        !medicareAdvantageFilterQuoteStore.isPlanFetched() &&
          !isNil(quotesPullStatus.maPlan) &&
          !medicareAdvantageFilterQuoteStore.isFiltersActive() &&
          setMATotalPlanCount(quotesPullStatus.maPlan)
        !msPlanFiltersStore.isPlanFetched() &&
          !isNil(quotesPullStatus.msPlan) &&
          !msPlanFiltersStore.isFiltersActive() &&
          setMSTotalPlanCount(quotesPullStatus.msPlan)

        !mPartDPlansFilterStore.isPlanFetched() &&
          !isNil(quotesPullStatus.maPlan) &&
          !mPartDPlansFilterStore.isFiltersActive() &&
          setMPartDPlanCount(quotesPullStatus.mPartD)

        // TODO Remove the plans fetched from store
        // !medicareAdvantageFilterQuoteStore.isPlanFetched() &&
        //   !isNil(response.maPlan) &&
        //   medicareAdvantageFilterQuoteStore.setTotalCount(
        //     response.maPlan
        //   )
        // !msPlanFiltersStore.isPlanFetched() &&
        //   !isNil(response.msPlan) &&
        //   msPlanFiltersStore.setTotalCount(response.msPlan)

        // !mPartDPlansFilterStore.isPlanFetched() &&
        //   !isNil(response.maPlan) &&
        //   mPartDPlansFilterStore.setTotalCount(response.mPartD)

        customerStore.setQuotePulledStatusMA(
          quotesPullStatus.isMAQuotesProcessing
        )
        customerStore.setQuotePulledStatusMPartD(
          quotesPullStatus.isMPartDQuotesProcessing
        )
        customerStore.setQuotePulledStatusMS(
          quotesPullStatus.isMSQuotesProcessing
        )

        if (
          quotesPullStatus.isMAQuotesProcessing ||
          quotesPullStatus.isMPartDQuotesProcessing ||
          quotesPullStatus.isMSQuotesProcessing
        ) {
          timerRef.current = setTimeout(() => {
            fetchQuotesPullStatus(getPlanYear())
          }, 15000)
        }

        // if (!customerStore.getCurrentTabStatus(currentTab)) {
        //   if (
        //     currentTab === 0 &&
        //     !medicareAdvantageFilterQuoteStore.isPlanFetched()
        //   )
        //     showQuotesOnTabChange(currentTab)
        //   else if (currentTab === 1 && !msPlanFiltersStore.isPlanFetched())
        //     showQuotesOnTabChange(currentTab)
        //   else if (
        //     currentTab === 2 &&
        //     !mPartDPlansFilterStore.isPlanFetched()
        //   )
        //     showQuotesOnTabChange(currentTab)
        //   else if (effectiveDateChanged && currentTab === 1) {
        //     showQuotesOnTabChange(currentTab)
        //   } else if (
        //     msPlanFiltersStore.getIsTobaccoChanged() &&
        //     currentTab === 1
        //   ) {
        //     showQuotesOnTabChange(currentTab)
        //   }
        // }
        setIsQuotesStatusPulled(true)
        // } else {
        //   if (isMounted.current)
        //     snackbarStore.set({
        //       snackbarOpen: true,
        //       snackbarMessage: 'Unable to fetch Quotes Pull Status',
        //       snackbarType: 'error',
        //     })
        // }
      })
      .catch((err) => {
        if (isMounted.current)
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: 'Unable to fetch Quotes Pull Status',
            snackbarType: 'error',
          })
      })
    if (msPlanFiltersStore.isQuotesPullStatusFetchTriggered)
      msPlanFiltersStore.setIsQuotesPullStatusFetchTriggered(false)
  }

  const handleAddSpouseDetails = (
    gender: string,
    dob: string,
    fName: string,
    lName: string
  ) => {
    setAddSpouseDetailsLoader(true)

    setTimeout(() => {
      console.log('After 5 secs')
    }, 2000)
    addSpouseDetails(fName, lName, dob, gender)
      .then((response) => {
        customerStore.isMAQuotesProcessing = true
        customerStore.isMSQuotesProcessing = true
        customerStore.isMPartDQuotesProcessing = true
        fetchQuotesPullStatus(getPlanYear())
        snackbarStore.set({
          snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
          snackbarOpen: true,
          snackbarType: SnackbarTypes.SUCCESS,
        })
        customerStore.setSpouseBasicDetails({
          spouseDateOfBirth: dob,
          spouseFirstName: fName,
          spouseGender: gender,
          spouseLastName: lName,
          spouseOptIn: true,
        })
        customerStore.setForSpouse(true)
        setAddSpouseDetailsLoader(false)
        setShowAddSpouseConfirmation(false)
        setThankYouModalActive(true)
        snackbarStore.set({
          snackbarMessage: 'Spouse details added successfully',
          snackbarOpen: true,
          snackbarType: SnackbarTypes.SUCCESS,
        })
      })
      .catch((err) => {
        setAddSpouseDetailsLoader(false)
        snackbarStore.set({
          snackbarMessage: 'Unable to add spouse details, try again later',
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
      })
      .finally(() => {
        setAddSpouseDetailsLoader(false)
      })
  }

  const getCustomerDetails = (preferenceCoverage: string) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_CUSTOMER_DETAILS.USECASE,
      HttpMethods.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data

          customerStore.set(data)
          customerStore.setPreferenceCoverage(preferenceCoverage)
          msPlanFiltersStore.setEffectiveDate(
            data.msEffectiveDateFilterSelf
              ? data.msEffectiveDateFilterSelf.substring(0, 10)
              : data.msEffectiveDateFilterSelf
          )
        } else throw response
      })
      .catch((err) => {
        if (isMounted.current)
          snackbarStore.set({
            snackbarMessage: getApiErrorMessage('fetch customer details'),
            snackbarOpen: true,
            snackbarType: 'error',
          })
      })
  }

  useEffect(() => {
    if (!customerStore.scrollPosition)
      window.scroll({ top: 0, behavior: 'smooth' })

    medicareAdvantageFilterQuoteStore.setPlansFetched(false)
    msPlanFiltersStore.setPlansFetched(false)
    mPartDPlansFilterStore.setPlansFetched(false)
    let preferenceCoverage = window.location.pathname.split('/')[2]
    if (preferenceCoverage !== undefined) setType(preferenceCoverage)

    if (isEmpty(preferenceCoverage)) {
      preferenceCoverage = customerStore.preferenceCoverage
    } else {
      customerStore.setPreferenceCoverage(preferenceCoverage)
    }

    switch (preferenceCoverage) {
      case PreferenceCoverageShortNameEnum.ma:
        setCurrentTab(0)
        break
      case PreferenceCoverageShortNameEnum.ms:
        setCurrentTab(1)
        break
      case PreferenceCoverageShortNameEnum.pdp:
        setCurrentTab(2)
        break
    }
    getCustomerDetails(preferenceCoverage)
    fetchQuotesPullStatus(getPlanYear())
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current)
      isMounted.current = false
    }
  }, [])

  // useEffect(() => {
  //   if (msPlanFiltersStore.isQuotesPullStatusFetchTriggered) {
  //     fetchQuotesPullStatus(getPlanYear())
  //   }
  // }, [msPlanFiltersStore.isQuotesPullStatusFetchTriggered])

  useEffect(() => {
    const customerDetails = customerStore.get()
    if (customerDetails.isAutoLoggedIn) setResPassModalActive(false)
    else if (!customerDetails.status || customerDetails.status === 'new')
      setResPassModalActive(true)
    else setResPassModalActive(false)
  }, [customerStore.status, customerStore.isAutoLoggedIn])

  useEffect(() => {
    if (resPassModalActive === false) {
      ApiManager.makeApiCallWithAuthentication(
        UrlConstants.GET_INFO_STEPS.USECASE,
        HttpMethods.GET_METHOD
      )
        .then((res) => {
          const infoStepsArray = res.data.data as string[]
          let redirectURL: string | null = null

          if (infoStepsArray && infoStepsArray.length !== 0) {
            if (infoStepsArray.includes('currentPlan')) {
              redirectURL = null
            } else if (infoStepsArray.includes('doctor')) {
              redirectURL = RouteConstants.INFO_STEPS_DOCTORS
            } else if (infoStepsArray.includes('pharmacy')) {
              redirectURL = RouteConstants.INFO_STEPS_CURRENT_PLAN
            } else if (infoStepsArray.includes('healthQuestion')) {
              redirectURL = RouteConstants.INFO_STEPS_PHARMACY
            } else if (infoStepsArray.includes('prescription')) {
              redirectURL = RouteConstants.INFO_STEPS_HEALTH
            } else if (infoStepsArray.includes(InfoStepEnum.QUESTIONNAIRE)) {
              redirectURL = RouteConstants.INFO_STEPS_PRESCRIPTION
            }
          } else {
            redirectURL = RouteConstants.INFO_STEPS_QUESTIONNAIRE
          }

          customerStore.setInfoSteps(infoStepsArray)

          setInfoStepsModal({
            active: redirectURL ? true : false,
            redirect: redirectURL,
          })
        })
        .catch((err) => {})
    }
  }, [resPassModalActive])

  // const showQuotesOnTabChange = (tabNumber: number) => {
  //   switch (tabNumber) {
  //     case 0:
  //       // if (maPlans.length === 0) {
  //       //   getMedicareAdvantagePlansFilters(false)
  //       // } else {
  //       //   getMedicareAdvantagePlansFilters(true)
  //       // }

  //       break
  //     case 1:
  //       if (msPlans.length === 0) {
  //         getMedicareSupplementPlanFilters(false)
  //       } else {
  //         getMedicareSupplementPlanFilters(true)
  //       }

  //       break
  //     case 2:
  //       if (mPartDPlans.length === 0) {
  //         getMedicarePartDPlansFilters(false)
  //       } else {
  //         getMedicarePartDPlansFilters(true)
  //       }

  //       break
  //   }
  // }

  // useEffect(() => {
  //   !customerStore.getCurrentTabStatus(currentTab) &&
  //     isQuotesStatusPulled &&
  //     showQuotesOnTabChange(currentTab)
  // }, [currentTab])

  // useEffect(() => {
  //   if (
  //     mPartDPlansFilterStore.filterApplied ||
  //     mPartDPlansFilterStore.paginationApplied
  //   ) {
  //     getMedicarePartDPlans(mPartDPlansFilterStore.mPartDPlanRequestFilter)
  //   }
  // }, [
  //   mPartDPlansFilterStore.filterApplied,
  //   mPartDPlansFilterStore.paginationApplied,
  // ])

  // useEffect(() => {
  //   if (mPartDPlansFilterStore.clearFilters) {
  //     getMedicarePartDPlansFilters(false)
  //   }
  // }, [mPartDPlansFilterStore.isFiltersCleared()])

  const isPDPInitialRender = useRef(true)

  // useEffect(() => {
  //   if (isPDPInitialRender.current) {
  //     isPDPInitialRender.current = false
  //     return
  //   }
  //   if (customerStore.scrollPosition === '') {
  //     mPartDPlansFilterStore.mPartDPlanFilters.selectSortOption = ''
  //     mPartDPlansFilterStore.mPartDPlanFilters = {} as MPartDPlanDisplayFilter
  //     mPartDPlansFilterStore.currentPlanYear = ''
  //     mPartDPlansFilterStore.filterApplied = false
  //     mPartDPlansFilterStore.plansFetched = false
  //     mPartDPlansFilterStore.filtersFetched = false
  //     mPartDPlansFilterStore.totalCount = null
  //     mPartDPlansFilterStore.filtersActive = false
  //     customerStore.setMPDPageNumber(PaginationConstants.DEFAULT_PAGE)
  //     setMPartDPlans([])
  //     getMedicarePartDPlansFilters(false)
  //   }
  // }, [customerStore.mPartDFilterYear])

  const isMAInitialRender = useRef(true)

  // useEffect(() => {
  //   if (isMAInitialRender.current) {
  //     isMAInitialRender.current = false
  //     return
  //   }
  //   if (customerStore.scrollPosition === '') {
  //     medicareAdvantageFilterQuoteStore.medicareAdvantageFilters =
  //       {} as MedicareAdvantageDisplayFilter
  //     medicareAdvantageFilterQuoteStore.filterApplied = false
  //     medicareAdvantageFilterQuoteStore.plansFetched = false
  //     medicareAdvantageFilterQuoteStore.filtersFetched = false
  //     medicareAdvantageFilterQuoteStore.totalCount = null
  //     medicareAdvantageFilterQuoteStore.filtersActive = false
  //     customerStore.setMAPageNumber(PaginationConstants.DEFAULT_PAGE)
  //     setMAPlans([])
  //     // getMedicareAdvantagePlansFilters(false)
  //   }
  // }, [customerStore.maFilterYear])

  // useEffect(() => {
  //   if (
  //     msPlanFiltersStore.filterApplied ||
  //     msPlanFiltersStore.paginationApplied
  //   ) {
  //     getMedicareSupplementPlans(msPlanFiltersStore.msPlanRequestFilter)
  //   }
  // }, [msPlanFiltersStore.filterApplied, msPlanFiltersStore.paginationApplied])

  // useEffect(() => {
  //   if (msPlanFiltersStore.isFiltersCleared()) {
  //     getMedicareSupplementPlanFilters(false)
  //   }
  // }, [msPlanFiltersStore.isFiltersCleared()])

  // useEffect(() => {
  //   if (medicareAdvantageFilterQuoteStore.isFilterChanged) {
  //     customerStore.setMAPageNumber(PaginationConstants.DEFAULT_PAGE)
  //     setMAPlans([])
  //     medicareAdvantageFilterQuoteStore.setIsFilterChanged(false)
  //   }
  // }, [medicareAdvantageFilterQuoteStore.isFilterChanged])

  // useEffect(() => {
  //   if (msPlanFiltersStore.isFilterChanged) {
  //     customerStore.setMSPageNumber(PaginationConstants.DEFAULT_PAGE)
  //     setMSPlans([])
  //     msPlanFiltersStore.setIsFilterChanged(false)
  //   }
  // }, [msPlanFiltersStore.isFilterChanged])

  // useEffect(() => {
  //   if (mPartDPlansFilterStore.isFilterChanged) {
  //     customerStore.setMPDPageNumber(PaginationConstants.DEFAULT_PAGE)
  //     setMPartDPlans([])
  //     mPartDPlansFilterStore.setIsFilterChanged(false)
  //   }
  // }, [mPartDPlansFilterStore.isFilterChanged])

  // useEffect(() => {
  //   if (
  //     medicareAdvantageFilterQuoteStore.filterApplied ||
  //     medicareAdvantageFilterQuoteStore.paginationApplied
  //   ) {
  //     getMedicareAdvantagePlans(
  //       medicareAdvantageFilterQuoteStore.maPlansRequestFilter
  //     )
  //   }
  // }, [
  //   medicareAdvantageFilterQuoteStore.filterApplied,
  //   medicareAdvantageFilterQuoteStore.paginationApplied,
  // ])

  // const maFiltersChanged = () => {
  //   getMedicareAdvantagePlans(
  //     medicareAdvantageFilterQuoteStore.getMAPlanRequestFilter()
  //   )
  // }

  // useEffect(() => {
  //   // if (medicareAdvantageFilterQuoteStore.isFiltersCleared()) {
  //   //   getMedicareAdvantagePlansFilters(false)
  //   // }
  // }, [medicareAdvantageFilterQuoteStore.isFiltersCleared()])

  // useEffect(() => {
  //   if (customerStore.getEnrollmentRequestPlan().planId !== '') {
  //     switch (customerStore.getEnrollmentRequestPlan().planType) {
  //       case MedicareQuotesType.MEDICARE_ADVANTAGE:
  //         const filteredMAPlan: MNonSupplementPlan | undefined = maPlans.find(
  //           (plan) => {
  //             return customerStore.getEnrollmentRequestPlan().planId === plan.id
  //           }
  //         )
  //         if (!isUndefined(filteredMAPlan)) {
  //           filteredMAPlan.enrollRequested = true
  //         }
  //         break
  //       case MedicareQuotesType.MEDICARE_SUPPLEMENT:
  //         const filteredMSPlan: MSPlan | undefined = msPlans.find((plan) => {
  //           return customerStore.getEnrollmentRequestPlan().planId === plan.id
  //         })
  //         if (!isUndefined(filteredMSPlan)) {
  //           filteredMSPlan.enrollRequested = true
  //         }
  //         break
  //       case MedicareQuotesType.MEDICARE_PRESCRIPTION:
  //         const filteredMPartDPlan: MNonSupplementPlan | undefined =
  //           mPartDPlans.find((plan) => {
  //             return customerStore.getEnrollmentRequestPlan().planId === plan.id
  //           })
  //         if (!isUndefined(filteredMPartDPlan)) {
  //           filteredMPartDPlan.enrollRequested = true
  //         }
  //     }
  //   }
  // }, [customerStore.getEnrollmentRequestPlan().planId])

  // useEffect(() => {
  //   if (customerStore.getSavePlanStatusObject().planId !== '') {
  //     switch (customerStore.getSavePlanStatusObject().planType) {
  //       case MedicareQuotesType.MEDICARE_ADVANTAGE:
  //         const filteredMAPlan: MNonSupplementPlan | undefined = maPlans.find(
  //           (plan) => {
  //             return customerStore.getSavePlanStatusObject().planId === plan.id
  //           }
  //         )
  //         if (!isUndefined(filteredMAPlan)) {
  //           filteredMAPlan.planSaved =
  //             customerStore.getSavePlanStatusObject().status
  //         }
  //         break
  //       case MedicareQuotesType.MEDICARE_SUPPLEMENT:
  //         const filteredMSPlan: MSPlan | undefined = msPlans.find((plan) => {
  //           return customerStore.getSavePlanStatusObject().planId === plan.id
  //         })
  //         if (!isUndefined(filteredMSPlan)) {
  //           filteredMSPlan.planSaved =
  //             customerStore.getSavePlanStatusObject().status
  //         }
  //         break
  //       case MedicareQuotesType.MEDICARE_PRESCRIPTION:
  //         const filteredMPartDPlan: MNonSupplementPlan | undefined =
  //           mPartDPlans.find((plan) => {
  //             return customerStore.getSavePlanStatusObject().planId === plan.id
  //           })
  //         if (!isUndefined(filteredMPartDPlan)) {
  //           filteredMPartDPlan.planSaved =
  //             customerStore.getSavePlanStatusObject().status
  //         }
  //     }
  //   }
  // }, [customerStore.getSavePlanStatusObject()])

  const getStepValue = (percentage: number, total: number): number => {
    return Math.round((percentage / 100) * total)
  }

  useEffect(() => {
    if (!isEmpty(maPlanMessage)) updatePriorityCallback(maPlanMessage)
  }, [maPlanMessage])

  useEffect(() => {
    if (!isEmpty(maPlanMessage)) updatePriorityCallback(msPlanMessage)
  }, [msPlanMessage])

  useEffect(() => {
    if (!isEmpty(maPlanMessage)) updatePriorityCallback(mPartDPlanMessage)
  }, [mPartDPlanMessage])

  useEffect(() => {
    let compareCardsPlanType = userActionStore.getComparePlanCards().planType
    if (
      (compareCardsPlanType === MedicareQuotesType.MEDICARE_ADVANTAGE &&
        customerStore.isMAQuotesProcessing) ||
      (compareCardsPlanType === MedicareQuotesType.MEDICARE_SUPPLEMENT &&
        customerStore.isMSQuotesProcessing) ||
      (compareCardsPlanType === MedicareQuotesType.MEDICARE_PRESCRIPTION &&
        customerStore.isMPartDQuotesProcessing)
    )
      userActionStore.resetComparePlanCards()
  }, [
    customerStore.isMAQuotesProcessing,
    customerStore.isMSQuotesProcessing,
    customerStore.isMPartDQuotesProcessing,
  ])

  // const getMedicareAdvantagePlansFilters = (
  //   isTabChanged: boolean,
  //   forSpouse = false
  // ) => {
  //   if (!medicareAdvantageFilterQuoteStore.filtersFetched) {
  //     medicareAdvantageFilterQuoteStore.setFiltersFetched(true)
  //   }
  //   if (
  //     isEqual(
  //       medicareAdvantageFilterQuoteStore.getMedicareAdvantageFilters(),
  //       {}
  //     )
  //   ) {
  //     getMNSPlansFilters<MedicareAdvantageFilters>(
  //       MedicareQuotesType.MEDICARE_ADVANTAGE,
  //       customerStore.maFilterYear === ''
  //         ? getPlanYear().toString()
  //         : customerStore.maFilterYear,
  //       medicareAdvantageFilterQuoteStore.getIsPrimaryAndSpouseOption()
  //     )
  //       .then((maPlanFilter: MedicareAdvantageFilters) => {
  //         if (!isEmpty(maPlanFilter)) {
  //           console.log('maPlanFilter', maPlanFilter)
  //           // const maPlanFilter: MedicareAdvantageFilters =
  //           //   medicareAdvantageFilters.data.data
  //           const maPlanDisplayFilters: MedicareAdvantageDisplayFilter = {
  //             moop: {
  //               minValue: maPlanFilter.moop.start,
  //               maxValue: maPlanFilter.moop.end,
  //               currentValue: maPlanFilter.moop.end,
  //               stepValue: getStepValue(
  //                 10,
  //                 maPlanFilter.moop.end - maPlanFilter.moop.start
  //               ),
  //             },
  //             monthlyPremium: {
  //               minValue: maPlanFilter.monthlyPremium.start,
  //               maxValue: maPlanFilter.monthlyPremium.end,
  //               currentValue: maPlanFilter.monthlyPremium.end,
  //               stepValue: getStepValue(
  //                 10,
  //                 maPlanFilter.monthlyPremium.end -
  //                   maPlanFilter.monthlyPremium.start
  //               ),
  //             },
  //             planYear: maPlanFilter.planYear
  //               ? maPlanFilter.planYear
  //               : { planYears: [], defaultPlanYear: '' },
  //             currentPlanYear: maPlanFilter.planYear.defaultPlanYear,
  //             ratingValue: {
  //               minValue: 0,
  //               maxValue: maPlanFilter.minStarRating.end,
  //               currentValue: 0,
  //               stepValue: getStepValue(
  //                 10,
  //                 maPlanFilter.minStarRating.end -
  //                   maPlanFilter.minStarRating.start
  //               ),
  //             },
  //             rxIncluded: maPlanFilter.rxIncluded,
  //             planTypeCheckbox: maPlanFilter.policyTypes?.map(
  //               (policyType, index) => {
  //                 return {
  //                   id: index,
  //                   checked: false,
  //                   label: policyType,
  //                   value: policyType,
  //                 }
  //               }
  //             ),
  //             planBenefitsCheckbox: maPlanFilter.benefits?.map(
  //               (benefit, index) => {
  //                 return {
  //                   id: index,
  //                   checked: false,
  //                   label:
  //                     benefit === 'Medicare Part B Get Back'
  //                       ? 'Medicare Part B Give Back'
  //                       : benefit,
  //                   value: benefit,
  //                 }
  //               }
  //             ),
  //             drugsCheckbox: maPlanFilter.prescriptionDrugs
  //               ? maPlanFilter.prescriptionDrugs.map(
  //                   (prescriptionDrug, index) => {
  //                     return {
  //                       id: index,
  //                       checked: false,
  //                       label: startCase(prescriptionDrug.name),
  //                       value: prescriptionDrug.name,
  //                       optionalValue: prescriptionDrug.currentDosage?.id,
  //                     }
  //                   }
  //                 )
  //               : [],
  //             carriers: maPlanFilter.carriers?.map((carrier, index) => {
  //               return {
  //                 id: parseInt(carrier.id),
  //                 checked: false,
  //                 label: carrier.name,
  //                 value: carrier.name,
  //               }
  //             }),
  //             providers: maPlanFilter.providers?.map((provider, index) => {
  //               return {
  //                 id: parseInt(provider.id),
  //                 checked: false,
  //                 label: provider.name,
  //                 value: provider.name,
  //                 optionalValue: provider.id,
  //               }
  //             }),

  //             sortOptions: maPlanFilter.sortOptions,
  //             selectSortOption: QuotesConstants.initialMAPlanSortOption,
  //             clearFiltersApplied: false,
  //             forSpouse: maPlanFilter.forSpouse,
  //           }

  //           // const storedCarrier = customerStore.currentCoverageSelf.includes(
  //           //   'Medicare Advantage'
  //           // )
  //           // //   ? customerStore.currentProviderSelf
  //           // //   : ''

  //           // const storedCarrier = customerStore.filterCoverageSelf.includes(
  //           //   'Medicare Advantage'
  //           // )
  //           //   ? customerStore.filterProviderSelf
  //           //   : ''

  //           // const carriers =
  //           //   storedCarrier !== ''
  //           //     ? maPlanFilter.carriers.filter((carrier) =>
  //           //         carrier
  //           //           .toLowerCase()
  //           //           .includes(storedCarrier.toLowerCase())
  //           //       )
  //           //     : []
  //           // const checkedCarriers =
  //           //   storedCarrier !== ''
  //           //     ? maPlanFilter.carriers.map((carrier, index) => {
  //           //         const checked = carrier
  //           //           .toLowerCase()
  //           //           .includes(storedCarrier.toLowerCase())
  //           //         return {
  //           //           id: index,
  //           //           checked: checked,
  //           //           label: carrier,
  //           //           value: carrier,
  //           //         }
  //           //       })
  //           //     : []
  //           // if (carriers.length !== 0) {
  //           //   maPlanDisplayFilters.carriers = checkedCarriers
  //           // }
  //           // const storedPlanType = customerStore.filterCoverageSelf.includes(
  //           //   'Medicare Advantage'
  //           // )
  //           //   ? customerStore.filterPlanTypeSelf
  //           //   : ''
  //           // const planTypes =
  //           //   storedPlanType !== ''
  //           //     ? maPlanFilter.planTypes.filter((planType) =>
  //           //         storedPlanType.includes(planType)
  //           //       )
  //           //     : []
  //           // const checkedPlanTypes =
  //           //   storedPlanType !== ''
  //           //     ? maPlanFilter.planTypes.map((type, index) => {
  //           //         const checked = storedPlanType.includes(type)
  //           //         return {
  //           //           id: index,
  //           //           checked: checked,
  //           //           label: type,
  //           //           value: type,
  //           //         }
  //           //       })
  //           //     : []
  //           // if (planTypes.length !== 0) {
  //           //   maPlanDisplayFilters.planTypeCheckbox = checkedPlanTypes
  //           // }

  //           // if (
  //           //   customerStore.filterCoverageSelf.includes('Medicare Advantage')
  //           // ) {
  //           //   customerStore.setFiltersInfoStep({
  //           //     coverageSelf: '',
  //           //     providerSelf: '',
  //           //     planTypeSelf: '',
  //           //   })
  //           // }
  //           medicareAdvantageFilterQuoteStore.setMedicareAdvantageFilters(
  //             maPlanDisplayFilters
  //           )
  //           medicareAdvantageFilterQuoteStore.setMAPlanRequestFilter({
  //             // carriers: carriers,
  //             // planTypes: planTypes,
  //             paginationDetail: {
  //               page: PaginationConstants.DEFAULT_PAGE,
  //               size: PaginationConstants.DEFAULT_PAGE_SIZE,
  //             },
  //             sortDetail: {
  //               order: 'ASC',
  //               type: QuotesConstants.initialMAPlanSortOption,
  //             },
  //             planYear:
  //               customerStore.maFilterYear === ''
  //                 ? getPlanYear().toString()
  //                 : customerStore.maFilterYear,
  //             forSpouse: maPlanFilter.forSpouse,
  //           })
  //           customerStore.setMAPageSize(PaginationConstants.DEFAULT_PAGE_SIZE)
  //           getMedicareAdvantagePlans(
  //             medicareAdvantageFilterQuoteStore.getMAPlanRequestFilter()
  //           )
  //         } else {
  //           if (isMounted.current)
  //             snackbarStore.set({
  //               snackbarOpen: true,
  //               snackbarMessage: 'No Medicare Advantage Plans Present',
  //               snackbarType: 'success',
  //             })
  //           if (
  //             customerStore.preferenceCoverage ===
  //             PreferenceCoverageShortNameEnum.ma
  //           ) {
  //             setMAPlanErrorMessage(StringConstants.MA_PLAN_NO_PLANS_FOUND)
  //           }
  //           medicareAdvantageFilterQuoteStore.setFiltersFetched(false)
  //         }
  //       })
  //       .catch((err: Error) => {
  //         if (isMounted.current)
  //           snackbarStore.set({
  //             snackbarOpen: true,
  //             snackbarMessage: getApiErrorMessage(
  //               'fetch Medicare advantage plan filters'
  //             ),
  //             snackbarType: 'error',
  //           })
  //         setMAPlanErrorMessage(
  //           StringConstants.PLAN_TECHNICAL_ISSUE_ERROR_MESSAGE
  //         )
  //         medicareAdvantageFilterQuoteStore.setFiltersFetched(false)
  //       })
  //   } else {
  //     if (!isTabChanged) {
  //       if (
  //         isEqual(
  //           medicareAdvantageFilterQuoteStore.getMAPlanRequestFilter(),
  //           {}
  //         )
  //       ) {
  //         const currentDate = new Date()
  //         const currentMonth = currentDate.getMonth() + 1
  //         medicareAdvantageFilterQuoteStore.setMAPlanRequestFilter({
  //           paginationDetail: {
  //             page: PaginationConstants.DEFAULT_PAGE,
  //             size: PaginationConstants.DEFAULT_PAGE_SIZE,
  //           },
  //           sortDetail: {
  //             order: 'ASC',
  //             type: QuotesConstants.initialMAPlanSortOption,
  //           },
  //           planYear:
  //             customerStore.maFilterYear === ''
  //               ? currentMonth >= 10 && currentMonth <= 12
  //                 ? customerStore.currentMedicarePartBDateSelf
  //                   ? Number(
  //                       customerStore.currentMedicarePartBDateSelf.substring(
  //                         0,
  //                         4
  //                       )
  //                     ) <
  //                     currentDate.getFullYear() + 1
  //                     ? currentDate.getFullYear().toString()
  //                     : (currentDate.getFullYear() + 1).toString()
  //                   : (currentDate.getFullYear() + 1).toString()
  //                 : currentDate.getFullYear().toString()
  //               : customerStore.maFilterYear,
  //         })
  //         customerStore.setMAPageNumber(PaginationConstants.DEFAULT_PAGE)
  //         customerStore.setMAPageSize(
  //           customerStore.maPageSize + PaginationConstants.DEFAULT_PAGE_SIZE
  //         )
  //       } else {
  //         if (
  //           medicareAdvantageFilterQuoteStore.prevSortType ===
  //           medicareAdvantageFilterQuoteStore.getMedicareAdvantageFilters()
  //             .selectSortOption
  //         ) {
  //           customerStore.setMAPageNumber(PaginationConstants.DEFAULT_PAGE)
  //           medicareAdvantageFilterQuoteStore.setPaginationDetails({
  //             page: PaginationConstants.DEFAULT_PAGE,
  //             size: customerStore.maPageSize,
  //           })
  //         }
  //       }
  //       getMedicareAdvantagePlans(
  //         medicareAdvantageFilterQuoteStore.getMAPlanRequestFilter(),
  //         true
  //       )
  //     }
  //   }
  // }

  // const getMedicarePartDPlans = (
  //   mPartDPlanRequestPayload: MParDPlanFiltersRequest,
  //   isClearFilters = false
  // ) => {
  //   getMPartDPPlans(mPartDPlanRequestPayload)
  //     .then((response: any) => {
  //       // if (response.status === 200) {
  //       if (response.data && response.data.length > 0) {
  //         const convertedMPartDPlans: MNonSupplementPlan[] = []
  //         for (let medicareAdvantagePlan of response.data.data) {
  //           convertedMPartDPlans.push(medicareAdvantagePlan)
  //         }
  //         if (
  //           (mPartDPlansFilterStore.prevSortType !== null &&
  //             mPartDPlansFilterStore.prevSortType !==
  //               mPartDPlansFilterStore.getMPartDPlansFilters()
  //                 .selectSortOption) ||
  //           customerStore.mpdPageNumber === PaginationConstants.DEFAULT_PAGE
  //         ) {
  //           mPartDPlansFilterStore.setPrevSortType(
  //             mPartDPlansFilterStore.getMPartDPlansFilters().selectSortOption
  //           )
  //           if (!isClearFilters)
  //             customerStore.setMPDPageNumber(
  //               customerStore.mpdPageSize /
  //                 PaginationConstants.DEFAULT_PAGE_SIZE
  //             )
  //           setMPartDPlans(convertedMPartDPlans)
  //         } else {
  //           setMPartDPlans(cloneDeep(mPartDPlans.concat(convertedMPartDPlans)))
  //         }
  //         mPartDPlansFilterStore.setTotalCount(response.data.totalCount)
  //       } else {
  //         setMPartDPlans([])
  //         if (isMounted.current)
  //           snackbarStore.set({
  //             snackbarOpen: true,
  //             snackbarMessage: 'No plans present for the applied filters',
  //             snackbarType: 'success',
  //           })
  //         mPartDPlansFilterStore.setTotalCount(0)
  //         mPartDPlansFilterStore.setFilterApplied(false)
  //       }
  //       mPartDPlansFilterStore.setFilterApplied(false)
  //       mPartDPlansFilterStore.setPlansFetched(true)
  //       // } else {
  //       //   throw response
  //       // }
  //     })
  //     .catch((err: Error) => {
  //       if (isMounted.current)
  //         snackbarStore.set({
  //           snackbarOpen: true,
  //           snackbarMessage: getApiErrorMessage('fetch Medicare part D plans'),
  //           snackbarType: 'error',
  //         })
  //     })
  // }

  // const getMedicarePartDPlansFilters = (isTabChanged: boolean) => {
  //   // if (!mPartDPlansFilterStore.filtersFetched) {
  //   //   mPartDPlansFilterStore.setFiltersFetched(true)
  //   // }
  //   // if (isEqual(mPartDPlansFilterStore.getMPartDPlansFilters(), {})) {
  //   //   getMNSPlansFilters<MedicarePartDFilters>(
  //   //     MedicareQuotesType.MEDICARE_PRESCRIPTION,
  //   //     customerStore.mPartDFilterYear === ''
  //   //       ? getPlanYear().toString()
  //   //       : customerStore.mPartDFilterYear,
  //   //     !mPartDPlansFilterStore.getIsPrimaryAndSpouseOption()
  //   //   )
  //   //     .then((mPartDPlanFilter: MedicarePartDFilters) => {
  //   //       // if (medicarePartDPlansFilter.status === 200) {
  //   //       if (!isEmpty(mPartDPlanFilter)) {
  //   //         const mPartDPlanDisplayFilter: MPartDPlanDisplayFilter = {
  //   //           carriers: mPartDPlanFilter.carriers.map(
  //   //             (carrier, index: number) => {
  //   //               return {
  //   //                 id: parseInt(carrier.id),
  //   //                 checked: false,
  //   //                 label: carrier.name,
  //   //                 value: carrier.name,
  //   //               }
  //   //             }
  //   //           ),
  //   //           selectSortOption: QuotesConstants.initialMPartDPlanSortOption,
  //   //           deductible: {
  //   //             minValue: mPartDPlanFilter.deductible.start,
  //   //             maxValue: mPartDPlanFilter.deductible.end,
  //   //             currentValue: mPartDPlanFilter.deductible.end,
  //   //             stepValue: getStepValue(
  //   //               10,
  //   //               mPartDPlanFilter.deductible.end -
  //   //                 mPartDPlanFilter.deductible.start
  //   //             ),
  //   //           },
  //   //           minStarRating: {
  //   //             minValue: 0,
  //   //             maxValue: mPartDPlanFilter.minStarRating.end,
  //   //             currentValue: 0,
  //   //             stepValue: getStepValue(
  //   //               10,
  //   //               mPartDPlanFilter.minStarRating.end -
  //   //                 mPartDPlanFilter.minStarRating.start
  //   //             ),
  //   //           },
  //   //           monthlyPremium: {
  //   //             minValue: mPartDPlanFilter.monthlyPremium.start,
  //   //             maxValue: mPartDPlanFilter.monthlyPremium.end,
  //   //             currentValue: mPartDPlanFilter.monthlyPremium.end,
  //   //             stepValue: getStepValue(
  //   //               10,
  //   //               mPartDPlanFilter.monthlyPremium.end -
  //   //                 mPartDPlanFilter.monthlyPremium.start
  //   //             ),
  //   //           },
  //   //           planYear: mPartDPlanFilter.planYear
  //   //             ? mPartDPlanFilter.planYear
  //   //             : { planYears: [], defaultPlanYear: '' },
  //   //           currentPlanYear: mPartDPlanFilter.planYear.defaultPlanYear,
  //   //           drugCheckBoxes: mPartDPlanFilter.prescriptionDrugs
  //   //             ? mPartDPlanFilter.prescriptionDrugs?.map(
  //   //                 (prescriptionDrug, index) => {
  //   //                   return {
  //   //                     id: index,
  //   //                     checked: false,
  //   //                     label: startCase(prescriptionDrug.name),
  //   //                     value: prescriptionDrug.name,
  //   //                     optionalValue: prescriptionDrug.currentDosage.id,
  //   //                   }
  //   //                 }
  //   //               )
  //   //             : [],
  //   //           providers: mPartDPlanFilter.providers
  //   //             ? mPartDPlanFilter.providers?.map((provider, index) => {
  //   //                 return {
  //   //                   id: index,
  //   //                   checked: false,
  //   //                   label: provider.name,
  //   //                   value: provider.name,
  //   //                   optionalValue: provider.id,
  //   //                 }
  //   //               })
  //   //             : [],
  //   //           sortOptions: mPartDPlanFilter.sortOptions,
  //   //           clearFiltersApplied: false,
  //   //         }
  //   //         mPartDPlansFilterStore.setPartDPlansFilters(mPartDPlanDisplayFilter)
  //   //         mPartDPlansFilterStore.setMPartDPlanRequestFilters({
  //   //           paginationDetail: {
  //   //             page: PaginationConstants.DEFAULT_PAGE,
  //   //             size: PaginationConstants.DEFAULT_PAGE_SIZE,
  //   //           },
  //   //           sortDetail: {
  //   //             order: 'ASC',
  //   //             type: QuotesConstants.initialMPartDPlanSortOption,
  //   //           },
  //   //           planYear:
  //   //             customerStore.mPartDFilterYear === ''
  //   //               ? getPlanYear().toString()
  //   //               : customerStore.mPartDFilterYear,
  //   //         })
  //   //         customerStore.setMPDPageSize(PaginationConstants.DEFAULT_PAGE_SIZE)
  //   //         getMedicarePartDPlans(
  //   //           mPartDPlansFilterStore.getMPartDPlanRequestFilters()
  //   //         )
  //   //       } else {
  //   //         if (isMounted.current)
  //   //           snackbarStore.set({
  //   //             snackbarOpen: true,
  //   //             snackbarMessage: 'No Medicare Part D Plans Present',
  //   //             snackbarType: 'success',
  //   //           })
  //   //         mPartDPlansFilterStore.setFiltersFetched(false)
  //   //         if (
  //   //           customerStore.preferenceCoverage ===
  //   //           PreferenceCoverageShortNameEnum.pdp
  //   //         )
  //   //           setMPatDPlanErrorMessage(
  //   //             StringConstants.MPARTD_PLAN_NO_PLANS_FOUND
  //   //           )
  //   //       }
  //   //       // } else {
  //   //       //   throw new Error(StringConstants.PLAN_TECHNICAL_ISSUE_ERROR_MESSAGE)
  //   //       // }
  //   //     })
  //   //     .catch((err: Error) => {
  //   //       if (isMounted.current)
  //   //         snackbarStore.set({
  //   //           snackbarOpen: true,
  //   //           snackbarMessage: getApiErrorMessage(
  //   //             'fetch Medicare part D plans filters'
  //   //           ),
  //   //           snackbarType: 'error',
  //   //         })
  //   //       setMPatDPlanErrorMessage(
  //   //         StringConstants.PLAN_TECHNICAL_ISSUE_ERROR_MESSAGE
  //   //       )
  //   //       mPartDPlansFilterStore.setFiltersFetched(false)
  //   //     })
  //   // } else {
  //   //   if (!isTabChanged) {
  //   //     const currentDate = new Date()
  //   //     const currentMonth = currentDate.getMonth() + 1
  //   //     if (isEqual(mPartDPlansFilterStore.getMPartDPlanRequestFilters(), {})) {
  //   //       mPartDPlansFilterStore.setMPartDPlanRequestFilters({
  //   //         paginationDetail: {
  //   //           page: PaginationConstants.DEFAULT_PAGE,
  //   //           size: PaginationConstants.DEFAULT_PAGE_SIZE,
  //   //         },
  //   //         sortDetail: {
  //   //           order: 'ASC',
  //   //           type: QuotesConstants.initialMPartDPlanSortOption,
  //   //         },
  //   //         planYear:
  //   //           customerStore.mPartDFilterYear === ''
  //   //             ? currentMonth >= 10 && currentMonth <= 12
  //   //               ? customerStore.currentMedicarePartBDateSelf
  //   //                 ? Number(
  //   //                     customerStore.currentMedicarePartBDateSelf.substring(
  //   //                       0,
  //   //                       4
  //   //                     )
  //   //                   ) <
  //   //                   currentDate.getFullYear() + 1
  //   //                   ? currentDate.getFullYear().toString()
  //   //                   : (currentDate.getFullYear() + 1).toString()
  //   //                 : (currentDate.getFullYear() + 1).toString()
  //   //               : currentDate.getFullYear().toString()
  //   //             : customerStore.mPartDFilterYear,
  //   //       })
  //   //       customerStore.setMPDPageNumber(PaginationConstants.DEFAULT_PAGE)
  //   //       customerStore.setMPDPageSize(
  //   //         customerStore.mpdPageSize + PaginationConstants.DEFAULT_PAGE_SIZE
  //   //       )
  //   //     } else {
  //   //       if (
  //   //         mPartDPlansFilterStore.prevSortType ===
  //   //         mPartDPlansFilterStore.getMPartDPlansFilters().selectSortOption
  //   //       ) {
  //   //         customerStore.setMPDPageNumber(PaginationConstants.DEFAULT_PAGE)
  //   //         mPartDPlansFilterStore.setPaginationDetails({
  //   //           page: PaginationConstants.DEFAULT_PAGE,
  //   //           size: customerStore.mpdPageSize,
  //   //         })
  //   //       }
  //   //     }
  //   //     getMedicarePartDPlans(
  //   //       mPartDPlansFilterStore.getMPartDPlanRequestFilters(),
  //   //       true
  //   //     )
  //   //   }
  //   // }
  // }

  // const getMedicareSupplementPlans = (
  //   msPlansFiltersPayload?: MSPlanRequestFilter
  // ) => {
  //   // ApiManager.makeApiCallWithAuthentication(
  //   //   UrlConstants.GET_MEDICARE_SUPPLEMENT_PLANS.USECASE,
  //   //   HttpMethods.POST_METHOD,
  //   //   msPlansFiltersPayload ? msPlansFiltersPayload : {}
  //   // )
  //   //   .then((msPlansResponse) => {
  //   //     if (msPlansResponse.status === 200) {
  //   //       if (msPlansResponse.data && msPlansResponse.data.data) {
  //   //         const convertedMSPlans: MSPlan[] = []
  //   //         for (let msPlan of msPlansResponse.data.data) {
  //   //           convertedMSPlans.push(msPlan)
  //   //         }
  //   //         if (
  //   //           (msPlanFiltersStore.prevSortType !== null &&
  //   //             msPlanFiltersStore.prevSortType !==
  //   //               msPlanFiltersStore.getMSPlansFilters().selectedSortOption) ||
  //   //           customerStore.msPageNumber === PaginationConstants.DEFAULT_PAGE
  //   //         ) {
  //   //           msPlanFiltersStore.setPrevSortType(
  //   //             msPlanFiltersStore.getMSPlansFilters().selectedSortOption
  //   //           )
  //   //           customerStore.setMSPageNumber(
  //   //             customerStore.msPageSize / PaginationConstants.DEFAULT_PAGE_SIZE
  //   //           )
  //   //           setMSPlans(convertedMSPlans)
  //   //         } else {
  //   //           setMSPlans(cloneDeep(msPlans.concat(convertedMSPlans)))
  //   //         }
  //   //         msPlanFiltersStore.setTotalCount(msPlansResponse.data.totalCount)
  //   //         msPlanFiltersStore.setIsEffectiveDateFilterApplied(false)
  //   //       }
  //   //     } else {
  //   //       setMSPlans([])
  //   //       if (
  //   //         isMounted.current &&
  //   //         !msPlanFiltersStore.getIsEffectiveDateFilterApplied()
  //   //       ) {
  //   //         snackbarStore.set({
  //   //           snackbarOpen: true,
  //   //           snackbarMessage: 'No plans present for the applied filters',
  //   //           snackbarType: 'success',
  //   //         })
  //   //       } else {
  //   //         msPlanFiltersStore.setFilterApplied(false)
  //   //         snackbarStore.set({
  //   //           snackbarOpen: true,
  //   //           snackbarMessage:
  //   //             'No plans are available for the applied effective date',
  //   //           snackbarType: 'success',
  //   //         })
  //   //       }
  //   //       msPlanFiltersStore.setTotalCount(0)
  //   //     }
  //   //     msPlanFiltersStore.setFilterApplied(false)
  //   //     msPlanFiltersStore.setPlansFetched(true)
  //   //   })
  //   //   .catch((err: Error) => {
  //   //     if (isMounted.current)
  //   //       snackbarStore.set({
  //   //         snackbarOpen: true,
  //   //         snackbarMessage: getApiErrorMessage(
  //   //           'fetch Medicare supplement plans'
  //   //         ),
  //   //         snackbarType: 'error',
  //   //       })
  //   //     msPlanFiltersStore.setPlansFetched(true)
  //   //   })
  // }

  // const getMedicareSupplementPlanFilters = (isTabChanged: boolean) => {
  //   // if (!msPlanFiltersStore.filtersFetched) {
  //   //   msPlanFiltersStore.setFiltersFetched(true)
  //   // }
  //   // if (
  //   //   isEqual(msPlanFiltersStore.getMSPlansFilters(), {}) ||
  //   //   effectiveDateChanged ||
  //   //   msPlanFiltersStore.getIsTobaccoChanged()
  //   // ) {
  //   //   setEffectiveDateChanged(false)
  //   //   msPlanFiltersStore.setIsTobaccoChanged(false)
  //   //   ApiManager.makeApiCallWithAuthentication(
  //   //     UrlConstants.GET_MEDICARE_SUPPLEMENT_PLANS_FILTERS.USECASE,
  //   //     HttpMethods.GET_METHOD
  //   //   )
  //   //     .then((msPlansFiltersResponse) => {
  //   //       if (msPlansFiltersResponse.status === 200) {
  //   //         if (
  //   //           msPlansFiltersResponse.data &&
  //   //           !isEmpty(msPlansFiltersResponse.data.data)
  //   //         ) {
  //   //           const tempMSPlanFilters: MSPlansFilters =
  //   //             msPlansFiltersResponse.data.data
  //   //           const startOfNextMonth =
  //   //             moment()
  //   //               .add(1, 'month')
  //   //               .toISOString()
  //   //               .split('T')[0]
  //   //               .substring(0, 8) + '01'
  //   //           const effectiveDate = isEmpty(
  //   //             customerStore.msEffectiveDateFilterSelf
  //   //           )
  //   //             ? startOfNextMonth
  //   //             : customerStore.msEffectiveDateFilterSelf!.split('T')[0]
  //   //           msPlanFiltersStore.setEffectiveDate(effectiveDate)
  //   //           const msPlanFilters: MSPlansDisplayFilters = {
  //   //             // effectiveDate: effectiveDate,
  //   //             clearFiltersApplied: false,
  //   //             // planYear: tempMSPlanFilters.planYear,
  //   //             selectedSortOption: QuotesConstants.initialMSPlanSortOption,
  //   //             ambestRatings: tempMSPlanFilters.ambestRatings.map(
  //   //               (ambestRating, index) => {
  //   //                 return {
  //   //                   id: index,
  //   //                   checked: false,
  //   //                   label: ambestRating,
  //   //                   value: ambestRating,
  //   //                 }
  //   //               }
  //   //             ),
  //   //             carriers: tempMSPlanFilters.carriers.map((carrier, index) => {
  //   //               return {
  //   //                 id: index,
  //   //                 checked: false,
  //   //                 label: carrier,
  //   //                 value: carrier,
  //   //               }
  //   //             }),
  //   //             planTypes: tempMSPlanFilters.planTypes.map(
  //   //               (planType, index) => {
  //   //                 return {
  //   //                   id: index,
  //   //                   checked: false,
  //   //                   label: `Plan ${planType} ${
  //   //                     planType === 'HDG' || planType === 'HDF'
  //   //                       ? '(High Deductible)'
  //   //                       : ''
  //   //                   }`,
  //   //                   value: planType,
  //   //                 }
  //   //               }
  //   //             ),
  //   //             sortOptions: tempMSPlanFilters.sortOptions,
  //   //             estimatedAnnualCost: {
  //   //               currentValue: tempMSPlanFilters.estimatedAnnualCost.end,
  //   //               maxValue: tempMSPlanFilters.estimatedAnnualCost.end,
  //   //               minValue: tempMSPlanFilters.estimatedAnnualCost.start,
  //   //               stepValue: getStepValue(
  //   //                 10,
  //   //                 tempMSPlanFilters.estimatedAnnualCost.end -
  //   //                   tempMSPlanFilters.estimatedAnnualCost.start
  //   //               ),
  //   //             },
  //   //             annualDeductible: {
  //   //               currentValue: tempMSPlanFilters.annualDeductible.end,
  //   //               maxValue: tempMSPlanFilters.annualDeductible.end,
  //   //               minValue: tempMSPlanFilters.annualDeductible.start,
  //   //               stepValue: getStepValue(
  //   //                 10,
  //   //                 tempMSPlanFilters.annualDeductible.end -
  //   //                   tempMSPlanFilters.annualDeductible.start
  //   //               ),
  //   //             },
  //   //             monthlyPremium: {
  //   //               currentValue: tempMSPlanFilters.monthlyPremium.end / 100,
  //   //               maxValue: tempMSPlanFilters.monthlyPremium.end / 100,
  //   //               minValue: tempMSPlanFilters.monthlyPremium.start / 100,
  //   //               stepValue: getStepValue(
  //   //                 10,
  //   //                 tempMSPlanFilters.monthlyPremium.end / 100 -
  //   //                   tempMSPlanFilters.monthlyPremium.start / 100
  //   //               ),
  //   //             },
  //   //           }
  //   //           // const storedCarrier = customerStore.currentCoverageSelf.includes(
  //   //           //   'Medicare Supplement'
  //   //           // )
  //   //           //   ? customerStore.currentProviderSelf
  //   //           //   : ''
  //   //           // const storedCarrier = customerStore.filterCoverageSelf.includes(
  //   //           //   'Medicare Supplement'
  //   //           // )
  //   //           //   ? customerStore.filterProviderSelf
  //   //           //   : ''
  //   //           // const carriers =
  //   //           //   storedCarrier !== ''
  //   //           //     ? tempMSPlanFilters.carriers.filter((carrier) =>
  //   //           //         carrier
  //   //           //           .toLowerCase()
  //   //           //           .includes(storedCarrier.toLowerCase())
  //   //           //       )
  //   //           //     : []
  //   //           // const checkedCarriers =
  //   //           //   storedCarrier !== ''
  //   //           //     ? tempMSPlanFilters.carriers.map((carrier, index) => {
  //   //           //         const checked = carrier
  //   //           //           .toLowerCase()
  //   //           //           .includes(storedCarrier.toLowerCase())
  //   //           //         return {
  //   //           //           id: index,
  //   //           //           checked: checked,
  //   //           //           label: carrier,
  //   //           //           value: carrier,
  //   //           //         }
  //   //           //       })
  //   //           //     : []
  //   //           // if (carriers.length !== 0) {
  //   //           //   msPlanFilters.carriers = checkedCarriers
  //   //           // }
  //   //           const storedPlanType = customerStore.filterCoverageSelf.includes(
  //   //             'Medicare Supplement'
  //   //           )
  //   //             ? customerStore.filterPlanTypeSelf.includes('High Deductible')
  //   //               ? customerStore.filterPlanTypeSelf.includes('Plan F')
  //   //                 ? 'Plan HDF (High Deductible)'
  //   //                 : customerStore.filterPlanTypeSelf.includes('Plan G')
  //   //                 ? 'Plan HDG (High Deductible)'
  //   //                 : ''
  //   //               : customerStore.filterPlanTypeSelf
  //   //             : ''
  //   //           const planTypes =
  //   //             storedPlanType !== ''
  //   //               ? tempMSPlanFilters.planTypes.filter((planType) =>
  //   //                   storedPlanType.includes('Plan ' + planType)
  //   //                 )
  //   //               : []
  //   //           const checkedPlanTypes =
  //   //             storedPlanType !== ''
  //   //               ? tempMSPlanFilters.planTypes.map((type, index) => {
  //   //                   const checked = storedPlanType.includes('Plan ' + type)
  //   //                   return {
  //   //                     id: index,
  //   //                     checked: checked,
  //   //                     label: `Plan ${type} ${
  //   //                       type === 'HDG' || type === 'HDF'
  //   //                         ? '(High Deductible)'
  //   //                         : ''
  //   //                     }`,
  //   //                     value: type,
  //   //                   }
  //   //                 })
  //   //               : []
  //   //           if (planTypes.length !== 0) {
  //   //             msPlanFilters.planTypes = checkedPlanTypes
  //   //           }
  //   //           if (
  //   //             customerStore.filterCoverageSelf.includes('Medicare Supplement')
  //   //           ) {
  //   //             customerStore.setFiltersInfoStep({
  //   //               coverageSelf: '',
  //   //               planTypeSelf: '',
  //   //               providerSelf: '',
  //   //             })
  //   //           }
  //   //           msPlanFiltersStore.setMSPlansFilters(msPlanFilters)
  //   //           msPlanFiltersStore.setMSPlanRequestFilters({
  //   //             // carriers: carriers,
  //   //             planTypes: planTypes,
  //   //             paginationDetail: {
  //   //               page: PaginationConstants.DEFAULT_PAGE,
  //   //               size: PaginationConstants.DEFAULT_PAGE_SIZE,
  //   //             },
  //   //             sortDetail: {
  //   //               order: 'ASC',
  //   //               type: QuotesConstants.initialMSPlanSortOption,
  //   //             },
  //   //           })
  //   //           customerStore.setMSPageSize(
  //   //             customerStore.msPageSize + PaginationConstants.DEFAULT_PAGE_SIZE
  //   //           )
  //   //           getMedicareSupplementPlans(
  //   //             msPlanFiltersStore.getMSPlanRequestFilters()
  //   //           )
  //   //         } else {
  //   //           if (
  //   //             isMounted.current &&
  //   //             !isEmpty(customerStore.dateOfBirth) &&
  //   //             !isEmpty(customerStore.gender)
  //   //           )
  //   //             snackbarStore.set({
  //   //               snackbarOpen: true,
  //   //               snackbarMessage: 'No Medicare Supplement Plans Present',
  //   //               snackbarType: 'success',
  //   //             })
  //   //           msPlanFiltersStore.setFiltersFetched(false)
  //   //           if (
  //   //             customerStore.preferenceCoverage ===
  //   //             PreferenceCoverageShortNameEnum.ms
  //   //           )
  //   //             setMSPlanErrorMessage(StringConstants.MS_PLAN_NO_PLANS_FOUND)
  //   //         }
  //   //       } else {
  //   //         throw new Error(StringConstants.PLAN_TECHNICAL_ISSUE_ERROR_MESSAGE)
  //   //       }
  //   //     })
  //   //     .catch((err: Error) => {
  //   //       if (isMounted.current)
  //   //         snackbarStore.set({
  //   //           snackbarOpen: true,
  //   //           snackbarMessage: getApiErrorMessage(
  //   //             'fetch Medicare supplement plan filters'
  //   //           ),
  //   //           snackbarType: 'error',
  //   //         })
  //   //       msPlanFiltersStore.setFiltersFetched(false)
  //   //       setMSPlanErrorMessage(
  //   //         StringConstants.PLAN_TECHNICAL_ISSUE_ERROR_MESSAGE
  //   //       )
  //   //     })
  //   // } else {
  //   //   if (!isTabChanged) {
  //   //     if (isEqual(msPlanFiltersStore.getMSPlanRequestFilters(), {})) {
  //   //       msPlanFiltersStore.setMSPlanRequestFilters({
  //   //         paginationDetail: {
  //   //           page: PaginationConstants.DEFAULT_PAGE,
  //   //           size: PaginationConstants.DEFAULT_PAGE_SIZE,
  //   //         },
  //   //         sortDetail: {
  //   //           order: 'ASC',
  //   //           type: QuotesConstants.initialMSPlanSortOption,
  //   //         },
  //   //       })
  //   //       customerStore.setMSPageSize(
  //   //         customerStore.msPageSize + PaginationConstants.DEFAULT_PAGE_SIZE
  //   //       )
  //   //     } else {
  //   //       if (
  //   //         msPlanFiltersStore.prevSortType ===
  //   //         msPlanFiltersStore.getMSPlansFilters().selectedSortOption
  //   //       ) {
  //   //         customerStore.setMSPageNumber(PaginationConstants.DEFAULT_PAGE)
  //   //         msPlanFiltersStore.setPaginationDetail({
  //   //           page: PaginationConstants.DEFAULT_PAGE,
  //   //           size: customerStore.msPageSize,
  //   //         })
  //   //       }
  //   //     }
  //   //     getMedicareSupplementPlans(msPlanFiltersStore.getMSPlanRequestFilters())
  //   //   }
  //   // }
  // }

  // Call the scroll function when the component mounts and when customerStore changes
  const handleTabChange = (e: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const handleResPassModalClose = () => {
    customerStore.setShowResetPasswordPrompt(false)
    setResPassModalActive(false)
  }

  const toggleEffectiveDateFilterPopupOpen = () => {
    setOpenEffectiveDateFilterConfirmationPopup(
      !openEffectiveDateFilterConfirmationPopup
    )
  }

  const scrollToStoredPosition = () => {
    const storedPosition = parseInt(customerStore.scrollPosition)
    if (storedPosition) {
      setTimeout(() => {
        window.scrollTo({ top: storedPosition, behavior: 'smooth' })
      }, 500)
      customerStore.setScrollPosition('')
    } else {
      if (storedPosition) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }
  }

  useEffect(() => {
    if (msPlans.length > 0 && currentTab === 1) {
      scrollToStoredPosition()
    }
    if (maPlans.length > 0 && currentTab === 0) {
      scrollToStoredPosition()
    }
    if (mPartDPlans.length > 0 && currentTab === 2) {
      scrollToStoredPosition()
    }
  }, [msPlans, maPlans, mPartDPlans])

  // const handleConfirmEffectiveDateFilterChange = () => {
  //   msPlanFiltersStore.setEffectiveDate(msPlanFiltersStore.tempEffectiveDate)
  //   msPlanFiltersStore.setTempEffectiveDate('')
  //   msPlanFiltersStore.setIsEffectiveDateFilterApplied(true)
  //   setEffectiveDateChanged(true)
  //   // msPlanFiltersStore.setTotalCount(null)
  //   // msPlanFiltersStore.setPlansFetched(false)
  //   // setMSPlans([])
  //   // msPlanFiltersStore.clearMSPlanRequestFilters()
  //   // msPlanFiltersStore.setFiltersFetched(false)
  //   toggleEffectiveDateFilterPopupOpen()
  //   window.scrollTo({ top: 0, behavior: 'smooth' })
  // }

  // useEffect(() => {
  //   if (!isEmpty(msPlanFiltersStore.tempEffectiveDate)) {
  //     setOpenEffectiveDateFilterConfirmationPopup(true)
  //   }
  // }, [msPlanFiltersStore.tempEffectiveDate])

  // const handleRejectEffectiveDateFilterChange = () => {
  //   msPlanFiltersStore.setTempEffectiveDate('')
  //   toggleEffectiveDateFilterPopupOpen()
  // }

  // useEffect(() => {
  //   let pathname = window.location.pathname.replace(/\//g, '')
  //   const requestBody: SFLeadInterface = {
  //     leadAppStage: 'Quoting Tool',
  //     leadAppSubstage: pathname,
  //   }

  //   ApiManager.makeApiCallWithAuthentication(
  //     UrlConstants.SF_CALLBACK_LEAD_INFO_UPDATE.USECASE,
  //     HttpMethods.PATCH_METHOD,
  //     requestBody
  //   )
  //     .then((response) => {
  //       if (response.status === 200)
  //         console.log(
  //           'Successfully updated the Salesforce for leadAppStage as Quoting Tool'
  //         )
  //       else
  //         throw new Error(
  //           'Something went wrong while updating the Salesforce for leadAppStage as Quoting Tool'
  //         )
  //     })
  //     .catch((err: Error) => {
  //       console.log(
  //         `Error occurred when trying to update SF callback fields,Err - ${err.message}`
  //       )
  //     })
  // }, [])
  return (
    <LayoutComponent showDiscountsDisclaimer={currentTab === 1} type={type}>
      <Container>
        <TopNotificationChip
          open={resPassChipActive}
          setOpen={() => setResPassChipActive(false)}
        >
          <NewPasswordNotification />
        </TopNotificationChip>

        <TopNotificationChip
          open={completeProfileChipActive}
          setOpen={() => setCompleteProfileChipActive(false)}
        >
          <CompleteYourProfileNotification />
        </TopNotificationChip>

        {showAddSpouseConfirmation && (
          <ModalComponent
            width={700}
            setOpen={() => {
              setShowAddSpouseConfirmation(!showAddSpouseConfirmation)
            }}
            description='Popup - Request for Enrollment Confirmation'
            hideCloseButton={false}
          >
            <AddSpouseConfirmationModal
              loading={addSpouseDetailsLoader}
              onConfirm={(
                dob: string,
                gender: string,
                fName: string,
                lName: string
              ) => {
                handleAddSpouseDetails(gender, dob, fName, lName)
              }}
              onReject={() => {
                setShowAddSpouseConfirmation(!showAddSpouseConfirmation)
              }}
            />
          </ModalComponent>
        )}

        {thankYouModalActive && (
          <ModalComponent setOpen={() => setThankYouModalActive(false)}>
            <ThankYouModal setOpen={() => setThankYouModalActive(false)} />
          </ModalComponent>
        )}

        {infoStepsModal.active &&
          !customerStore.getCurrentTabStatus(currentTab) && (
            <ModalComponent
              width={600}
              setOpen={() =>
                setInfoStepsModal({
                  ...infoStepsModal,
                  active: false,
                })
              }
              description='Popup - Info Steps Prompt'
            >
              <InfoStepsPrompt
                setOpen={() =>
                  setInfoStepsModal({
                    ...infoStepsModal,
                    active: false,
                  })
                }
                redirect={infoStepsModal.redirect}
              />
            </ModalComponent>
          )}

        {resPassModalActive && customerStore.getShowResetPasswordPrompt() && (
          <ModalComponent
            width={600}
            setOpen={handleResPassModalClose}
            description='Popup - Reset Password Prompt'
          >
            <NewPasswordModal
              setOpen={handleResPassModalClose}
              open={resPassModalActive}
            />
          </ModalComponent>
        )}

        {/* {openEffectiveDateFilterConfirmationPopup && (
          <ModalComponent
            width={450}
            setOpen={handleRejectEffectiveDateFilterChange}
            hideCloseButton={true}
            description='Popup - Confirm Effective Date Filter Change'
          >
            <EffectiveDateFilterChangeConfirmation
              onConfirm={handleConfirmEffectiveDateFilterChange}
              onReject={handleRejectEffectiveDateFilterChange}
            />
          </ModalComponent>
        )} */}

        <InPageHeader
          onChange={handleTabChange}
          currentTab={currentTab}
          showBackButton={false}
          tabLabels={[
            {
              heading: 'Medicare Advantage (Part C)',
              subHeading: `${
                !customerStore.getCurrentTabStatus(0)
                  ? isNull(maPlanTotalCount)
                    ? ''
                    : `${maPlanTotalCount} plans Available`
                  : GeneralConstants.quotesRefreshMessage
              }`,
              subHeading2: `${
                isEmpty(customerStore.county) && maPlanTotalCount === 0
                  ? StringConstants.MS_TAB_SUBHEADING_FOR_MA_LEAD
                  : ''
              }`,
            },
            {
              heading: 'Medicare Supplement (Medigap)',
              subHeading: `${
                !customerStore.getCurrentTabStatus(1)
                  ? isNull(msPlanTotalCount)
                    ? ''
                    : `${msPlanTotalCount} plans Available`
                  : GeneralConstants.quotesRefreshMessage
              }`,
              subHeading2: `${
                (isEmpty(customerStore.gender) ||
                  isEmpty(customerStore.dateOfBirth)) &&
                msPlanTotalCount === 0
                  ? StringConstants.MS_TAB_SUBHEADING_FOR_MA_LEAD
                  : ''
              }`,
            },
            {
              heading: 'Medicare Prescription Drugs (Part D)',
              subHeading: `${
                !customerStore.getCurrentTabStatus(2)
                  ? isNull(mPartDPlanTotalCount)
                    ? ''
                    : `${mPartDPlanTotalCount} plans Available`
                  : GeneralConstants.quotesRefreshMessage
              }`,
              subHeading2: `${
                isEmpty(customerStore.county) && mPartDPlanTotalCount === 0
                  ? StringConstants.MS_TAB_SUBHEADING_FOR_MA_LEAD
                  : ''
              }`,
            },
          ]}
          isDashboard={true}
          isSidebarShown={!customerStore.getCurrentTabStatus(currentTab)}
        />

        {currentTab === 0 && (
          <>
            {!customerStore.getCurrentTabStatus(currentTab) ? (
              <MedicareAdvantageSection
                plans={maPlans as MNonSupplementPlan[]}
                onDataChange={handleMAPlansData}
                setMATotalPlanCount={setMATotalPlanCount}
                maPlanTotalCount={maPlanTotalCount}
                setShowAddSpouseConfirmation={setShowAddSpouseConfirmation}
              />
            ) : isEmpty(customerStore.county) ? (
              <AddMNSPlanDetails initiateTimer={initiateTimer} tabNumber={2} />
            ) : (
              <ContactCard />
            )}
          </>
        )}

        {currentTab === 1 && (
          <>
            {!customerStore.getCurrentTabStatus(currentTab) ? (
              <MedicareSupplement
                plans={msPlans}
                msPlanTotalCount={msPlanTotalCount}
                onDataChange={handleMSPlansData}
                setMSPlanTotalCount={setMSTotalPlanCount}
                setShowAddSpouseConfirmation={setShowAddSpouseConfirmation}
                fetchQuotesPullStatus={fetchQuotesPullStatus}
              />
            ) : (
              <ContactCard />
            )}
          </>
        )}

        {currentTab === 2 && (
          <>
            {!customerStore.getCurrentTabStatus(currentTab) ? (
              <MedicarePrescription
                plans={mPartDPlans as MNonSupplementPlan[]}
                onDataChange={handleMPartDData}
                setMPartDPlanCount={setMPartDPlanCount}
                mPartDTotalCount={mPartDPlanTotalCount}
                setShowAddSpouseConfirmation={setShowAddSpouseConfirmation}
              />
            ) : isEmpty(customerStore.county) ? (
              <AddMNSPlanDetails initiateTimer={initiateTimer} tabNumber={2} />
            ) : (
              <ContactCard />
            )}
          </>
        )}

        {msPlanFiltersStore.getIsOverLayLoading() && <OverlayLoader />}
      </Container>
    </LayoutComponent>
  )
}
export default SecureComponent(observer(DashboardPage))
