import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import AccessDeniedPage from './components/DashboardPage/PageSections/PopUpPlanCards/AccessDeniedPage'
import AddDoctorComponent from './components/InfoStepsPage/StepperComponents/Doctors/AddDoctor.component'
import AddPrescriptionComponent from './components/InfoStepsPage/AddPrescription.component'
import ComparePlansPage from './pages/ComparePlansPage/ComparePlansPage'
import CompletePlanDetailsPage from './pages/CompletePlanDetailsPage/CompletePlanDetailsPage'
import ContactComponent from './components/ProfilePage/ProfileSubSections/Contact.component'
import CurrentPlan from './components/InfoStepsPage/StepperComponents/CurrentPlan/CurrentPlan'
import DashboardPage from './pages/DashboardPage/DashboardPage'
import DoctorsScreen from './components/InfoStepsPage/StepperComponents/Doctors/DoctorsScreen'
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage'
import ForgotPasswordResetScreen from './pages/common/ForgotPasswordResetScreen'
import HealthComponent from './components/ProfilePage/ProfileSubSections/Health.component'
import HealthQuestion from './components/InfoStepsPage/StepperComponents/Health/HealthQuestion'
import InfoStepsPage from './pages/InfoStepsPage/InfoStepsPage'
import LoginPage from './pages/LoginPage/LoginPage'
import MedicareInfoComponent from './components/ProfilePage/ProfileSubSections/MedicareInfo.component'
import MyProfileSection from './components/ProfilePage/MyProfileSection'
import PharmacyScreen from './components/InfoStepsPage/StepperComponents/Pharmacy/PharmacyScreen'
import PharmacyScreenInfoSteps from './components/InfoStepsPage/StepperComponents/Pharmacy/PharmacyScreenInfoSteps'
import PopUpDashboardPage from './pages/PopUpDashboardPage/PopUpDashboardPage'
import PreferencesComponent from './components/ProfilePage/ProfileSubSections/Preferences.component'
import PrescriptionScreen from './components/InfoStepsPage/StepperComponents/Prescriptions/PrescriptionScreen'
import ProfileComponent from './components/ProfilePage/ProfileSubSections/Profile.component'
import ProfilePage from './pages/ProfilePage/ProfilePage'
import QuestionnaireScreen from './components/InfoStepsPage/StepperComponents/Questionnaire/QuestionnaireScreen'
import React from 'react'
import RedirectScreen from './pages/common/RedirectScreen'
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage'
import RouteConstants from './constants/RouteConstants'
import SavedPlansPage from './pages/SavedPlansPage/SavedPlansPage'
import SpouseSection from './components/ProfilePage/SpouseSection'
import customerStore from './datastore/CustomerStore'
import { getPlanYear } from './utils/CommonUtils'
import { isAuthenticatedUser } from './utils/AuthUtils'
import { observer } from 'mobx-react'
import { pollQuotesPullStatus } from './utils/quotesPullStatus'
import { withRouter } from './components/common/WithRouter/WithRouter.component'

// import Preferences from './components/ProfilePage/ProfileSubSections/Preferences.component'

// import { fetchQuotesPullStatus } from './utils/quotesPullStatus'
// import customerStore from './datastore/CustomerStore'

const App: React.FC = () => {
  window.Invoca && window.Invoca.PNAPI.run()
  window.onbeforeunload = function (e) {
    e.preventDefault()
    return 'Are you Sure'
  }

  const location = useLocation()

  React.useEffect(() => {
    const currentPath = '/' + location.pathname.split('/')[1]
    if (!customerStore.isQuotesPullApiCalled) {
      if (
        currentPath === RouteConstants.PROFILE ||
        currentPath === RouteConstants.INFO_STEPS
      ) {
        customerStore.setIsQuotesPullAPiCalled(true)
        pollQuotesPullStatus(getPlanYear())
      }
    }

    if (currentPath === RouteConstants.DASHBOARD) {
      customerStore.setIsQuotesPullAPiCalled(false)
    }
  }, [
    customerStore.postalCode,
    customerStore.dateOfBirth,
    customerStore.county,
    customerStore.gender,
    customerStore.currentMedicarePartBDateSelf,
    location.pathname,
  ])

  return (
    <Routes>
      {[
        RouteConstants.POPUPDASHBOARD_FOR_MA,
        RouteConstants.POPUPDASHBOARD_FOR_MS,
        RouteConstants.POPUPDASHBOARD_FOR_PDP,
      ].map((path, index) => (
        <Route path={path} key={index} element={<PopUpDashboardPage />} />
      ))}
      <Route
        path={RouteConstants.ACCESS_DENIED}
        element={<AccessDeniedPage />}
      />
      <Route
        path={RouteConstants.HOME}
        element={<Navigate replace to={RouteConstants.REDIRECT} />}
      />
      <Route path={RouteConstants.REDIRECT} element={<RedirectScreen />} />

      {isAuthenticatedUser() ? (
        <>
          <Route
            path={RouteConstants.DASHBOARD}
            element={<DashboardPage />}
          ></Route>
          <Route
            path={RouteConstants.DASHBOARD_MS}
            element={<DashboardPage />}
          ></Route>
          <Route
            path={RouteConstants.DASHBOARD_MA}
            element={<DashboardPage />}
          ></Route>
          <Route
            path={RouteConstants.DASHBOARD_PDP}
            element={<DashboardPage />}
          ></Route>
          <Route
            path={RouteConstants.RESET_PASSWORD}
            element={<ResetPasswordPage />}
          />
          <Route
            path={RouteConstants.COMPARE_PLANS}
            element={<ComparePlansPage />}
          />
          <Route path={RouteConstants.PROFILE} element={<ProfilePage />}>
            <Route path='myprofile' element={<MyProfileSection />}>
              <Route
                path='profile'
                element={<ProfileComponent isSpouse={false} />}
              />
              <Route
                path='contact'
                element={<ContactComponent isSpouse={false} />}
              />
              <Route
                path='health'
                element={<HealthComponent isSpouse={false} />}
              />
              <Route
                path='prescriptions'
                element={
                  <AddPrescriptionComponent redirect={false} isSpouse={false} />
                }
              />
              <Route
                path='pharmacy'
                element={
                  <PharmacyScreenInfoSteps
                    showBottomStepNavigator={false}
                    redirect={false}
                    isSpouse={false}
                  />
                }
              />
              <Route
                path='doctors'
                element={
                  <AddDoctorComponent
                    showBottomStepNavigator={false}
                    redirect={false}
                    isSpouse={false}
                  />
                }
              />
              <Route
                path='medicare-info'
                element={<MedicareInfoComponent isSpouse={false} />}
              />
              <Route
                path='settings'
                element={<PreferencesComponent isSpouse={false} />}
              />
            </Route>

            <Route path='spouseprofile' element={<SpouseSection />}>
              <Route
                path='profile'
                element={<ProfileComponent isSpouse={true} />}
              />
              <Route
                path='health'
                element={<HealthComponent isSpouse={true} />}
              />
              <Route
                path='prescriptions'
                element={
                  <AddPrescriptionComponent redirect={false} isSpouse={true} />
                }
              />
              <Route
                path='pharmacy'
                element={
                  <PharmacyScreenInfoSteps
                    showBottomStepNavigator={false}
                    redirect={false}
                    isSpouse={true}
                  />
                }
              />
              <Route
                path='doctors'
                element={
                  <AddDoctorComponent
                    showBottomStepNavigator={false}
                    redirect={false}
                    isSpouse={true}
                  />
                }
              />
              {/* <Route path='settings' element={<PreferencesComponent isSpouse={true} />} /> */}
              <Route
                path='medicare-info'
                element={<MedicareInfoComponent isSpouse={true} />}
              />
              <Route
                path='*'
                element={
                  <Navigate
                    replace
                    to={RouteConstants.PROFILE + '/spouseprofile/profile'}
                  />
                }
              />
            </Route>
          </Route>
          <Route path={RouteConstants.INFO_STEPS} element={<InfoStepsPage />}>
            <Route
              path={RouteConstants.INFO_STEPS_QUESTIONNAIRE}
              element={<QuestionnaireScreen />}
            />
            <Route
              path={RouteConstants.INFO_STEPS_PRESCRIPTION}
              element={<PrescriptionScreen pathContext='infoSteps' />}
            />
            <Route
              path={RouteConstants.INFO_STEPS_HEALTH}
              element={<HealthQuestion />}
            />
            <Route
              path={RouteConstants.INFO_STEPS_PHARMACY}
              element={
                <PharmacyScreenInfoSteps showBottomStepNavigator={true} />
              }
            />
            <Route
              path={RouteConstants.INFO_STEPS_DOCTORS}
              element={<DoctorsScreen showBottomStepNavigator={true} />}
            />
            <Route
              path={RouteConstants.INFO_STEPS_CURRENT_PLAN}
              element={<CurrentPlan />}
            />
            <Route path='*' element={<PrescriptionScreen />} />
          </Route>
          {/* <Route
            path={RouteConstants.COMPLETE_PLAN_DETAILS}
            element={<CompletePlanDetailsPage />}
          /> */}
          <Route
            path={RouteConstants.SAVED_PLANS}
            element={<SavedPlansPage />}
          />
          <Route
            path={RouteConstants.FORGOT_PASSWORD}
            element={<ForgotPasswordPage />}
          />
          <Route
            path='*'
            element={<Navigate to={RouteConstants.DASHBOARD} />}
          />
        </>
      ) : (
        <>
          <Route path={RouteConstants.LOGIN} element={<LoginPage />} />
          <Route
            path={RouteConstants.FORGOT_PASSWORD}
            element={<ForgotPasswordPage />}
          />
          <Route
            path={RouteConstants.RESET_PASSWORD_FORGOT}
            element={<ResetPasswordPage />}
          />
          <Route
            path={RouteConstants.VALIDATE_FORGOT_PASSWORD}
            element={<ForgotPasswordResetScreen />}
          />
          <Route path='*' element={<Navigate to={RouteConstants.LOGIN} />} />
        </>
      )}
    </Routes>
  )
}
export default withRouter(observer(App))
