import {
  AiFillHeart as FilledHeart,
  AiOutlineHeart as OutlinedHeart,
} from 'react-icons/ai'
import React, { useState } from 'react'
import { savePlan, undoSavePlan } from '../../../service/quotes'

import { Checkbox } from '@mui/material'
import { FaHourglassHalf } from 'react-icons/fa'
import { MedicareQuotesType } from '../../../enums/MedicareQuoteTypeEnum'
import StringConstants from '../../../constants/StringConstants'
import customerStore from '../../../datastore/CustomerStore'
import { fireEvent } from '../../../cra'
import { getApiErrorMessage } from '../../../utils/StringUtils'
import snackbarStore from '../../../datastore/SnackbarStore'
import theme from '../../../global/theme'

interface SavePlanIconButtonProps {
  planId: string
  isPrimaryPlanSaved: boolean
  spousePlanId?: string
  planType: MedicareQuotesType
  saved: boolean
  page: string
  forSpouse: boolean
  onAddSaved: (planId: string) => void
  onRemoveSaved: (planId: string) => void
}

const SavePlanIconButton: React.FC<SavePlanIconButtonProps> = (
  planInfo: SavePlanIconButtonProps
) => {
  const [loading, setLoading] = useState<boolean>(false)

  const savePlanAction = (e: any) => {
    fireEvent('click', e, {
      label: 'Save Plan',
      description: `${planInfo.page} - Save Plan`,
    })

    setLoading(true)
    try {
      if (!planInfo.saved) {
        const promiseArray = []

        if (!planInfo.isPrimaryPlanSaved) {
          promiseArray.push(savePlan(planInfo.planId, planInfo.planType, false))
        }
        if (planInfo.forSpouse) {
          if (planInfo.spousePlanId)
            promiseArray.push(
              savePlan(planInfo.spousePlanId, planInfo.planType, true)
            )
          else throw new Error('Spouse Plan ID is required')
        }
        if (promiseArray.length > 0) {
          Promise.all(promiseArray)
            .then(() => {
              snackbarStore.set({
                snackbarOpen: true,
                snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
                snackbarType: 'success',
              })
              planInfo.onAddSaved(planInfo.planId)
              customerStore.setSavePlanStatusObject({
                planId: planInfo.planId,
                planType: planInfo.planType,
                status: true,
              })
              fireEvent('savePlanSuccessful', e, {
                planId: planInfo.planId,
                planType: planInfo.planType,
                label: 'Save Plan',
                description: `${planInfo.page} - Saving Plan Successful`,
                value: 'Successful',
              })
            })
            .catch((err) => {
              if (
                err.data.message === 'Maximum limit reached for saving plans'
              ) {
                snackbarStore.set({
                  snackbarOpen: true,
                  snackbarMessage: getApiErrorMessage(
                    'save plan. You can save only upto 5 plans'
                  ),
                  snackbarType: 'error',
                })
                fireEvent('savePlanFailed', e, {
                  planId: planInfo.planId,
                  planType: planInfo.planType,
                  label: 'Save Plan',
                  value: 'Failed',
                  description: `${planInfo.page} - Saving Plan Failed - Exceeding Max Number of Saved Plans`,
                })
              } else {
                snackbarStore.set({
                  snackbarOpen: true,
                  snackbarMessage: getApiErrorMessage('save plan'),
                  snackbarType: 'error',
                })
                fireEvent('savePlanFailed', e, {
                  planId: planInfo.planId,
                  planType: planInfo.planType,
                  label: 'Save Plan',
                  value: 'Failed',
                  description: `${planInfo.page} - Saving Plan Failed`,
                })
              }
            })
            .finally(() => setLoading(false))
        } else setLoading(false)
      } else {
        const promiseArray = []

        if (planInfo.isPrimaryPlanSaved) {
          promiseArray.push(
            undoSavePlan(planInfo.planId, planInfo.planType, false)
          )
        }
        if (planInfo.forSpouse) {
          if (planInfo.spousePlanId)
            promiseArray.push(
              undoSavePlan(planInfo.spousePlanId, planInfo.planType, true)
            )
          else throw new Error('Spouse Plan ID is required')
        }
        if (promiseArray.length > 0) {
          Promise.all(promiseArray)
            .then(() => {
              snackbarStore.set({
                snackbarOpen: true,
                snackbarMessage: 'Plan unsaved successfully',
                snackbarType: 'success',
              })
              planInfo.onRemoveSaved(planInfo.planId)
              customerStore.setSavePlanStatusObject({
                planId: planInfo.planId,
                planType: planInfo.planType,
                status: false,
              })
              fireEvent('removeSavedPlanSuccessful', e, {
                planId: planInfo.planId,
                planType: planInfo.planType,
                label: 'Save Plan',
                description: `${planInfo.page} - Removing Saved Plan Successful`,
                value: 'Successful',
              })
            })
            .catch((err) => {
              snackbarStore.set({
                snackbarOpen: true,
                snackbarMessage: getApiErrorMessage('delete plan'),
                snackbarType: 'error',
              })
              fireEvent('removeSavedPlanFailed', e, {
                planId: planInfo.planId,
                planType: planInfo.planType,
                label: 'Save Plan',
                value: 'Failed',
                description: `${planInfo.page} - Removing Saved Plan Successful`,
              })
            })
            .finally(() => setLoading(false))
        } else setLoading(false)
      }
    } catch (err) {}
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox
        icon={
          !loading ? (
            <OutlinedHeart
              style={{
                fontSize: '18px',
                color: `${theme.colors.secondary}`,
              }}
            />
          ) : (
            <FaHourglassHalf
              style={{
                fontSize: '18px',
              }}
            />
          )
        }
        checkedIcon={
          !loading ? (
            <FilledHeart
              style={{
                fontSize: '18px',
                color: `${theme.colors.secondary}`,
              }}
            />
          ) : (
            <FaHourglassHalf
              style={{
                fontSize: '18px',
              }}
            />
          )
        }
        checked={planInfo.saved}
        onChange={savePlanAction}
        disabled={loading}
      />
      <span style={{ whiteSpace: 'nowrap' }}>
        {planInfo.saved ? 'Saved Plan' : 'Save Plan'}
      </span>
    </div>
  )
}

export default SavePlanIconButton
