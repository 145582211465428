import { APIResponse } from '../interface/APIResponseInterface'
import ApiManager from '../api/ApiManager'
import { AxiosResponse } from 'axios'
import { CustomerDTO } from '../datastore/CustomerStore'
import { HttpMethods } from '../enums/HttpMethodsEnum'
import { InfoStepEnum } from '../enums/InfoStepEnum'
import { Preference } from '../components/ProfilePage/ProfileSubSections/Preferences.component'
import { SnackbarTypes } from '../enums/SnackbarTypesEnum'
import UrlConstants from '../constants/UrlConstants'
import { getApiErrorMessage } from '../utils/StringUtils'
import snackbarStore from '../datastore/SnackbarStore'

export const updateInfoStepStatus = async (payload: any) => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.PATCH_INFO_STEPS.USECASE,
      HttpMethods.PATCH_METHOD,
      {
        $infoStep: payload,
      }
    )
      .then((response: APIResponse<string>) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response)
        } else {
          reject(response)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updateCustomerDetails = async (payload: any) => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_CUSTOMER_DETAILS.USECASE,
      HttpMethods.PATCH_METHOD,
      payload
    )
      .then(async (response) => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else throw response
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('update customer details'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
        reject(err)
      })
  })
}

export const addSpouseDetails = async (
  fName: string,
  lName: string,
  dob: string,
  gender: string
): Promise<string> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.ADD_SPOUSE.USECASE,
      HttpMethods.POST_METHOD,
      {
        spouseDateOfBirth: dob,
        spouseGender: gender,
        spouseFirstName: fName,
        spouseLastName: lName,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else {
          reject(response)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const removeSpouseDetails = async (): Promise<CustomerDTO> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.REMOVE_SPOUSE.USECASE,
      HttpMethods.DELETE_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else {
          reject(response)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getCustomerDetails = async (): Promise<any> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_CUSTOMER_DETAILS.USECASE,
      HttpMethods.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else {
          reject(response)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updateMSEffectiveDateFilter = async (
  msEffectiveDateFilterSelf: string
): Promise<any> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_CUSTOMER_DETAILS.USECASE,
      HttpMethods.PATCH_METHOD,
      {
        msEffectiveDateFilterSelf: msEffectiveDateFilterSelf,
      }
    )
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const updateInfoStep = async (
  infoStep: InfoStepEnum
): Promise<string> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.PATCH_INFO_STEPS.USECASE,
      HttpMethods.PATCH_METHOD,
      {
        $infoStep: infoStep,
      }
    )
      .then((response: AxiosResponse) => {
        if (response.status === 200) resolve(response.data.data)
        else reject(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updateSupplementalQuestions = async (
  payload: Partial<{
    effectiveDate: null | string
    quoteType: string
    planType: string
    provider: string
  }>,
  queryParams: { [key: string]: any }
): Promise<string> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.PATCH_SUPPLEMENTAL_QUESTIONS.USECASE,
      HttpMethods.PATCH_METHOD,
      payload,
      queryParams
    )
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else reject(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getCustomerPreference = async (
  forSpouse: boolean
): Promise<Preference> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PROFILE_PREFERENCES.USECASE,
      HttpMethods.GET_METHOD,
      { 'for-spouse': forSpouse }
    )
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else {
          reject(response)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updateCustomerPreference = async (
  preferences: Partial<Preference>,
  forSpouse: boolean
) => {
  return new Promise((resolve, reject) => {
    const payload = { ...preferences, forSpouse }
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.PATCH_PROFILE_PREFERENCES.USECASE,
      HttpMethods.PATCH_METHOD,
      payload
    )
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else {
          reject(response)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}
