import { makeAutoObservable, observable } from 'mobx'

import { MParDPlanFiltersRequest } from '../../interface/quotes/mNonSupplementPlans/medicarePartD/request/MPartDPlanFiltersRequest'
import { MPartDPlanDisplayFilter } from '../../interface/quotes/mNonSupplementPlans/medicarePartD/MPartDPlanDisplayFilters'
import { PaginationDetailType } from '../../interface/common/PaginationType'
import { SortDetailType } from '../../interface/common/SortDetailType'
import { startCase } from 'lodash'

class MPartDPlansFilterStore {
  @observable mPartDPlanFilters: MPartDPlanDisplayFilter
  @observable
  mPartDPlanRequestFilter: MParDPlanFiltersRequest
  @observable spouseMPartDPlanFilters: MPartDPlanDisplayFilter
  @observable
  spouseMPartDPlanRequestFilter: MParDPlanFiltersRequest
  @observable currentPlanYear: string
  @observable clearFilters: boolean
  @observable filterApplied: boolean
  @observable plansFetched: boolean
  @observable filtersFetched: boolean
  @observable totalCount: number | null
  @observable isFilterChanged: boolean
  @observable filtersActive: boolean
  @observable prevSortType: string | null
  @observable paginationLoader: boolean
  @observable paginationApplied: boolean
  @observable isPrimaryAndSpouseOption: boolean

  constructor() {
    makeAutoObservable(this, { mPartDPlanFilters: observable.struct })
    this.mPartDPlanFilters = {} as MPartDPlanDisplayFilter
    this.mPartDPlanRequestFilter = {}
    this.spouseMPartDPlanFilters = {} as MPartDPlanDisplayFilter
    this.spouseMPartDPlanRequestFilter = {}
    this.currentPlanYear = ''
    this.clearFilters = false
    this.filterApplied = false
    this.plansFetched = false
    this.filtersFetched = false
    this.isFilterChanged = false
    this.totalCount = null
    this.filtersActive = false
    this.prevSortType = null
    this.paginationLoader = false
    this.paginationApplied = false
    this.isPrimaryAndSpouseOption = false
  }

  getIsPrimaryAndSpouseOption(): boolean {
    return this.isPrimaryAndSpouseOption
  }

  setIsPrimaryAndSpouseOption(value: boolean): void {
    this.isPrimaryAndSpouseOption = value
  }

  setPlansFetched(planFetchedStatus: boolean) {
    this.plansFetched = planFetchedStatus
  }

  isPlanFetched() {
    return this.plansFetched
  }

  setFiltersFetched(filtersFetchedStatus: boolean) {
    this.filtersFetched = filtersFetchedStatus
  }

  isFiltersFetched() {
    return this.filtersFetched
  }

  getIsFilterChanged() {
    return this.isFilterChanged
  }

  setIsFilterChanged(_isFilterChanged: boolean) {
    this.isFilterChanged = _isFilterChanged
  }
  getMPartDPlansFilters() {
    return this.mPartDPlanFilters
  }

  setPartDPlansFilters(_mPartDPlanFilters: MPartDPlanDisplayFilter) {
    this.mPartDPlanFilters = _mPartDPlanFilters
  }

  setSpousePartDPlansFilters(
    _spouseMPartDPlanFilters: MPartDPlanDisplayFilter
  ) {
    this.spouseMPartDPlanFilters = _spouseMPartDPlanFilters
  }

  setSpouseMPartDPlanRequestFilters(
    _spouseMPartDPlanRequestFilter: MParDPlanFiltersRequest
  ) {
    this.spouseMPartDPlanRequestFilter = _spouseMPartDPlanRequestFilter
  }

  setFilterApplied(_filterApplied: boolean) {
    this.filterApplied = _filterApplied
  }

  isFilterApplied() {
    return this.filterApplied
  }

  isFiltersCleared() {
    return this.clearFilters
  }

  setClearFilters(_clearFilters: boolean) {
    this.clearFilters = _clearFilters
  }

  setSortDetail(_sortDetail: SortDetailType) {
    this.mPartDPlanRequestFilter.sortDetail = _sortDetail
  }

  setPrevSortType(_prevSortType: string | null) {
    this.prevSortType = _prevSortType
  }

  getMPartDPlanRequestFilters() {
    return this.mPartDPlanRequestFilter
  }

  setMPartDPlanRequestFilters(
    _mPartDPlanRequestFilter: MParDPlanFiltersRequest
  ) {
    this.mPartDPlanRequestFilter = _mPartDPlanRequestFilter
  }

  setPaginationDetails(_paginationDetail: PaginationDetailType) {
    this.mPartDPlanRequestFilter.paginationDetail = _paginationDetail
  }

  getPaginationDetails() {
    return this.mPartDPlanRequestFilter.paginationDetail
  }

  getTotalCount() {
    return this.totalCount
  }

  setTotalCount(_totalCount: number | null) {
    this.totalCount = _totalCount
  }

  setPaginationLoader(_paginationLoader: boolean) {
    this.paginationLoader = _paginationLoader
  }

  setPaginationApplied(_paginationApplied: boolean) {
    this.paginationApplied = _paginationApplied
  }

  clearMPartDPlanRequestFilters() {
    this.mPartDPlanRequestFilter = {} as MParDPlanFiltersRequest
  }

  setDrugNameOnAdditionOfDrug(drugName: string) {
    if (this.mPartDPlanFilters.drugCheckBoxes) {
      if (this.mPartDPlanFilters.drugCheckBoxes.length >= 0) {
        this.mPartDPlanFilters.drugCheckBoxes.push({
          checked: false,
          id: this.mPartDPlanFilters.drugCheckBoxes.length + 1,
          label: startCase(drugName),
          value: drugName,
        })
        if (this.mPartDPlanFilters.drugCheckBoxes.length > 1) {
          this.mPartDPlanFilters.drugCheckBoxes =
            this.mPartDPlanFilters.drugCheckBoxes.sort((a, b) =>
              a.label.localeCompare(b.label)
            )
        }
      }
    }
  }

  removeDrugNameOnDeletionOfDrug(drugName: string) {
    if (this.mPartDPlanFilters.drugCheckBoxes) {
      if (this.mPartDPlanFilters.drugCheckBoxes.length > 0) {
        this.mPartDPlanFilters.drugCheckBoxes =
          this.mPartDPlanFilters.drugCheckBoxes.filter((drugCheckbox) => {
            return drugCheckbox.value !== drugName
          })
        this.mPartDPlanRequestFilter.prescriptions?.splice(
          this.mPartDPlanRequestFilter.prescriptions.indexOf(drugName),
          1
        )
      }
    }
  }

  isFiltersActive(): boolean {
    return this.filtersActive
  }

  setFiltersActive(activeStatus: boolean) {
    this.filtersActive = activeStatus
  }

  clearStore() {
    this.mPartDPlanFilters.selectSortOption = ''
    this.mPartDPlanFilters = {} as MPartDPlanDisplayFilter
    this.currentPlanYear = ''
    this.clearFilters = false
    this.filterApplied = false
    this.plansFetched = false
    this.filtersFetched = false
    this.totalCount = null
    this.filtersActive = false
  }
}

const mPartDPlansFilterStore = new MPartDPlansFilterStore()

export default mPartDPlansFilterStore
