import React, { useEffect } from 'react'

import CheckboxGroup from '../../common/CheckboxGroup/CheckboxGroup.component'
import InputField from '../../common/InputField/InputField.component'
import Label from '../../common/Label/Label'
import StringConstants from '../../../constants/StringConstants'
import Tooltip from '../../common/Tooltip/Tooltip.component'
import { isEmpty } from 'lodash'
import theme from '../../../global/theme'
import toolTipOptionArray from '../../../constants/CheckboxOptionToolTipConstants'

interface CheckboxQuestionI {
  options: any
  response: any
  id: any
  question: any
  isRequired: any
  handleResponseSelection: (id: any, responseArray: any) => void
  checkboxGroupName?: string
  displayOrder?: number
  isCoverageFieldVisible?: boolean
  coverages?: string
  coverageError?: boolean
  coverageHelperText?: string
  setIsCoverageFieldVisible?: React.Dispatch<React.SetStateAction<boolean>>
  setCoverages?: React.Dispatch<React.SetStateAction<string>>
  setCoverageError?: React.Dispatch<React.SetStateAction<boolean>>
  setCoverageHelperText?: React.Dispatch<React.SetStateAction<string>>
}

const CheckboxQuestion: React.FC<CheckboxQuestionI> = ({
  options,
  response,
  id,
  question,
  isRequired,
  handleResponseSelection,
  checkboxGroupName = '',
  displayOrder,
  isCoverageFieldVisible,
  coverages,
  coverageError,
  coverageHelperText,
  setIsCoverageFieldVisible,
  setCoverages,
  setCoverageError,
  setCoverageHelperText,
}) => {
  let coverageResponse = ''

  const getCheckBoxValue = (response: any, item: any) => {
    if (response.includes(item.option)) {
      return true
    } else if (displayOrder === 6 && item.option === 'Others') {
      const optionsArray = options.map((value: { option: any }) => value.option)
      const hasOtherValue = response.filter(
        (value: any) => !optionsArray.includes(value)
      )
      if (hasOtherValue.length > 0) {
        coverageResponse = hasOtherValue[0].toString()
        return true
      }
    }
  }

  const [checkboxState, setCheckboxState] = React.useState<
    {
      id: number
      checked: boolean
      label: JSX.Element
      value: string
      disabled: boolean
    }[]
  >([
    ...options.map((item: any, index: number) => {
      return {
        id: index,
        checked: response && getCheckBoxValue(response, item) ? true : false,
        label: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p>{item.option}</p>
            {toolTipOptionArray.hasOwnProperty(item.option) && (
              <div style={{ marginTop: '1px' }}>
                <Tooltip title={toolTipOptionArray[item.option]}></Tooltip>
              </div>
            )}
          </div>
        ),
        value: item.option,
        disabled:
          response &&
          response.includes('None of the above') &&
          item.option !== 'None of the above'
            ? true
            : false,
      }
    }),
  ])

  useEffect(() => {
    coverageResponse && setCoverages && setCoverages(coverageResponse)
  }, [])

  useEffect(() => {
    const val = checkboxState.find((item) => item.value === 'Others')
    const isChecked = val ? val.checked : false
    setIsCoverageFieldVisible && setIsCoverageFieldVisible(isChecked)
    !isChecked && setCoverages && setCoverages('')
  }, [checkboxState])

  const validateOtherCoverages = (coverages: string) => {
    setCoverageError &&
      setCoverageError(
        isEmpty(coverages) || coverages.length > 255 ? true : false
      )
    setCoverageHelperText &&
      setCoverageHelperText(
        isEmpty(coverages)
          ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_HEALTH_QUESTION_COVERAGE
          : coverages.length > 255
          ? StringConstants.UI_ERROR_MSG_FOR_MORE_CHARACTERS_THAN_SF_LIMIT
          : ''
      )
  }

  const handleCoverageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateOtherCoverages(e.target.value)
    setCoverages && setCoverages(e.target.value)
    const updatedState = checkboxState.map((item) =>
      item.value === e.target.value ? { ...item, checked: !item.checked } : item
    )

    handleResponseSelection(id, [
      ...updatedState
        .filter((item) => item.checked)
        .map((item) => (item.value === 'Others' ? e.target.value : item.value)),
    ])
  }

  const handleCheckboxState = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== 'None of the above') {
      const updatedState = checkboxState.map((item) =>
        item.value === e.target.value
          ? { ...item, checked: !item.checked }
          : item
      )

      handleResponseSelection(id, [
        ...updatedState
          .filter((item) => item.checked)
          .map((item) => (item.value === 'Others' ? coverages : item.value)),
      ])

      setCheckboxState(updatedState)
    } else if (e.target.value === 'None of the above' && e.target.checked) {
      const updatedState = checkboxState.map((item) =>
        item.value === 'None of the above'
          ? { ...item, checked: !item.checked }
          : { ...item, checked: false, disabled: true }
      )

      handleResponseSelection(id, [
        ...updatedState
          .filter((item) => item.checked)
          .map((item) => item.value),
      ])

      setCheckboxState(updatedState)
    } else if (e.target.value === 'None of the above' && !e.target.checked) {
      const updatedState = checkboxState.map((item) =>
        item.value === 'None of the above'
          ? { ...item, checked: !item.checked }
          : { ...item, checked: false, disabled: false }
      )

      handleResponseSelection(id, [
        ...updatedState
          .filter((item) => item.checked)
          .map((item) => item.value),
      ])

      setCheckboxState(updatedState)
    }
  }
  return (
    <div style={{ paddingBottom: '20px' }}>
      <Label style={{ paddingBottom: '10px' }}>
        {question}{' '}
        {isRequired && <span style={{ color: theme.colors.secondary }}>*</span>}
      </Label>
      <CheckboxGroup
        checkboxState={checkboxState}
        onChange={handleCheckboxState}
        width='100%'
        checkboxGroupName={checkboxGroupName}
        checkboxStyle={{
          background: theme.colors.backgroundColorContainer,
          borderRadius: '4px',
        }}
      />
      {displayOrder === 6 && isCoverageFieldVisible && (
        <div>
          <Label>
            Add your other coverages{' '}
            <span style={{ color: `${theme.colors.secondary}` }}>*</span>
          </Label>
          <InputField
            additionalTextPaddingLeft='15px'
            width='100%'
            label='Enter your other coverages'
            value={coverages}
            onChange={handleCoverageChange}
            error={coverageError}
            helperText={coverageHelperText}
          />
        </div>
      )}
    </div>
  )
}

export default CheckboxQuestion
