import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import AddSpouse from './AddSpouse.component'
import { AiOutlineMedicineBox } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { FiMenu } from 'react-icons/fi'
import { GiHeartPlus } from 'react-icons/gi'
import { IconButton } from '@mui/material'
import { ImFileText2 } from 'react-icons/im'
import LeftNavbar from './LeftNavbar.component'
import PharmacyIcon from '../common/Icon/PharmacyIcon.coponent'
import PrescriptionIcon from '../common/Icon/PrescriptionIcon.component'
import ProviderIcon from '../common/Icon/ProviderIcon.component'
import React from 'react'
import RouteConstants from '../../constants/RouteConstants'
import SecureComponent from '../../pages/common/SecureComponent'
import SettingIcon from '../common/Icon/SettingIcon.component'
import customerStore from '../../datastore/CustomerStore'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useCurrentSubrouteContext } from '../../pages/ProfilePage/ProfilePage'
import { withRouter } from '../common/WithRouter/WithRouter.component'

interface SpouseSectionContainerProps {
  display?: 'block' | 'none'
  location?: {
    state: {
      from: {
        pathname: string
      }
    }
  }
}

interface SpouseSectionProps extends SpouseSectionContainerProps { }

const Container = styled.div<SpouseSectionContainerProps>`
  display: ${(props) => props.display};
`

const ContentContainer = styled.div`
  display: flex;
`

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 80px;

  @media screen and (max-width: 950px) {
    padding: 20px 40px;
  }

  @media screen and (max-width: 600px) {
    padding: 10px 20px;
  }
`

const SectionHeading = styled.div`
  color: ${theme.colors.textDark};
  display: flex;
  align-items: center;
  position: sticky;
  top: 85px;
  z-index: 500;
  background-color: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.borderColor};
  padding: 10px 0px;
  h2 {
    font-size: 24px;
    font-weight: 600;
  }
  .nav-toggle {
    display: none;
  }
  @media screen and (max-width: 950px) {
    .nav-toggle {
      display: flex;
    }
  }
  @media screen and (max-width: 600px) {
    top: 95px;
    padding: 0px;
    h2 {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 400px) {
    top: 85px;
  }
`

const SpouseSection: React.FC<SpouseSectionProps> = (
  props: SpouseSectionProps
) => {
  const { setCurrentSubRoute, currentSubRoute } = useCurrentSubrouteContext()
  const [activeNav, setActiveNav] = React.useState(currentSubRoute)
  const [showLeftNav, setShowLeftNav] = React.useState(false)

  const navigate = useNavigate()
  const location = useLocation().pathname

  const navbarProps = {
    navLinks: [
      {
        label: 'Profile',
        icon: <CgProfile />,
        link: 'profile',
      },
      {
        label: "Medicare Info",
        link: 'medicare-info',
        icon: <ImFileText2 />
      },
      {
        label: 'Health',
        icon: <GiHeartPlus />,
        link: 'health',
      },
      {
        label: 'Prescriptions',
        link: 'prescriptions',
        icon: <PrescriptionIcon color={activeNav === 'prescriptions' ? '#FFFFFF' : undefined} />,
      },
      {
        label: 'Pharmacy',
        link: 'pharmacy',
        icon: <PharmacyIcon color={activeNav === 'pharmacy' ? '#FFFFFF' : undefined} />,
      },
      {
        label: 'Physicians',
        link: 'doctors',
        icon: (
          <ProviderIcon
            color={activeNav === 'doctors' ? '#FFFFFF' : undefined}
          ></ProviderIcon>
        ),
      }
    ],
  }

  const activeNavHandler = (link: string) => {
    setShowLeftNav(!showLeftNav)
    setActiveNav(link)
    setCurrentSubRoute(link)
    navigate(link, {
      state: {
        from:
          (props.location &&
            props.location.state &&
            props.location.state.from) ||
          RouteConstants.DASHBOARD,
      },
    })
  }

  const showLeftNavHandler = () => {
    setShowLeftNav(!showLeftNav)
  }

  React.useEffect(() => {
    if (currentSubRoute !== location.split('/')[3]) {
      setActiveNav(location.split('/')[3])
      setCurrentSubRoute(location.split('/')[3])
    }
  }, [currentSubRoute, location, setCurrentSubRoute])

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <Container display={props.display}>
      {customerStore.spouseOptIn ? (
        <ContentContainer>
          <LeftNavbar
            {...navbarProps}
            activeNav={activeNav}
            onClick={activeNavHandler}
            showLeftNavHandler={showLeftNavHandler}
            showLeftNav={showLeftNav}
            isSpouse={true}
          />
          <Wrapper>
            <SectionHeading>
              <IconButton
                className='nav-toggle'
                onClick={() => setShowLeftNav(!showLeftNav)}
              >
                <FiMenu style={{ color: `${theme.colors.primary}` }} />
              </IconButton>
              <h2>
                {
                  navbarProps.navLinks.find(
                    (iterator) => iterator.link === activeNav
                  )?.label
                }
              </h2>
            </SectionHeading>
            <Outlet />
          </Wrapper>
        </ContentContainer>
      ) : (
        <AddSpouse />
      )}
    </Container>
  )
}

export default SecureComponent(withRouter(observer(SpouseSection)))
