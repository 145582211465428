import React, { useState } from 'react'

import Button from '../../common/Button/Button.component'
import InputField from '../../common/InputField/InputField.component'
import RadioQuestion from '../../ProfilePage/common/RadioQuestion'
import StringConstants from '../../../constants/StringConstants'
import ValidationUtils from '../../../utils/validation/ValidationUtils'
import YearMonthDaySelector from '../../common/Select/YearMonthDaySelector'
import { isEmpty } from 'lodash'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface ModelProps {
  loading: boolean
  onConfirm: (
    date: string,
    gender: string,
    fName: string,
    lName: string
  ) => void
  onReject: () => void
}

const Container = styled.div`
  width: 500px;
  padding: 20px;
  @media screen and (max-width: 650px) {
    width: 100%;
    padding: 20px;
  }
  h3 {
    color: #272f69;
    padding-top: 0px;
    padding-bottom: 30px;
  }
  p {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
    color: ${theme.colors.textDark};
  }

  .confirmation_buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .find_my_medicare_button {
      width: 50%;
      @media screen and (max-width: 750px) {
        width: 100%;
      }
    }
  }
`
const ModalButton = styled.div`
  width: 150px;
  text-decoration: none;

  @media screen and (max-width: 750px) {
    width: 100%;
  }
`
const Question = styled.div``

const FieldLabel = styled.h3`
  padding: 10px 0px !important;
  font-size: 14px;
  font-weight: 600;
  color: ${theme.colors.textDark} !important;
  span {
    color: ${theme.colors.secondary};
  }
`

const AddSpouseConfirmationModal: React.FC<ModelProps> = ({
  loading,
  onConfirm,
  onReject,
}) => {
  const [year, setYear] = useState<number | ''>('')
  const [month, setMonth] = useState<number | ''>('')
  const [day, setDay] = useState<number | ''>('')
  const [errors, setErrors] = useState({ year: '', month: '', day: '' })
  const [firstName, setFirstName] = React.useState('')
  const [firstNameError, setFirstNameError] = React.useState(false)
  const [firstNameHelperText, setFirstNameHelperText] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [lastNameError, setLastNameError] = React.useState(false)
  const [lastNameHelperText, setLastNameHelperText] = React.useState('')
  const [genderError, setGenderError] = React.useState<string>('')

  const [showConfirmationContent, setShowConfirmationContent] =
    React.useState<boolean>(false)

  const [gender, setGender] = useState<'male' | 'female' | ''>('')

  const handleOnOk = () => {
    setShowConfirmationContent(true)
  }

  const validateDate = () => {
    const newErrors = { year: '', month: '', day: '' }

    if (year === '') newErrors.year = 'Required'
    if (month === '') newErrors.month = 'Required'
    if (day === '') newErrors.day = 'Required'

    if (year && month && day) {
      const date = new Date(year, month - 1, day)
      if (
        date.getFullYear() !== year ||
        date.getMonth() + 1 !== month ||
        date.getDate() !== day
      ) {
        newErrors.day = 'Invalid date'
      }
    }

    setErrors(newErrors)

    return Object.values(newErrors).every((error) => error === '')
  }

  const validateFirstName = (firstName: string) => {
    const firstNameError = !ValidationUtils.validateFirstName(firstName)
    setFirstNameError(firstNameError)
    setFirstNameHelperText(
      isEmpty(firstName)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_FIRST_NAME
        : firstNameError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_FIRST_NAME
        : ''
    )
  }
  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateFirstName(e.target.value)
    setFirstName(e.target.value)
  }

  const validateLastName = (lastName: string) => {
    const lastNameError = !ValidationUtils.validateLastName(lastName)
    setLastNameError(lastNameError)
    setLastNameHelperText(
      isEmpty(lastName)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_LAST_NAME
        : lastNameError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_LAST_NAME
        : ''
    )
  }
  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateLastName(e.target.value)
    setLastName(e.target.value)
  }

  const handleResponseSelection = (id: any, responseArray: any) => {
    setGender(responseArray[0])
  }

  const validateGender = () => {
    if (isEmpty(gender)) {
      setGenderError('Required')
    }
  }
  const handleSubmit = () => {
    validateFirstName(firstName)
    validateLastName(lastName)
    validateGender()
    if (
      validateDate() &&
      !isEmpty(firstName) &&
      !isEmpty(lastName) &&
      !isEmpty(gender)
    ) {
      onConfirm(
        `${year}-${month.toString().padStart(2, '0')}-${day
          .toString()
          .padStart(2, '0')}`,
        gender.toLowerCase(),
        firstName,
        lastName
      )
    }
  }

  return (
    <Container>
      <h3>Would You Like to Add a Spouse?</h3>
      <p style={{ textAlign: 'center' }}>
        Household discounts up to 15% may be available for each member. Would
        you like to view rates for a Spouse/Partner?
      </p>
      {!showConfirmationContent ? (
        <div className='confirmation_buttons'>
          <ModalButton>
            <Button
              variant='outlined'
              bold={false}
              width='100%'
              color='primary'
              onClick={(e) => {
                handleOnOk()
              }}
              fontSize='14px'
            >
              Yes
            </Button>
          </ModalButton>

          <ModalButton>
            <Button
              color='primary'
              bold={false}
              width='100%'
              onClick={(e) => {
                onReject()
              }}
              fontSize='14px'
            >
              No
            </Button>
          </ModalButton>
        </div>
      ) : (
        <>
          <Question>
            <FieldLabel>
              Your Spouse/Partner’s first name <span>*</span>
            </FieldLabel>
            <InputField
              // bottomPadding={false}
              error={firstNameError}
              helperText={firstNameHelperText}
              value={firstName}
              placeholder='First name'
              width='100%'
              color='primary'
              onChange={handleFirstNameChange}
            />
          </Question>
          <Question>
            <FieldLabel>
              Your Spouse/Partner's last name <span>*</span>
            </FieldLabel>
            <InputField
              // bottomPadding={false}
              error={lastNameError}
              helperText={lastNameHelperText}
              value={lastName}
              placeholder='Last name'
              width='100%'
              color='primary'
              onChange={handleLastNameChange}
            />
          </Question>
          <Question>
            <YearMonthDaySelector
              label='Spouse/Partner Date of Birth'
              onYearChange={setYear}
              onMonthChange={setMonth}
              onDayChange={setDay}
              errors={errors}
              setErrors={setErrors}
              loading={loading}
            ></YearMonthDaySelector>
          </Question>
          {/* <FormHelperText error>{errors.year}</FormHelperText>
          <FormHelperText error>{errors.month}</FormHelperText>
          <FormHelperText error>{errors.day}</FormHelperText> */}

          <Question>
            <RadioQuestion
              handleResponseSelection={handleResponseSelection}
              id={Math.random()}
              isRequired={true}
              options={[{ option: 'Male' }, { option: 'Female' }]}
              question={'Spouse/Partner Gender'}
              value={gender}
              loading={loading}
              error={isEmpty(genderError) ? false : true}
            ></RadioQuestion>
          </Question>
          <div className='confirmation_buttons'>
            <ModalButton className='find_my_medicare_button'>
              <Button
                variant='contained'
                bold={false}
                width='100%'
                color='secondary'
                onClick={(e) => {
                  handleSubmit()
                }}
                fontSize='14px'
                loading={loading}
              >
                Find My Medicare Plans
              </Button>
            </ModalButton>

            <ModalButton>
              <Button
                color='primary'
                bold={false}
                width='100%'
                onClick={(e) => {
                  onReject()
                }}
                fontSize='14px'
              >
                No
              </Button>
            </ModalButton>
          </div>
        </>
      )}
    </Container>
  )
}

export default AddSpouseConfirmationModal
