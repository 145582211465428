import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

import Button from '../Button/Button.component'
import { FiChevronLeft } from 'react-icons/fi'
import Icon from '../Icon/Icon.component'
import MuiTab from '@mui/material/Tab'
import MuiTabs from '@mui/material/Tabs'
import { PreferenceCoverageShortNameEnum } from '../../../enums/PreferenceCoverageShortNameEnum'
import QuotesPulledStatus from '../Notification/QuotesPulledStatus'
import React from 'react'
import RouteConstants from '../../../constants/RouteConstants'
import TabLabelComponent from './TabLabel.component'
import customerStore from '../../../datastore/CustomerStore'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../../global/theme'
import { useNavigate } from 'react-router-dom'

interface InPageHeaderProps {
  onChange: (event: React.SyntheticEvent, newValue: number) => void
  currentTab: number
  tabLabels: Array<tabLabel>
  heading?: string
  subHeading?: string
  showBackButton?: boolean
  locationState?: string
  type?: string
  isDashboard?: boolean
  isSavedPlans?: boolean
  justifyContent?: string
  bgColor?: string
  showBottomNavigator?: boolean
  isSidebarShown?: boolean
}

type tabLabel = {
  heading: string
  subHeading?: string
  subHeading2?: string
}

const InPageHeaderWrapper = styled.div`
  display: flex;
  .dummy-filter-placeholder {
    min-width: 350px;
    background-color: ${theme.colors.lightBack};
    @media screen and (max-width: 950px) {
      min-width: 0px;
      width: 0px;
    }
  }
`

const Container = styled.div<{ bgColor?: string }>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ bgColor }) =>
    bgColor ? bgColor : theme.colors.lightBack};
  text-align: center;
`
const ArrowButtonContainer = styled.div`
  @media (max-width: 600px) {
    display: flex;
    align-items: center;
    .hide-mobile-tabs {
      display: none;
    }
  }

  @media (min-width: 415px) and (max-width: 600px) {
    .fixed-width-tab {
      width: 314px;
    }
  }
`
const ArrowButton = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: block;
    cursor: pointer;
    padding: 15px;
  }
`

const Tab = styled(MuiTab)<{ isDashboard?: boolean; isSavedPlans?: boolean }>`
  background-color: ${theme.colors.white};
  border-radius: 8px;
  text-transform: capitalize;
  font-size: 18px;
  padding: 20px;
  margin: 0px 2px;
  min-width: ${({ isDashboard, isSavedPlans }) =>
    isDashboard || isSavedPlans ? '320px' : '150px'};
  position: relative;
  overflow: visible;

  &:hover {
    background-color: #f2f7fe;
  }

  @media (max-width: 1200px) {
    overflow: hidden;
  }

  @media (max-width: 600px) {
    width: 100%;
    min-width: 240px;
  }
`

const Heading = styled.h1`
  padding-top: 20px;
  font-weight: 700;
  color: ${theme.colors.primary};
  text-transform: uppercase;
  font-size: 32px;

  @media screen and (max-width: 600px) {
    font-size: 24px;
  }
`

const Tabs = styled(MuiTabs)<{ showBottomNavigator: boolean }>`
  .MuiTabs-indicator {
    height: 0px;
  }

  .Mui-selected {
    background-color: ${theme.colors.lightBack};
  }
`

const TabContainer = styled.div<{
  justifyContent?: string
}>`
  padding: 20px 0px;
  margin: auto;
  .tabs-wrapper {
    border-radius: 12px;
    background-color: ${theme.colors.white};
    padding: 10px;
    .MuiTabs-root {
      .MuiTabs-scrollableX {
        @media (max-width: 950px) {
          max-width: 80vw;
        }

        @media (min-width: 950px) and (max-width: 1428px) {
          max-width: 50vw;
        }
      }
    }
  }
`

const ButtonContainer = styled.div<{ showBackButton: boolean }>`
  display: ${(props) => (props.showBackButton ? 'block' : 'none')};
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 30px 0px 0px 80px;
  margin-left: -54px;

  @media screen and (max-width: 850px) {
    position: static;
    padding: 10px 0px 0px 20px;
    display: ${(props) => (props.showBackButton ? 'flex' : 'none')};
    margin-left: 0px;
  }

  @media screen and (max-width: 750px) {
    margin-left: -24px;
  }
`

const InPageHeader: React.FC<InPageHeaderProps> = ({
  onChange,
  currentTab,
  tabLabels,
  heading,
  showBackButton = true,
  locationState = RouteConstants.DASHBOARD,
  type,
  isDashboard,
  isSavedPlans,
  justifyContent,
  bgColor,
  showBottomNavigator = true,
  isSidebarShown = true,
}) => {
  const navigate = useNavigate()

  const handleBack = () => {
    if (!customerStore.isProfileUpdating) {
      if (type === PreferenceCoverageShortNameEnum.ma)
        locationState = RouteConstants.DASHBOARD_MA
      else if (type === PreferenceCoverageShortNameEnum.ms)
        locationState = RouteConstants.DASHBOARD_MS
      else if (type === PreferenceCoverageShortNameEnum.pdp)
        locationState = RouteConstants.DASHBOARD_PDP
      navigate(locationState)
    }
  }
  return (
    <InPageHeaderWrapper>
      {isSidebarShown && <div className='dummy-filter-placeholder'></div>}
      <Container bgColor={bgColor}>
        <ButtonContainer showBackButton={showBackButton}>
          <Button
            variant='text'
            width='100px'
            onClick={handleBack}
            description='Back Button'
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Icon childComponent={<FiChevronLeft />} />
              Back
            </span>
          </Button>
        </ButtonContainer>
        {customerStore.getCurrentTabStatus(currentTab) && (
          <QuotesPulledStatus />
        )}
        {heading && <Heading>{heading}</Heading>}
        <TabContainer justifyContent={justifyContent}>
          <div>
            <ArrowButtonContainer>
              <ArrowButton
                style={{
                  paddingLeft: currentTab === 0 ? '35px' : '',
                }}
              >
                {currentTab !== 0 && (
                  <span
                    onClick={(e) => {
                      if (currentTab > 0) {
                        onChange(e, currentTab - 1)
                      }
                    }}
                  >
                    <Icon
                      fontSize={'20px'}
                      childComponent={<IoIosArrowBack />}
                    />
                  </span>
                )}
              </ArrowButton>
              <div className='tabs-wrapper'>
                <Tabs
                  value={currentTab}
                  onChange={onChange}
                  variant='scrollable'
                  scrollButtons='auto'
                  showBottomNavigator={showBottomNavigator}
                >
                  {tabLabels.map((tabLabel, index) => (
                    <Tab
                      isDashboard={isDashboard}
                      isSavedPlans={isSavedPlans}
                      disableRipple
                      key={index}
                      label={<TabLabelComponent tabLabel={tabLabel} />}
                      className={`fixed-width-tab ${
                        index === currentTab ? '' : 'hide-mobile-tabs'
                      }`}
                    />
                  ))}
                </Tabs>
              </div>
              <ArrowButton
                style={{
                  paddingLeft:
                    currentTab === tabLabels.length - 1 ? '35px' : '',
                }}
              >
                {currentTab !== tabLabels.length - 1 && (
                  <span
                    onClick={(e) => {
                      if (currentTab < tabLabels.length - 1) {
                        onChange(e, currentTab + 1)
                      }
                    }}
                  >
                    <Icon
                      fontSize={'20px'}
                      childComponent={<IoIosArrowForward />}
                    />
                  </span>
                )}
              </ArrowButton>
            </ArrowButtonContainer>
          </div>
        </TabContainer>
      </Container>
    </InPageHeaderWrapper>
  )
}

export default observer(InPageHeader)
