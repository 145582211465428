import React, { useEffect } from 'react'

import ClickAwayListener from '@mui/material/ClickAwayListener'
import { TiArrowUnsorted } from 'react-icons/ti'
import { fireEvent } from '../../../../cra'
import mPartDPlansFilterStore from '../../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import msPlanFiltersStore from '../../../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface SortByProps {
  value?: { label: string; index: number }
  options: any[]
  onChange: (e: any, item: any) => void
}

interface SortByContainerProps {
  greyOut: boolean
}

const Container = styled.div`
  position: relative;
  border-radius: 32px;
  border: 1px solid ${theme.colors.textGrey};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  cursor: pointer;
  height: 100%;
  width: 100%;
`
const VerticalBorder = styled.div`
  height: 16px;
  width: 1px;
  background-color: ${theme.colors.textGrey};
  margin: 0px 10px;
`
const HorizontalBorder = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.borderColor};
  margin: 10px 0px;
`

const Left = styled.div`
  font-weight: 600;
  color: ${theme.colors.textDark};
  white-space: nowrap;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`

const Right = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: ${theme.colors.textGrey};
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`

const Dropdown = styled.div`
  position: absolute;
  top: 40px;
  left: 0px;
  z-index: 1;
  border-radius: 16px;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  border: 1px solid ${theme.colors.borderColor};
  background-color: white;
  padding: 20px 10px;
`
const DropdownElements = styled.p`
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: ${theme.colors.primary};
  display: flex;
  align-items: center;
  cursor: pointer;
`

const SortByContainer = styled.div<SortByContainerProps>`
  opacity: ${({ greyOut }) => (greyOut ? 0.5 : 1)};
  pointer-events: ${({ greyOut }) => (greyOut ? 'none' : 'auto')};

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`

const SortBy: React.FC<SortByProps> = ({ options, value, onChange }) => {
  const [dropDownState, setDropDownState] = React.useState(false)
  const [currentValue, setCurrentValue] = React.useState(value?.label)
  const [selectedState, setSelectedState] = React.useState(value?.index)

  useEffect(() => {
    setCurrentValue(value?.label)
    setSelectedState(value?.index)
  }, [value])
  return (
    <ClickAwayListener onClickAway={() => setDropDownState(false)}>
      <SortByContainer
        className='sort_by'
        greyOut={
          msPlanFiltersStore.filterApplied ||
          medicareAdvantageFilterQuoteStore.filterApplied ||
          mPartDPlansFilterStore.filterApplied
        }
      >
        <Container
          onClick={(e) => {
            setDropDownState(!dropDownState)
            fireEvent('click', e, {
              label: 'Sort By',
              value: value?.label,
              description: 'Dashboard - Sort By option',
            })
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TiArrowUnsorted />
            <Left>Sort by</Left>
          </div>
          <VerticalBorder />
          <Right>
            <p>{currentValue}</p>
          </Right>
          {dropDownState && (
            <Dropdown>
              {options.map((item, index) => (
                <div key={index + 1}>
                  <DropdownElements
                    key={index + 1}
                    onClick={(e) => {
                      onChange(e, item)
                      setCurrentValue(item.value)
                      setDropDownState(false)
                      setSelectedState(index)
                    }}
                    style={{
                      backgroundColor: `${
                        selectedState === index
                          ? theme.colors.contrastPrimary
                          : ''
                      }`,
                    }}
                  >
                    {item.title}
                  </DropdownElements>
                  <HorizontalBorder />
                </div>
              ))}
            </Dropdown>
          )}
        </Container>
      </SortByContainer>
    </ClickAwayListener>
  )
}

export default SortBy
