import MuiSelect, { SelectChangeEvent } from '@mui/material/Select'
import React, { MouseEventHandler } from 'react'

import FormControl from '@mui/material/FormControl'
import { FormHelperText } from '@mui/material'
import Label from '../Label/Label'
import MenuItem from '@mui/material/MenuItem'

interface SelectProps {
  width?: string
  height?: string
  margin?: string
  fontSize?: string
  onChange?: (e: SelectChangeEvent) => void
  value?: string | any
  options: string[] | number[]
  formLabel?: string
  borderRadius?: string
  labelMargin?: string
  padding?: string
  error?: boolean
  helperText?: string
  helperTextColor?: string
  helperTextFontSize?: string
  notched?: boolean
  shrink?: boolean
  onMouseUp?: MouseEventHandler<HTMLDivElement>
  responsivePopover?: boolean
  isInfoSteps?: boolean
  disabled?: boolean
  label?: string
  bottomPadding?: boolean
}

const Select: React.FC<SelectProps> = ({
  width = '100%',
  height = '40px',
  margin = '0px 0px',
  padding = '0px 0px',
  onChange,
  value,
  options,
  formLabel,
  borderRadius = '50px',
  labelMargin = '0px 0px 4px 0px',
  error = false,
  helperText = '',
  notched,
  shrink,
  onMouseUp,
  responsivePopover = false,
  isInfoSteps = false,
  disabled = false,
  label,
  bottomPadding = true,
  fontSize = '14px',
  helperTextColor = '#d32f2f',
  helperTextFontSize = '14px'
}) => {
  const isResponsivePopover: string = responsivePopover
    ? width
    : width + ' !important'
  return (
    <div style={{ paddingBottom: bottomPadding ? '20px' : 0 }}>
      {label && <Label style={{ paddingBottom: '10px' }}>{label}</Label>}
      <FormControl sx={{ minWidth: `${width}` }}>
        <MuiSelect
          notched={notched}
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={value || ''}
          onChange={onChange}
          onMouseUp={onMouseUp}
          MenuProps={{ keepMounted: true, disablePortal: true }}
          error={error}
          disabled={disabled}
          sx={{
            height: `${height}`,
            fontSize: fontSize,
            borderRadius: `${borderRadius}`,
            padding: `${padding}`,
            '& .MuiMenu-list': {
              maxHeight: '300px',
            },
            '@media screen and (max-width: 750px)': {
              width: width,
              '& .MuiMenu-list': {
                width: width,
              },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem value={option} key={option} style={{ minWidth: width }}>
              {option}
            </MenuItem>
          ))}
        </MuiSelect>
        <FormHelperText sx={{ color: `${helperTextColor} !important`, fontSize: helperTextFontSize, marginTop: 0.5 }} error={error}>
          {helperText}
        </FormHelperText>
      </FormControl>
    </div>
  )
}

export default Select
