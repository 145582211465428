import { AiOutlineClose } from 'react-icons/ai'
import IconButton from '@mui/material/IconButton'
import MuiSnackbar from '@mui/material/Snackbar'
import React from 'react'
import RouteConstants from '../../../constants/RouteConstants'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

type SnackbarType = 'success' | 'error' | 'warning' | 'default'

interface SnackbarProps {
  open: boolean
  message: string | undefined
  type?: SnackbarType
  onClose: () => void
}

const SnackbarStyled = styled(MuiSnackbar)<{
  type: SnackbarType
  locationpath: string
}>`
  .MuiSnackbarContent-root {
    background-color: ${({ type }) =>
      type === 'success'
        ? theme.colors.success
        : type === 'error'
        ? theme.colors.danger
        : type === 'warning'
        ? theme.colors.warning
        : theme.colors.primary};
    color: ${theme.colors.textLight};
    border-radius: 8px;
    margin-bottom: ${({ locationpath }) =>
      locationpath.startsWith(RouteConstants.INFO_STEPS) ? '100px' : '20px'};
  }
`

const Snackbar: React.FC<SnackbarProps> = ({
  open,
  message,
  type = 'default',
  onClose,
}) => {
  const action = (
    <React.Fragment>
      <IconButton
        size='small'
        aria-label='close'
        color='inherit'
        onClick={onClose}
      >
        <AiOutlineClose fontSize='small' />
      </IconButton>
    </React.Fragment>
  )

  return (
    <>
      <SnackbarStyled
        locationpath={window.location.pathname}
        message={message}
        open={open}
        onClose={onClose}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        type={type}
        action={action}
      />
    </>
  )
}

export default Snackbar
