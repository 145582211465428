const RouteConstants = {
  HOME: '/',
  POPUPDASHBOARD_FOR_MS: '/pop-up-dashboard/ms',
  POPUPDASHBOARD_FOR_MA: '/pop-up-dashboard/ma',
  POPUPDASHBOARD_FOR_PDP: '/pop-up-dashboard/pdp',
  LOGIN: '/login',
  REDIRECT: '/redirect',
  DASHBOARD: '/dashboard',
  DASHBOARD_MS: '/dashboard/ms',
  DASHBOARD_MA: '/dashboard/ma',
  DASHBOARD_PDP: '/dashboard/pdp',
  RESET_PASSWORD: '/reset-password',
  FORGOT_PASSWORD: '/forgot-password',
  VALIDATE_FORGOT_PASSWORD: '/forgot-password/validate',
  RESET_PASSWORD_FORGOT: '/forgot-password/reset',
  PROFILE: '/profile',
  INFO_STEPS: '/info-steps',
  INFO_STEPS_QUESTIONNAIRE: '/info-steps/questionnaire',
  INFO_STEPS_PRESCRIPTION: '/info-steps/prescription',
  INFO_STEPS_HEALTH: '/info-steps/health',
  INFO_STEPS_PHARMACY: '/info-steps/pharmacy',
  INFO_STEPS_DOCTORS: '/info-steps/doctors',
  INFO_STEPS_CURRENT_PLAN: '/info-steps/current-plan',
  COMPARE_PLANS: '/compare-plans',
  SAVED_PLANS: '/saved-plans',
  COMPLETE_PLAN_DETAILS: '/plan-details',
  ACCESS_DENIED: '/access-denied',
}

export default RouteConstants
