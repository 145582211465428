import Button from '../../../common/Button/Button.component'
import { PlanRecommendationScoresType } from '../../../../interface/questionnaire/PlanRecommendationScores'
import RouteConstants from '../../../../constants/RouteConstants'
import StringConstants from '../../../../constants/StringConstants'
import { observer } from 'mobx-react'
import searchPlansIcon from '../../../../assets/vectors/SearchPlansIcon.svg'
import theme from '../../../../global/theme'
import { useNavigate } from 'react-router-dom'

const recommendedPlanNames = {
  ma: 'Medicare Advantage Plan (Part C)',
  ms: 'Medicare Supplement Plan (Medigap)',
  pdp: 'Medicare Prescription Drug (Part D)',
}

const planBenefits = {
  ma: [
    'You prefer lower monthly premiums.',
    'You don’t need coverage in multiple locations.',
    ' You don’t mind a restrictive network of doctors.',
  ],
  ms: [
    'You prefer to pay less out-of-pocket.',
    'You want coverage while traveling.',
    'You want the flexibility to choose your doctor.',
  ],
  pdp: [
    'You are already enrolled in Original Medicare',
    'You take a lot of prescriptions drugs',
    'You want to save money on prescriptions',
  ],
}

const RecommendedPlan = () => {
  const recommendationScores = sessionStorage.getItem(
    StringConstants.PLAN_RECOMMENDATION_SCORES
  )
  const recommendationScoresObj: PlanRecommendationScoresType =
    recommendationScores
      ? JSON.parse(recommendationScores)
      : { ms: 0, ma: 0, pdp: 0 }
  const recommendedPlan = Object.keys(recommendationScoresObj).reduce((a, b) =>
    recommendationScoresObj[a as keyof PlanRecommendationScoresType] >
    recommendationScoresObj[b as keyof PlanRecommendationScoresType]
      ? a
      : b
  ) as 'ms' | 'ma' | 'pdp'

  const navigate = useNavigate()

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <div
        style={{
          maxWidth: '600px',
          gap: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: '30px',
            fontWeight: 700,
            color: theme.colors.primary,
            lineHeight: '40px',
          }}
        >
          <span>The results are in! </span>
          <span>Explore your options below.</span>
        </div>

        <img
          style={{ height: '80px', width: '80px' }}
          src={searchPlansIcon}
          alt='icon'
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '22px',
            color: '#515151',
          }}
        >
          <span>Based on the information you provided, our experts </span>
          <span>have calculated the best plan for your needs.</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
          >
            <span
              style={{
                fontWeight: 700,
                color: theme.colors.primary,
                fontSize: '20px',
              }}
            >
              {recommendedPlanNames[recommendedPlan]}
            </span>
            <span
              style={{ fontSize: '16px', fontWeight: 600, color: '#515151' }}
            >
              We recommend this plan type for you because:
            </span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '2% 98%',
                textAlign: 'start',
                alignItems: 'center',
                justifyItems: 'start',
                rowGap: '2px',
                columnGap: '10px',
                fontWeight: 600,
                color: '#515151',
                fontSize: '16px',
              }}
            >
              {planBenefits[recommendedPlan].map((benefit) => (
                <>
                  <span>•</span>
                  <span>{benefit}</span>
                </>
              ))}
            </div>
          </div>
        </div>
        <Button
          onClick={() => {
            if (recommendedPlan === 'ma') {
              navigate(RouteConstants.DASHBOARD_MA)
            } else if (recommendedPlan === 'pdp') {
              navigate(RouteConstants.DASHBOARD_PDP)
            } else {
              navigate(RouteConstants.DASHBOARD)
            }
          }}
        >
          View Plans
        </Button>
      </div>
    </div>
  )
}

export default observer(RecommendedPlan)
