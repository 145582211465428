import React from 'react'
import { Slider } from '@mui/material'
import Tooltip from '../Tooltip/Tooltip.component'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface SliderProps {
  minValue?: number
  maxValue?: number
  step?: number
  size?: 'small' | 'medium'
  valueLabelDisplay?: 'auto' | 'on' | 'off'
  onChange?: (e: React.SyntheticEvent | Event) => void
  onChangeCommitted?: (e: React.SyntheticEvent | Event) => void
  label?: string
  value?: number
  withTooltip?: boolean
  tooltipContent?: string
}

const SliderComponent: React.FC<SliderProps> = ({
  withTooltip = false,
  tooltipContent = '',
  minValue = 100,
  maxValue = 1000,
  step = 10,
  valueLabelDisplay = 'auto',
  label = '',
  size = 'small',
  value,
  onChange,
  onChangeCommitted,
}) => {
  if (minValue === maxValue) {
    return null
  }
  return (
    <Container>
      <Label>
        {label}
        {withTooltip && <Tooltip title={tooltipContent} />}
      </Label>

      <Slider
        min={minValue}
        max={maxValue}
        step={step}
        size={size}
        valueLabelDisplay={valueLabelDisplay}
        value={value}
        onChange={onChange}
        onChangeCommitted={onChangeCommitted}
      />
    </Container>
  )
}

const Label = styled.p`
  color: ${theme.colors.primary};
  font-size: 14px;
  font-weight: 700;
`

const Container = styled.div``

export default SliderComponent
