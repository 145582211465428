import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { cloneDeep, groupBy, isEmpty, isNull } from 'lodash'
import { getApiErrorMessage, titleCase } from '../../utils/StringUtils'
import {
  getCompleteDetailOfMSPlan,
  getCompleteDetailsOfMNSPlans,
} from '../../service/quotes'

import { ActionContext } from '../../cra'
import BouncingDotsLoader from '../../components/common/Loading/BouncingDotsLoader'
import DetailSection from '../../components/CompletePlanDetailsPage/DetailSection'
import HeaderSection from '../../components/CompletePlanDetailsPage/HeaderSection'
import { MAPlan } from '../../interface/quotes/mNonSupplementPlans/medicareAdvantage/MAPlan'
import { MPartDPlan } from '../../interface/quotes/mNonSupplementPlans/medicarePartD/MPartDPlan'
import { MSPlan } from '../../interface/quotes/mSupplementPlans/MSPlan'
import { MSPlanGenericDetails } from '../../interface/quotes/mSupplementPlans/MSPlanGenericDetails'
import { MSPlanGenericDisplayDetail } from '../../interface/common/MSPlanGenericDisplayDetail'
import { MSPlansRiderType } from '../../interface/quotes/mSupplementPlans/MSPlansRiderType'
import { MedicarePlanDetailsOverview } from '../../interface/quotes/MedicarePlanDetailsOverview'
import { MedicareQuotesType } from '../../enums/MedicareQuoteTypeEnum'
import ModalComponent from '../../components/common/Modal/Modal.component'
import { PackageBenefit } from '../../interface/quotes/mNonSupplementPlans/common/PackageBenefit'
import SecureComponent from '../common/SecureComponent'
import SortOrderConstants from '../../constants/SortOrderConstants'
import ThankYouModal from '../../components/DashboardPage/Modals/ThankYouModal'
import customerStore from '../../datastore/CustomerStore'
import { getMonthlyPremiumWithDiscounts } from '../../utils/DiscountCalculationUtils'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useSearchParams } from 'react-router-dom'

const Container = styled.div`
  background-color: ${theme.colors.white};
  min-height: 220px;
  min-width: 500px;

  @media print {
    @page {
      margin-top: 20mm;
      margin-bottom: 20mm;
    }
  }
`

interface CompletePlanDetailsPageProps {
  medicarePlanId: string
  medicarePlanType: 'ma' | 'ms' | 'mpd',
  printComponentRef: React.MutableRefObject<null>
  reactToPrint: any
}

const CompletePlanDetailsPage: FC<CompletePlanDetailsPageProps> = ({
  medicarePlanId,
  medicarePlanType,
  printComponentRef,
  reactToPrint
}) => {
  const [searchParams] = useSearchParams()
  const [maPlan, setMAPlan] = useState<MAPlan>()
  const [mPartDPlan, setMPartDPlan] = useState<MPartDPlan>()
  const [msPlan, setMSPlan] = useState<MSPlan>()
  const [msPlanGenericDetails, setMSPlanGenericDetails] =
    useState<MSPlanGenericDisplayDetail>()
  const [planType, setPlanType] = useState<MedicareQuotesType>(
    MedicareQuotesType.MEDICARE_SUPPLEMENT
  )
  const [sunfirePlanType, setSunfirePlanType] = useState<MedicareQuotesType>(
    MedicareQuotesType.MEDICARE_SUPPLEMENT
  )
  const [planId, setPlanId] = useState<string | null>(null)
  const [planName, setPlanName] = useState<string>()
  const [isFavorite, setIsFavorite] = useState<boolean>(false)
  const [overview, setOverview] = useState<MedicarePlanDetailsOverview>()
  const [planBenefits, setPlanBenefits] = useState<PackageBenefit[]>()
  const [thankYouModalActive, setThankYouModalActive] = useState<boolean>(false)
  const [state, setState] = useState({
    loading: true,
  })
  // const printComponentRef = useRef(null)
  const timerRef: { current: NodeJS.Timeout | null } = useRef(null)

  const handleOnEnroll = () => {
    setThankYouModalActive(true)
  }

  const { trackCurrentPage } = useContext(ActionContext)

  useEffect(() => {
    trackCurrentPage('Complete Plan Details')
    window.scrollTo({ top: 0, behavior: 'smooth' })
    // Clear the interval when the component unmounts
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current)
    }
  }, [trackCurrentPage])

  useEffect(() => {
    setPlanId(medicarePlanId)

    switch (medicarePlanType) {
      case 'ma':
        getCompleteDetailsOfMNSPlans(medicarePlanId)
          .then((maPlan) => {
            if (!isEmpty(maPlan[0])) {
              const convertedMAPlan: any = maPlan[0]
              setPlanName(convertedMAPlan.name)
              customerStore.setSearchPlan(convertedMAPlan.name)
              setPlanType(MedicareQuotesType.MEDICARE_NON_SUPPLEMENT)
              setSunfirePlanType(MedicareQuotesType.MEDICARE_ADVANTAGE)
              setMAPlan(convertedMAPlan)
              timerRef.current = setTimeout(() => {
                setState({
                  ...state,
                  loading: false,
                })
              }, 200)
            }
          })
          .catch((err: Error) => {
            console.log('err', err)
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: getApiErrorMessage(
                'fetch Medicare advantage plan'
              ),
              snackbarType: 'error',
            })
          })
        break
      case 'mpd':
        getCompleteDetailsOfMNSPlans(medicarePlanId)
          .then((mPartDPlan) => {
            if (!isEmpty(mPartDPlan[0])) {
              const convertedMPartDPlan: MPartDPlan = mPartDPlan[0]
              setPlanName(convertedMPartDPlan.name)
              customerStore.setSearchPlan(convertedMPartDPlan.name)
              setPlanType(MedicareQuotesType.MEDICARE_NON_SUPPLEMENT)
              setSunfirePlanType(MedicareQuotesType.MEDICARE_PRESCRIPTION)
              setMPartDPlan(convertedMPartDPlan)
              timerRef.current = setTimeout(() => {
                setState({
                  ...state,
                  loading: false,
                })
              }, 200)
            }
          })
          .catch((err: Error) => {
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: getApiErrorMessage('fetch Medicare part D plan'),
              snackbarType: 'error',
            })
          })
        break
      case 'ms':
        getCompleteDetailOfMSPlan(medicarePlanId)
          .then((msPlan) => {
            if (msPlan) {
              const msPlanDetails: {
                msPlan: MSPlan
                msPlanGenericDetails: MSPlanGenericDetails[]
              } = msPlan
              if (
                msPlanDetails.msPlan.riders &&
                msPlanDetails.msPlan.riders.length > 0
              ) {
                const tempRiders: MSPlansRiderType[] = []
                msPlanDetails.msPlan.riders.forEach((rider: any) => {
                  let x: MSPlansRiderType = JSON.parse(rider)
                  tempRiders.push(x)
                })
                msPlanDetails.msPlan.riders = tempRiders
              }
              setPlanName(
                `${msPlanDetails.msPlan.msCompanyBase.name} (Plan ${msPlanDetails.msPlan.planName})`
              )
              customerStore.setSearchPlan(
                `${msPlanDetails.msPlan.msCompanyBase.name} (Plan ${msPlanDetails.msPlan.planName})`
              )
              setPlanType(MedicareQuotesType.MEDICARE_SUPPLEMENT)
              setSunfirePlanType(MedicareQuotesType.MEDICARE_SUPPLEMENT)
              const msPlanGenericDetailsResponse: MSPlanGenericDetails[] =
                msPlanDetails.msPlanGenericDetails
              let displayResponse: MSPlanGenericDisplayDetail =
                {} as MSPlanGenericDisplayDetail
              displayResponse.planName = msPlanDetails.msPlan.planName
              const temp = groupBy(msPlanGenericDetailsResponse, 'partType')
              const sorted: string[] = []
              const others: string[] = []
              const otherBenefits: string[] = []
              const order = SortOrderConstants.MSPlanDetailsSortOrder
              Object.keys(temp).forEach((key: string) => {
                if (order.includes(key)) {
                  sorted[order.indexOf(key)] = key
                } else if (key === 'Other Benefits') {
                  otherBenefits.push(key)
                } else others.push(key)
              })
              const unique = sorted.concat(others.sort()).concat(otherBenefits)
              unique.forEach((key) => {
                const tableRecord: { key: string; value: string }[] = []
                temp[key].forEach((planDetail) => {
                  if (planDetail.noOfDays) {
                    planDetail.noOfDays?.forEach((day, index) => {
                      tableRecord.push({
                        key: `${!isNull(planDetail.serviceName)
                          ? planDetail.serviceName + ':'
                          : ''
                          } ${day}`,
                        value: `Plan pays ${planDetail.amountPaidByPlan[index]
                          } and you pay ${planDetail.amountPaidByCustomer[
                            index
                          ].toLowerCase()}`,
                      })
                    })
                  } else {
                    tableRecord.push({
                      key: 'Additional Deductible',
                      value: `$${planDetail.additionalDeductible.toString()}`,
                    })
                  }
                })
                displayResponse.planDetail
                  ? displayResponse.planDetail.push({
                    partType: titleCase(key),
                    partTypeDetails: tableRecord,
                  })
                  : (displayResponse.planDetail = [
                    {
                      partType: titleCase(key),
                      partTypeDetails: tableRecord,
                    },
                  ])
              })
              setMSPlan(msPlanDetails.msPlan)
              setMSPlanGenericDetails(cloneDeep(displayResponse))
              timerRef.current = setTimeout(() => {
                setState({
                  ...state,
                  loading: false,
                })
              }, 200)
            }
          })
          .catch((err: Error) => {
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: getApiErrorMessage(
                'fetch Medicare supplement plan details'
              ),
              snackbarType: 'error',
            })
          })
        break
    }
  }, [])

  useEffect(() => {
    if (maPlan) {
      const tempOverview: MedicarePlanDetailsOverview = {
        fmtPremium: maPlan.fmtPremium,
        fmtMedicalDeductible: maPlan.fmtMedicalDeductible,
        oonMoop: maPlan.oonMoop,
        moop: maPlan.moop,
        combMoop: maPlan.combMoop,
        fmtDeductible: maPlan.fmtDeductible,
        initialCoverageLimit: maPlan.initialCoverageLimit,
        catastrophicLimit: maPlan.catastrophicLimit,
        rating: maPlan.fmtRating,
      }
      setIsFavorite(maPlan.planSaved)
      setOverview(tempOverview)
      setPlanBenefits(maPlan.benefitSections)
    }
  }, [maPlan])

  useEffect(() => {
    if (mPartDPlan) {
      const tempOverview: MedicarePlanDetailsOverview = {
        fmtPremium: mPartDPlan.fmtPremium,
        fmtMedicalDeductible: mPartDPlan.fmtMedicalDeductible,
        initialCoverageLimit: mPartDPlan.initialCoverageLimit,
        catastrophicLimit: mPartDPlan.catastrophicLimit,
        fmtDeductible: mPartDPlan.fmtDeductible,
        rating: mPartDPlan.fmtRating,
        oonMoop: mPartDPlan.oonMoop,
        moop: mPartDPlan.moop,
        combMoop: mPartDPlan.combMoop,
      }
      setIsFavorite(mPartDPlan.planSaved)
      setOverview(tempOverview)
      setPlanBenefits(mPartDPlan.benefitSections)
    }
  }, [mPartDPlan])

  useEffect(() => {
    if (msPlan) {
      const monthlyPremiumWithDiscounts = getMonthlyPremiumWithDiscounts(
        +msPlan.mSupplementRate.month / 100,
        msPlan.id
      )
      const estimatedCostWithDiscounts =
        msPlan.estimatedAnnualCost -
        (+msPlan.mSupplementRate.month / 100 - monthlyPremiumWithDiscounts) * 12

      const tempOverview: any = {
        estimatedAnnualCost: estimatedCostWithDiscounts,
        estimatedAnnualDeductible: msPlan.annualDeductible,
        monthlyPremium: monthlyPremiumWithDiscounts,
      }
      setIsFavorite(msPlan.planSaved)
      setOverview(tempOverview)
    }
  }, [msPlan])

  const getCarrierLogoName = (
    planType: MedicareQuotesType
  ): string => {
    let logoName: string = ''
    switch (planType) {
      case MedicareQuotesType.MEDICARE_ADVANTAGE:
        logoName = maPlan?.carrierName || ''
        break
      case MedicareQuotesType.MEDICARE_SUPPLEMENT:
        logoName = msPlan?.msCompanyBase.name || ''
        break
      case MedicareQuotesType.MEDICARE_PRESCRIPTION:
        logoName = mPartDPlan?.carrierName || ''
        break
    }
    return logoName
  }

  const getEnrollRequestStatus = () => {
    if (maPlan) {
      return maPlan.enrollRequested
    } else if (msPlan) {
      return msPlan.enrollRequested
    } else if (mPartDPlan) {
      return mPartDPlan.enrollRequested
    }
  }
  // const reactToPrint = useReactToPrint({
  //   content: () => printComponentRef.current,
  // })

  const [printLoader, setPrintLoader] = React.useState<boolean>(false)
  const handleOnPrintClicked = () => {
    setPrintLoader(true)
    timerRef.current = setTimeout(reactToPrint, 0)
    setPrintLoader(false)
  }

  return (
    // <LayoutComponent>
    <Container >
      {state.loading ? (
        <div style={{ marginTop: 200 }}>
          <BouncingDotsLoader />
        </div>
      ) : (
        <>
          {thankYouModalActive && (
            <ModalComponent
              width={600}
              setOpen={() => setThankYouModalActive(false)}
            >
              <ThankYouModal
                setOpen={() => setThankYouModalActive(false)}
                to={`?id=${searchParams.get('id')}&&type=${searchParams.get(
                  'type'
                )}`}
              />
            </ModalComponent>
          )}
          {planId && planName && (
            <HeaderSection
              carrierLogoName={getCarrierLogoName(sunfirePlanType)}
              planId={planId}
              planName={planName}
              isFavorite={isFavorite}
              planType={planType}
              onAddSaved={() => setIsFavorite(true)}
              onRemoveSaved={() => setIsFavorite(false)}
              handleOnEnroll={handleOnEnroll}
              // printComponentRef={printComponentRef}
              enrollRequestedStatus={getEnrollRequestStatus()!}
              sunfirePlanType={sunfirePlanType}
              ridersApplied={
                planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                  ? msPlan?.ridersApplied
                  : undefined
              }
              handleOnPrintClicked={handleOnPrintClicked}
              printLoader={printLoader}
            />
          )}
          {overview && (
            <DetailSection
              carrierLogoName={getCarrierLogoName(sunfirePlanType)}
              printComponentRef={printComponentRef}
              planName={planName}
              overview={overview}
              planBenefits={planBenefits!}
              msPlanGenericDetails={msPlanGenericDetails}
              planType={planType}
              riders={
                planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                  ? msPlan?.riders || []
                  : undefined
              }
            />
          )}
        </>
      )}
    </Container>
    // </LayoutComponent>
  )
}

export default SecureComponent(CompletePlanDetailsPage)
