import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { Radio, Rating, Switch } from '@mui/material'
import React, { useState } from 'react'
import { isEmpty, isEqual, isNull, isUndefined } from 'lodash'

import CheckboxGroup from '../../../common/CheckboxGroup/CheckboxGroup.component'
import CloseIconButton from '../../../common/Button/CloseIconButton.component'
import { MAPlanFiltersRequest } from '../../../../interface/quotes/mNonSupplementPlans/medicareAdvantage/request/MAPlanFiltersRequest'
import { MedicareAdvantageDisplayFilter } from '../../../../interface/quotes/mNonSupplementPlans/medicareAdvantage/MedicareAdvantageDisplayFilters'
import ModalComponent from '../../../common/Modal/Modal.component'
import OptionalMessage from '../../Common/OptionalMessageFilter'
import PaginationConstants from '../../../../constants/PaginationConstants'
import PlanYearChangeConfirmationPopUp from '../../Modals/PlanYearChangeConfirmationPopUp'
import QuotesConstants from '../../../../constants/QuotesConstants'
import RouteConstants from '../../../../constants/RouteConstants'
import SliderComponent from '../../../common/Slider/Slider'
import { SliderType } from '../../../../interface/common/SliderType'
import StringConstants from '../../../../constants/StringConstants'
import Tooltip from '../../../common/Tooltip/Tooltip.component'
import { convertToUSCurrency } from '../../../../utils/CurrencyConverterUtil'
import customerStore from '../../../../datastore/CustomerStore'
import { fireEvent } from '../../../../cra'
import medicareAdvantageFilterQuoteStore from '../../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface FilterContainerProps {
  showFilter?: boolean
  greyOut?: boolean
}

const FilterWrapper = styled.div`
  min-width: 350px;
  @media screen and (max-width: 950px) {
    min-width: 0px;
    width: 0px;
  }
`

const Overlay = styled.div<FilterContainerProps>`
  @media screen and (max-width: 950px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: ${({ showFilter }) => (showFilter ? 'block' : 'none')};
    transition: background-color 0.3s ease;
  }
`

const Container = styled.div<FilterContainerProps>`
  background-color: ${theme.colors.white};
  border-right: 1px solid ${theme.colors.borderColor};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  z-index: 500;
  position: fixed;
  top: 0px;
  left: 0;
  height: 100%;
  width: 350px;
  padding: 100px 40px;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (max-width: 950px) {
    max-height: 100vh;
    width: 320px;
    padding: 20px;
    z-index: 1001;
    display: ${({ showFilter }) => (showFilter ? 'block' : 'none')};
  }
  pointer-events: ${({ greyOut }) => (greyOut ? 'none' : 'auto')};
`

const Filter = styled.div`
  padding: 20px 0px;
`

const Label = styled.p`
  color: ${theme.colors.primary};
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 8px;
`
const CloseButtonWrapper = styled.div`
  display: none;
  position: absolute;
  top: 10px;
  right: 0px;
  @media screen and (max-width: 950px) {
    display: block;
  }
`

const SliderContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

interface FilterAdvantageProps {
  showFilter: boolean
  handleShowFilter: () => void
  filterRef: React.MutableRefObject<any>
  clearFilterState: boolean
  maFiltersChanged: () => void
  clearButtonState: () => void
  setSelectedFilters?: (data: string[]) => void
  maPlansFilters: MedicareAdvantageDisplayFilter
  setMAPlansFilters: (data: MedicareAdvantageDisplayFilter) => void
  maPlansRequestBody: MAPlanFiltersRequest
  handleRequestBodyChange: (
    fieldsChanged: MAPlanFiltersRequest,
    forSpouse: boolean
  ) => void
  setPlanLoader: (data: boolean) => void
}

const FilterAdvantage: React.FC<FilterAdvantageProps> = ({
  showFilter,
  handleShowFilter,
  filterRef,
  clearFilterState,
  clearButtonState,
  setSelectedFilters,
  maPlansFilters,
  maFiltersChanged,
  setMAPlansFilters,
  maPlansRequestBody,
  handleRequestBodyChange,
  setPlanLoader,
}) => {
  const mAdvantageFilterRequest: MAPlanFiltersRequest = {}
  const [monthlyPremiumSliderValue, setMonthlyPremiumSliderValue] =
    useState<number>(maPlansFilters.monthlyPremium.currentValue)
  const [moopSliderValue, setMoopSliderValue] = useState<number>(
    maPlansFilters.moop.currentValue
  )
  const [
    openPlanYearFilterConfirmationPopup,
    setOpenPlanYearFilterConfirmationPopup,
  ] = useState<boolean>(false)
  const [tempPlanYear, setTempPlanYear] = useState('')
  const [carrierDropdown, setCarrierDropdown] = useState(false)
  const [typeDropdown, setTypeDropdown] = useState(false)
  const [benefitsDropdown, setBenefitsDropdown] = useState(false)
  const [drugsDropdown, setDrugsDropdown] = useState(false)
  const [providersDropDown, setProvidersDropDown] = useState(false)

  const handleSelectedFilters = () => {
    const carrierFilters = maPlansFilters.carriers
      ?.filter((carrier) => carrier.checked === true)
      .map((carrier) => carrier.value)
    const benefitsFilters = maPlansFilters.planBenefitsCheckbox
      ?.filter((benefit) => benefit.checked === true)
      .map((benefit) => benefit.value)
    const planTypeFilters = maPlansFilters.planTypeCheckbox
      ?.filter((planType) => planType.checked === true)
      .map((planType) => planType.value)
    const drugsFilters = maPlansFilters.drugsCheckbox
      ?.filter((drug) => drug.checked === true)
      .map((drug) => drug.value)
    const providerFilters = maPlansFilters.providers
      ?.filter((provider) => provider.checked === true)
      .map((provider) => provider.value)
    const selectedCarrierFilters = Array.isArray(carrierFilters)
      ? carrierFilters
      : [carrierFilters]
    const selectedBenefitsFilters = Array.isArray(benefitsFilters)
      ? benefitsFilters
      : [benefitsFilters]
    const selectedPlanTypeFilters = Array.isArray(planTypeFilters)
      ? planTypeFilters
      : [planTypeFilters]
    const selectedDrugsFilters = Array.isArray(drugsFilters)
      ? drugsFilters
      : [drugsFilters]
    const selectProviderFilters = Array.isArray(providerFilters)
      ? providerFilters
      : [providerFilters]

    setSelectedFilters &&
      setSelectedFilters([
        ...selectedCarrierFilters,
        ...selectedPlanTypeFilters,
        ...selectedBenefitsFilters,
        ...selectedDrugsFilters,
        ...selectProviderFilters,
      ])

    // Set status to active to disable count update
    medicareAdvantageFilterQuoteStore.setFiltersActive(
      [
        ...selectedCarrierFilters,
        ...selectedPlanTypeFilters,
        ...selectedBenefitsFilters,
        ...selectedDrugsFilters,
        ...selectProviderFilters,
      ].length !== 0
    )
  }

  const handleCarrierCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (maPlansFilters) {
      const checkedCarriers = maPlansFilters.carriers?.map((item) =>
        item.value === e.target.value
          ? { ...item, checked: !item.checked }
          : item
      )

      setMAPlansFilters({
        ...maPlansFilters,
        carriers: checkedCarriers,
      })

      handleRequestBodyChange(
        {
          carriers: checkedCarriers?.reduce((acc, item) => {
            if (item.checked) {
              acc.push(item.id)
            }
            return acc
          }, [] as number[]),
          paginationDetail: {
            page: PaginationConstants.DEFAULT_PAGE,
            size: PaginationConstants.DEFAULT_PAGE_SIZE,
          },
        },
        medicareAdvantageFilterQuoteStore.getIsPrimaryAndSpouseOption()
      )

      if (medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption) {
        medicareAdvantageFilterQuoteStore.spouseMedicareAdvantageFilters.carriers =
          checkedCarriers
      } else {
        medicareAdvantageFilterQuoteStore.medicareAdvantageFilters.carriers =
          checkedCarriers
      }

      // Use `checkedCarriers` here instead of the old `maPlansFilters.carriers`
      // setMAPlansRequestBody({
      //   ...maPlansRequestBody,
      //   carriers: checkedCarriers?.reduce((acc, item) => {
      //     if (item.checked) {
      //       acc.push(item.id)
      //     }
      //     return acc
      //   }, [] as number[]),
      //   paginationDetail: {
      //     page: PaginationConstants.DEFAULT_PAGE,
      //     size: PaginationConstants.DEFAULT_PAGE_SIZE,
      //   },
      // })

      setPlanLoader(true)
      window.scroll({ top: 0, behavior: 'smooth' })
      //medicareAdvantageFilterQuoteStore.setIsFilterChanged(true)
      // applyFilters()
    }
  }

  const handlePlanBenefitsCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (maPlansFilters) {
      const checkedPlanBenefits = maPlansFilters.planBenefitsCheckbox?.map(
        (item) =>
          item.value === e.target.value
            ? { ...item, checked: !item.checked }
            : item
      )
      setMAPlansFilters({
        ...maPlansFilters,
        planBenefitsCheckbox: checkedPlanBenefits,
      })

      handleRequestBodyChange(
        {
          planBenefits: checkedPlanBenefits?.reduce((acc, item) => {
            if (item.checked) {
              acc.push(item.value)
            }
            return acc
          }, [] as string[]),
          paginationDetail: {
            page: PaginationConstants.DEFAULT_PAGE,
            size: PaginationConstants.DEFAULT_PAGE_SIZE,
          },
        },
        medicareAdvantageFilterQuoteStore.getIsPrimaryAndSpouseOption()
      )
      if (medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption) {
        medicareAdvantageFilterQuoteStore.spouseMedicareAdvantageFilters.planBenefitsCheckbox =
          checkedPlanBenefits
      } else {
        medicareAdvantageFilterQuoteStore.medicareAdvantageFilters.planBenefitsCheckbox =
          checkedPlanBenefits
      }
      setPlanLoader(true)

      //medicareAdvantageFilterQuoteStore.setIsFilterChanged(true)
      // applyFilters()
    }
  }

  const handlePlanTypeCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (maPlansFilters) {
      const checkedPlanTypes = maPlansFilters.planTypeCheckbox?.map((item) =>
        item.value === e.target.value
          ? { ...item, checked: !item.checked }
          : item
      )
      setMAPlansFilters({
        ...maPlansFilters,
        planTypeCheckbox: checkedPlanTypes,
      })

      handleRequestBodyChange(
        {
          policyTypes: checkedPlanTypes?.reduce((acc, item) => {
            if (item.checked) {
              acc.push(item.value)
            }
            return acc
          }, [] as string[]),
          paginationDetail: {
            page: PaginationConstants.DEFAULT_PAGE,
            size: PaginationConstants.DEFAULT_PAGE_SIZE,
          },
        },
        medicareAdvantageFilterQuoteStore.getIsPrimaryAndSpouseOption()
      )

      if (!medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption) {
        medicareAdvantageFilterQuoteStore.medicareAdvantageFilters.planTypeCheckbox =
          checkedPlanTypes
      } else {
        medicareAdvantageFilterQuoteStore.spouseMedicareAdvantageFilters.planTypeCheckbox =
          checkedPlanTypes
      }

      setPlanLoader(true)
    }
  }

  const handleDrugsCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (maPlansFilters) {
      const checkedDrugs = maPlansFilters.drugsCheckbox?.map((item) =>
        item.value.toString() === e.target.value.toString()
          ? { ...item, checked: !item.checked }
          : item
      )
      setMAPlansFilters({
        ...maPlansFilters,
        drugsCheckbox: checkedDrugs,
      })

      handleRequestBodyChange(
        {
          prescriptions: checkedDrugs?.reduce((acc, item) => {
            if (item.checked) {
              acc.push(item.optionalValue!)
            }
            return acc
          }, [] as string[]),
          paginationDetail: {
            page: PaginationConstants.DEFAULT_PAGE,
            size: PaginationConstants.DEFAULT_PAGE_SIZE,
          },
        },
        medicareAdvantageFilterQuoteStore.getIsPrimaryAndSpouseOption()
      )

      if (medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption) {
        medicareAdvantageFilterQuoteStore.spouseMedicareAdvantageFilters.drugsCheckbox =
          checkedDrugs
      } else {
        medicareAdvantageFilterQuoteStore.medicareAdvantageFilters.drugsCheckbox =
          checkedDrugs
      }

      setPlanLoader(true)
    }
  }

  const handleProvidersCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (maPlansFilters) {
      const checkedProviders = maPlansFilters.providers?.map((item) =>
        item.value.toString() === e.target.value.toString()
          ? { ...item, checked: !item.checked }
          : item
      )
      setMAPlansFilters({
        ...maPlansFilters,
        providers: checkedProviders,
      })
      handleRequestBodyChange(
        {
          providers: checkedProviders?.reduce((acc, item) => {
            if (item.checked) {
              acc.push(item.optionalValue!)
            }
            return acc
          }, [] as string[]),
          paginationDetail: {
            page: PaginationConstants.DEFAULT_PAGE,
            size: PaginationConstants.DEFAULT_PAGE_SIZE,
          },
        },
        medicareAdvantageFilterQuoteStore.getIsPrimaryAndSpouseOption()
      )

      if (medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption) {
        medicareAdvantageFilterQuoteStore.spouseMedicareAdvantageFilters.providers =
          checkedProviders
      } else {
        medicareAdvantageFilterQuoteStore.medicareAdvantageFilters.providers =
          checkedProviders
      }

      setPlanLoader(true)
    }
  }

  const handleRxCoverage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (maPlansFilters) {
      setMAPlansFilters({
        ...maPlansFilters,
        rxIncluded: event.target.checked,
      })

      handleRequestBodyChange(
        {
          rxIncluded: event.target.checked,
        },
        medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption
      )
      setPlanLoader(true)
    }
  }
  const togglePlanYearFilterPopupOpen = () => {
    setOpenPlanYearFilterConfirmationPopup(!openPlanYearFilterConfirmationPopup)
  }
  const handleOnConfirmPlanYearChange = () => {
    setMAPlansFilters({
      ...maPlansFilters!,
      currentPlanYear: tempPlanYear,
    })
    customerStore.setMAFilterYear(tempPlanYear)
    customerStore.setMAPageSize(0)
    togglePlanYearFilterPopupOpen()
    window.scrollTo({ top: 0, behavior: 'smooth' })

    handleRequestBodyChange(
      { planYear: tempPlanYear },
      medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption
    )

    if (medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption) {
      medicareAdvantageFilterQuoteStore.spouseMedicareAdvantageFilters.currentPlanYear =
        tempPlanYear
    } else {
      medicareAdvantageFilterQuoteStore.medicareAdvantageFilters.currentPlanYear =
        tempPlanYear
    }
    setPlanLoader(true)
  }

  const handleOnRejectPlanYearChange = () => {
    setTempPlanYear('')
    togglePlanYearFilterPopupOpen()
  }

  const handlePlanYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (maPlansFilters) {
      const year = e.target.value

      if (e.target.checked) {
        setTempPlanYear(year.toString())
        togglePlanYearFilterPopupOpen()
      }
    }
  }

  const handleRatingChange = (value: number | null) => {
    if (maPlansFilters) {
      if (value && value >= maPlansFilters.ratingValue.minValue) {
        const sliderValue: SliderType = {
          ...maPlansFilters!.ratingValue!,
          currentValue: value,
        }

        setMAPlansFilters({
          ...maPlansFilters!,
          ratingValue: sliderValue,
        })

        handleRequestBodyChange(
          {
            minStarRating: {
              start: sliderValue.minValue,
              end: sliderValue.currentValue,
            },
          },
          medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption
        )

        setPlanLoader(true)
        if (medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption) {
          medicareAdvantageFilterQuoteStore.spouseMedicareAdvantageFilters.ratingValue =
            sliderValue
        } else {
          medicareAdvantageFilterQuoteStore.medicareAdvantageFilters.ratingValue =
            sliderValue
        }
      }
    }
  }

  const handleMaxMonthlyPremium = (e: any) => {
    if (maPlansFilters) {
      const monthlyPremiumValue = {
        ...maPlansFilters.monthlyPremium,
        currentValue: monthlyPremiumSliderValue,
      }
      setMAPlansFilters({
        ...maPlansFilters,
        monthlyPremium: monthlyPremiumValue,
      })

      handleRequestBodyChange(
        {
          monthlyPremium: {
            start: monthlyPremiumValue.minValue,
            end: monthlyPremiumValue.currentValue,
          },
        },
        medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption
      )
      setPlanLoader(true)

      if (medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption) {
        medicareAdvantageFilterQuoteStore.spouseMedicareAdvantageFilters.monthlyPremium =
          monthlyPremiumValue
      } else {
        medicareAdvantageFilterQuoteStore.medicareAdvantageFilters.monthlyPremium =
          monthlyPremiumValue
      }
    }
  }

  const handleMaxOutOfPocket = (e: any) => {
    if (maPlansFilters) {
      const moopValue = {
        ...maPlansFilters.moop,
        currentValue: moopSliderValue,
      }
      setMAPlansFilters({
        ...maPlansFilters,
        moop: moopValue,
      })

      handleRequestBodyChange(
        {
          moop: {
            start: moopValue.minValue,
            end: moopValue.currentValue,
          },
        },
        medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption
      )
      if (medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption) {
        medicareAdvantageFilterQuoteStore.spouseMedicareAdvantageFilters.moop =
          moopValue
      } else {
        medicareAdvantageFilterQuoteStore.medicareAdvantageFilters.moop =
          moopValue
      }

      setPlanLoader(true)
    }
  }

  const applyFilters = () => {
    if (!isEmpty(maPlansFilters)) {
      mAdvantageFilterRequest.carriers = maPlansFilters.carriers
        ?.filter((item) => item.checked)
        .map((item) => item.id)
      mAdvantageFilterRequest.policyTypes = maPlansFilters.planTypeCheckbox
        ?.filter((item) => item.checked)
        .map((item) => item.value)
      if (maPlansFilters.currentPlanYear !== '') {
        mAdvantageFilterRequest.planYear =
          customerStore.maFilterYear === ''
            ? maPlansFilters.currentPlanYear.toString()
            : customerStore.maFilterYear
      }

      mAdvantageFilterRequest.planBenefits = maPlansFilters.planBenefitsCheckbox
        ?.filter((item) => item.checked)
        .map((item) => item.value)

      mAdvantageFilterRequest.prescriptions = maPlansFilters.drugsCheckbox
        .filter((item) => item.checked)
        .map((item) => (item.optionalValue ? item.optionalValue : ''))

      mAdvantageFilterRequest.providers = maPlansFilters.providers
        .filter((item) => item.checked)
        .map((item) => (item.optionalValue ? item.optionalValue : ''))

      mAdvantageFilterRequest.rxIncluded = maPlansFilters.rxIncluded

      if (
        maPlansFilters.ratingValue.currentValue !==
        maPlansFilters.ratingValue.minValue
      ) {
        mAdvantageFilterRequest.minStarRating = {
          start: maPlansFilters.ratingValue.minValue,
          end: maPlansFilters.ratingValue.currentValue,
        }
      }

      if (maPlansFilters.moop.currentValue !== maPlansFilters.moop.maxValue) {
        mAdvantageFilterRequest.moop = {
          start: maPlansFilters.moop.minValue,
          end: maPlansFilters.moop.currentValue,
        }
      }

      if (
        maPlansFilters.monthlyPremium.currentValue !==
        maPlansFilters.monthlyPremium.maxValue
      ) {
        mAdvantageFilterRequest.monthlyPremium = {
          start: maPlansFilters.monthlyPremium.minValue,
          end: maPlansFilters.monthlyPremium.currentValue,
        }
      }

      if (
        !isNull(
          medicareAdvantageFilterQuoteStore.medicareAdvantageFilters
            .selectSortOption
        ) &&
        !isEmpty(
          medicareAdvantageFilterQuoteStore.medicareAdvantageFilters
            .selectSortOption
        )
      ) {
        if (
          medicareAdvantageFilterQuoteStore.medicareAdvantageFilters
            .selectSortOption === 'Overall Rating'
        ) {
          mAdvantageFilterRequest.sortDetail = {
            order: 'DESC',
            type: medicareAdvantageFilterQuoteStore.medicareAdvantageFilters
              .selectSortOption,
          }
        } else {
          mAdvantageFilterRequest.sortDetail = {
            order: 'ASC',
            type: medicareAdvantageFilterQuoteStore.medicareAdvantageFilters
              .selectSortOption,
          }
        }
      }
      mAdvantageFilterRequest.forSpouse =
        medicareAdvantageFilterQuoteStore.getIsPrimaryAndSpouseOption()

      if (
        !isEqual(
          JSON.stringify(mAdvantageFilterRequest),
          JSON.stringify(
            medicareAdvantageFilterQuoteStore.getMAPlanRequestFilter()
          )
        )
      ) {
        maPlansFilters.selectSortOption =
          medicareAdvantageFilterQuoteStore.medicareAdvantageFilters.selectSortOption
        medicareAdvantageFilterQuoteStore.setFilterApplied(true)
        medicareAdvantageFilterQuoteStore.setMedicareAdvantageFilters(
          maPlansFilters
        )

        mAdvantageFilterRequest.paginationDetail = {
          page: PaginationConstants.DEFAULT_PAGE,
          size: PaginationConstants.DEFAULT_PAGE_SIZE,
        }
        customerStore.setMAPageSize(PaginationConstants.DEFAULT_PAGE_SIZE)
        medicareAdvantageFilterQuoteStore.setMAPlanRequestFilter(
          mAdvantageFilterRequest
        )
      }
      handleShowFilter()
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const clearFilters = () => {
    if (maPlansFilters) {
      setMonthlyPremiumSliderValue(maPlansFilters.monthlyPremium.maxValue)
      setMoopSliderValue(maPlansFilters.moop.maxValue)
      setMAPlansFilters({
        ...maPlansFilters,
        carriers: maPlansFilters.carriers.map((item) => ({
          ...item,
          checked: false,
        })),
        currentPlanYear: maPlansFilters.planYear.defaultPlanYear,
        drugsCheckbox: maPlansFilters.drugsCheckbox.map((item) => ({
          ...item,
          checked: false,
        })),
        providers: maPlansFilters.providers.map((item) => ({
          ...item,
          checked: false,
        })),
        monthlyPremium: {
          ...maPlansFilters.monthlyPremium,
          currentValue: maPlansFilters.monthlyPremium.maxValue,
        },
        moop: {
          ...maPlansFilters.moop,
          currentValue: maPlansFilters.moop.maxValue,
        },
        planBenefitsCheckbox: maPlansFilters.planBenefitsCheckbox.map(
          (item) => ({ ...item, checked: false })
        ),
        planTypeCheckbox: maPlansFilters.planTypeCheckbox.map((item) => ({
          ...item,
          checked: false,
        })),
        ratingValue: {
          ...maPlansFilters?.ratingValue,
          currentValue: maPlansFilters.ratingValue.minValue,
        },
        rxIncluded: true,
        clearFiltersApplied: true,
        selectSortOption: QuotesConstants.initialMAPlanSortOption,
      })
      setSelectedFilters && setSelectedFilters([])
    }
  }

  const handleDropdownClick = (id: string) => {
    switch (id) {
      case 'carrier':
        setCarrierDropdown(!carrierDropdown)
        setTypeDropdown(false)
        setBenefitsDropdown(false)
        setDrugsDropdown(false)
        setProvidersDropDown(false)
        break
      case 'type':
        setTypeDropdown(!typeDropdown)
        setCarrierDropdown(false)
        setBenefitsDropdown(false)
        setDrugsDropdown(false)
        setProvidersDropDown(false)
        break
      case 'benefits':
        setBenefitsDropdown(!benefitsDropdown)
        setCarrierDropdown(false)
        setTypeDropdown(false)
        setDrugsDropdown(false)
        setProvidersDropDown(false)
        break
      case 'drugs':
        setDrugsDropdown(!drugsDropdown)
        setCarrierDropdown(false)
        setTypeDropdown(false)
        setBenefitsDropdown(false)
        setProvidersDropDown(false)
        break
      case 'providers':
        setProvidersDropDown(!providersDropDown)
        setDrugsDropdown(false)
        setCarrierDropdown(false)
        setTypeDropdown(false)
        setBenefitsDropdown(false)
        break
    }
  }

  return (
    <FilterWrapper ref={filterRef}>
      {openPlanYearFilterConfirmationPopup && (
        <ModalComponent
          setOpen={handleOnRejectPlanYearChange}
          hideCloseButton={true}
        >
          <PlanYearChangeConfirmationPopUp
            onConfirm={handleOnConfirmPlanYearChange}
            onReject={handleOnRejectPlanYearChange}
            year={tempPlanYear}
          />
        </ModalComponent>
      )}
      <Overlay showFilter={showFilter} />
      <Container
        greyOut={medicareAdvantageFilterQuoteStore.filterApplied}
        showFilter={showFilter}
      >
        <CloseButtonWrapper>
          <CloseIconButton
            fontSize='32px'
            onClick={() => {
              handleShowFilter()
            }}
          />
        </CloseButtonWrapper>
        {!isEmpty(maPlansFilters) ? (
          <div>
            <OptionalMessage
              heading='Showing plans for'
              link={RouteConstants.PROFILE + '/myprofile/contact'}
              linkText={customerStore.getPostalCodeAndCounty()}
              showToolTip={true}
            />
            <div className='filters-wrapper'>
              {maPlansFilters && (
                <Filter
                  key='PLanYear'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Label>Plan Year</Label>
                  {maPlansFilters.planYear.planYears.map((planYear) => (
                    <>
                      <Radio
                        key={'Plan_Year_Radio'}
                        value={planYear}
                        onChange={handlePlanYear}
                        size='small'
                        checked={
                          customerStore.maFilterYear === ''
                            ? maPlansFilters.currentPlanYear.toString() ===
                              planYear.toString()
                            : customerStore.maFilterYear === planYear.toString()
                        }
                      />
                      {planYear}
                    </>
                  ))}
                </Filter>
              )}
              <Filter
                key={'Star_Rating'}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Label>
                  Star Rating
                  <Tooltip title={StringConstants.TOOLTIP_MSG_MA_STAR_RATING} />
                </Label>
                {maPlansFilters.ratingValue && (
                  <Rating
                    name='simple-controlled'
                    value={maPlansFilters.ratingValue.currentValue}
                    precision={0.5}
                    onChange={(event, newValue) => {
                      fireEvent('starRatingInput', event, {
                        value: newValue,
                        label: 'Star Rating',
                        description: 'Star Rating Filter - Medicare Advantage',
                      })
                      handleRatingChange(newValue)
                    }}
                  />
                )}
              </Filter>
              {!isUndefined(maPlansFilters.rxIncluded) && (
                <Filter key={'RX_Coverage'}>
                  <Label>
                    Rx Coverage
                    <Tooltip
                      title={StringConstants.TOOLTIP_MSG_MA_RX_COVERAGE}
                    />
                  </Label>

                  <span
                    style={{
                      color: `${theme.colors.textGrey}`,
                      fontSize: '14px',
                    }}
                  >
                    Not Included
                  </span>
                  <Switch
                    checked={maPlansFilters.rxIncluded}
                    onChange={(e) => {
                      fireEvent('switchInput', e, {
                        label: 'Rx Coverage',
                        value: maPlansFilters.rxIncluded
                          ? 'Not Included'
                          : 'Included',
                        description:
                          'Rx Coverage Included/Not Included Filter - Medicare Advantage',
                      })
                      handleRxCoverage(e)
                    }}
                  />
                  <span
                    style={{
                      color: `${theme.colors.textGrey}`,
                      fontSize: '14px',
                    }}
                  >
                    Included
                  </span>
                </Filter>
              )}

              <Filter key={'Carrier'}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleDropdownClick('carrier')
                  }}
                >
                  <Label>
                    Carrier
                    <Tooltip title={StringConstants.TOOLTIP_MSG_MA_CARRIERS} />
                  </Label>
                  {carrierDropdown ? (
                    <BsChevronUp
                      style={{ cursor: 'pointer', marginLeft: 'auto' }}
                    />
                  ) : (
                    <BsChevronDown
                      style={{ cursor: 'pointer', marginLeft: 'auto' }}
                    />
                  )}
                </div>
                {carrierDropdown ? (
                  <div>
                    {maPlansFilters.carriers && (
                      <CheckboxGroup
                        checkboxState={maPlansFilters.carriers}
                        onChange={handleCarrierCheckboxChange}
                        width='100%'
                        type='unstyled'
                        checkboxGroupName='Carrier Filter - Medicare Advantage'
                      />
                    )}
                  </div>
                ) : null}
              </Filter>
              <Filter key={'Plan_Type'}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleDropdownClick('type')
                  }}
                >
                  <Label>
                    Plan Type
                    <Tooltip title={StringConstants.TOOLTIP_MSG_MA_PLAN_TYPE} />
                  </Label>
                  {typeDropdown ? (
                    <BsChevronUp
                      style={{ cursor: 'pointer', marginLeft: 'auto' }}
                    />
                  ) : (
                    <BsChevronDown
                      style={{ cursor: 'pointer', marginLeft: 'auto' }}
                    />
                  )}
                </div>
                {typeDropdown ? (
                  <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                    {maPlansFilters.planBenefitsCheckbox && (
                      <CheckboxGroup
                        checkboxState={maPlansFilters.planTypeCheckbox}
                        onChange={handlePlanTypeCheckboxChange}
                        width='100%'
                        type='unstyled'
                        checkboxGroupName='Plan Type Filter - Medicare Advantage'
                      />
                    )}
                  </div>
                ) : null}
              </Filter>
              <Filter key={'Plan_Benefits'}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleDropdownClick('benefits')
                  }}
                >
                  <Label>
                    Plan Benefits
                    <Tooltip
                      title={StringConstants.TOOLTIP_MSG_PLAN_BENEFITS}
                    />
                  </Label>
                  {benefitsDropdown ? (
                    <BsChevronUp
                      style={{ cursor: 'pointer', marginLeft: 'auto' }}
                    />
                  ) : (
                    <BsChevronDown
                      style={{ cursor: 'pointer', marginLeft: 'auto' }}
                    />
                  )}
                </div>
                {benefitsDropdown ? (
                  <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                    {maPlansFilters.planBenefitsCheckbox && (
                      <>
                        <CheckboxGroup
                          checkboxState={maPlansFilters.planBenefitsCheckbox}
                          onChange={handlePlanBenefitsCheckbox}
                          width='100%'
                          type='unstyled'
                          checkboxGroupName='Plan Benefits Filter - Medicare Advantage'
                        />
                      </>
                    )}
                  </div>
                ) : null}
              </Filter>

              {maPlansFilters.drugsCheckbox &&
                maPlansFilters.drugsCheckbox.length > 0 && (
                  <Filter key={'Drug_Coverage'}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleDropdownClick('drugs')
                      }}
                    >
                      <Label>
                        Drugs Covered
                        <Tooltip
                          title={StringConstants.TOOLTIP_MSG_MA_DRUGS_COVERED}
                        />
                      </Label>
                      {drugsDropdown ? (
                        <BsChevronUp
                          style={{ cursor: 'pointer', marginLeft: 'auto' }}
                        />
                      ) : (
                        <BsChevronDown
                          style={{ cursor: 'pointer', marginLeft: 'auto' }}
                        />
                      )}
                    </div>
                    {drugsDropdown ? (
                      <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                        <CheckboxGroup
                          checkboxState={maPlansFilters.drugsCheckbox}
                          onChange={handleDrugsCheckbox}
                          width='100%'
                          type='unstyled'
                          checkboxGroupName='Drugs Covered Filter - Medicare Advantage'
                        />
                      </div>
                    ) : null}
                  </Filter>
                )}
              {maPlansFilters.providers?.length > 0 && (
                <Filter key={'Providers_Coverage'}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleDropdownClick('providers')
                    }}
                  >
                    <Label>
                      Physician Covered
                      <Tooltip
                        title={StringConstants.TOOLTIP_MSG_MA_Physician_COVERED}
                      />
                    </Label>
                    {providersDropDown ? (
                      <BsChevronUp
                        style={{ cursor: 'pointer', marginLeft: 'auto' }}
                      />
                    ) : (
                      <BsChevronDown
                        style={{ cursor: 'pointer', marginLeft: 'auto' }}
                      />
                    )}
                  </div>
                  {providersDropDown ? (
                    <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                      <CheckboxGroup
                        checkboxState={maPlansFilters.providers}
                        onChange={handleProvidersCheckbox}
                        width='100%'
                        type='unstyled'
                        checkboxGroupName='Drugs Covered Filter - Medicare Advantage'
                      />
                    </div>
                  ) : null}
                </Filter>
              )}
              {!isUndefined(maPlansFilters.monthlyPremium) && (
                <Filter key={'Maximum_Monthly_Premium'}>
                  <SliderComponent
                    label='Maximum Monthly Premium'
                    minValue={maPlansFilters.monthlyPremium.minValue}
                    maxValue={maPlansFilters.monthlyPremium.maxValue}
                    step={maPlansFilters.monthlyPremium.stepValue}
                    size='small'
                    value={monthlyPremiumSliderValue}
                    onChange={(e: any) => {
                      setMonthlyPremiumSliderValue(e.target.value)
                    }}
                    onChangeCommitted={(e: any) => {
                      handleMaxMonthlyPremium(e)
                      const label = 'Maximum Monthly Premium'
                      const value = monthlyPremiumSliderValue
                      fireEvent('sliderInput', e, {
                        label,
                        value,
                        description: `Value of ${label} set to ${value}`,
                      })
                    }}
                    withTooltip={true}
                    tooltipContent={
                      StringConstants.TOOLTIP_MSG_MAXIMUM_MONTHLY_PREMIUM
                    }
                  />
                  <SliderContentContainer>
                    <div>
                      {convertToUSCurrency(
                        maPlansFilters.monthlyPremium.minValue
                      )}
                    </div>
                    <div>
                      {convertToUSCurrency(
                        maPlansFilters.monthlyPremium.maxValue
                      )}
                    </div>
                  </SliderContentContainer>
                </Filter>
              )}
              {!isUndefined(maPlansFilters.moop) && (
                <Filter key={'Maximum_Out_of_Pocket'}>
                  <SliderComponent
                    label='Maximum Out of Pocket'
                    minValue={maPlansFilters.moop.minValue}
                    maxValue={maPlansFilters.moop.maxValue}
                    step={maPlansFilters.moop.stepValue}
                    size='small'
                    value={moopSliderValue}
                    onChange={(e: any) => {
                      setMoopSliderValue(e.target.value)
                    }}
                    onChangeCommitted={(e: any) => {
                      handleMaxOutOfPocket(e)
                      const label = 'Maximum Out of Pocket'
                      const value = moopSliderValue
                      fireEvent('sliderInput', e, {
                        label,
                        value,
                        description: `Value of ${label} set to ${value}`,
                      })
                    }}
                    withTooltip={true}
                    tooltipContent={
                      StringConstants.TOOLTIP_MSG_MAXIMUM_OUT_OF_POCKET
                    }
                  />
                  <SliderContentContainer>
                    <div>
                      {convertToUSCurrency(maPlansFilters.moop.minValue)}
                    </div>
                    <div>
                      {convertToUSCurrency(maPlansFilters.moop.maxValue)}
                    </div>
                  </SliderContentContainer>
                </Filter>
              )}
            </div>
          </div>
        ) : null}
      </Container>
    </FilterWrapper>
  )
}

export default observer(FilterAdvantage)
