import { useNavigate, useSearchParams } from 'react-router-dom'

import AddPrescriptionStep from './AddPrescriptionStep'
import ApiManager from '../../../../api/ApiManager'
import DisclosureStep from './DisclosureStep'
import FallbackStep from './FallbackStep'
import { HttpMethods } from '../../../../enums/HttpMethodsEnum'
import { InfoStepEnum } from '../../../../enums/InfoStepEnum'
import MillimanQuestion from './MillimanQuestion'
import PrescriptionQuestion from './PrescriptionQuestion'
import QuotesRefreshProvider from '../../../../context/QuotesRefreshContext'
import React from 'react'
import ReviewPrescriptionStep from './ReviewPrescriptionStep'
import RouteConstants from '../../../../constants/RouteConstants'
import SecureComponent from '../../../../pages/common/SecureComponent'
import customerStore from '../../../../datastore/CustomerStore'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import { updateInfoStep } from '../../../../service/customer'

const Container = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
`

const PrescriptionScreen: React.FC<{
  pathContext?: string
}> = ({ pathContext = 'Profile' }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [buttonLoading, setButtonLoading] = React.useState(false)
  const [step, setStep] = React.useState<number>(1)
  const [forSpouse, setForSpouse] = React.useState(false)

  React.useEffect(() => {
    setForSpouse(searchParams.get('forSpouse') === 'true')
  }, [searchParams])

  const handleCompletePrescriptionStep = async () => {
    try {
      setButtonLoading(true)
      if (customerStore.getSpouseOptIn() === false || forSpouse) {
        await updateInfoStep(InfoStepEnum.PRESCRIPTION)
        navigate(RouteConstants.INFO_STEPS_HEALTH)
      } else if (customerStore.getSpouseOptIn() && !forSpouse) {
        setStep(6)
        navigate(`${RouteConstants.INFO_STEPS_PRESCRIPTION}/?forSpouse=true`)
      }
    } catch (error) {
      snackbarStore.set({
        snackbarMessage: 'Something went wrong',
        snackbarOpen: true,
        snackbarType: 'error',
      })
    } finally {
      setButtonLoading(false)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const handleStepBack = () => {
    if (step === 3) {
      setStep(2)
    } else {
      if (forSpouse) {
        navigate(RouteConstants.INFO_STEPS_PRESCRIPTION)
      }
      setStep(1)
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const getSubStepComponent = (step: number) => {
    switch (step) {
      case 1:
        return (
          <PrescriptionQuestion
            completePrescriptionStep={handleCompletePrescriptionStep}
            setStep={setStep}
            buttonLoading={buttonLoading}
          />
        )
      case 2:
        return (
          <MillimanQuestion
            setStep={setStep}
            buttonLoading={buttonLoading}
            handleStepBack={handleStepBack}
          />
        )
      case 3:
        return <DisclosureStep setStep={setStep} />
      case 4:
        return (
          <QuotesRefreshProvider>
            <ReviewPrescriptionStep
              setStep={setStep}
              completePrescriptionStep={handleCompletePrescriptionStep}
            />
          </QuotesRefreshProvider>
        )
      case 5:
        return (
          <FallbackStep
            setStep={setStep}
            completePrescriptionStep={handleCompletePrescriptionStep}
            buttonLoading={buttonLoading}
          />
        )
      case 6:
        return (
          <AddPrescriptionStep
            forSpouse={forSpouse}
            buttonLoading={buttonLoading}
            completePrescriptionStep={handleCompletePrescriptionStep}
            pathContext={pathContext}
            handleStepBack={handleStepBack}
          />
        )
      default:
        return (
          <AddPrescriptionStep
            forSpouse={forSpouse}
            buttonLoading={buttonLoading}
            completePrescriptionStep={handleCompletePrescriptionStep}
            pathContext={pathContext}
            handleStepBack={handleStepBack}
          />
        )
    }
  }

  return <Container>{getSubStepComponent(step)}</Container>
}

export default SecureComponent(PrescriptionScreen)
