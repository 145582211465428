import Button from '../../common/Button/Button.component'
import InputField from '../../common/InputField/InputField.component'
import MaskedInput from 'react-maskedinput'
import { MedicareQuotesType } from '../../../enums/MedicareQuoteTypeEnum'
import RadioGroup from '../../common/RadioGroup/RadioGroup.component'
import React from 'react'
import StringConstants from '../../../constants/StringConstants'
import ValidationUtils from '../../../utils/validation/ValidationUtils'
import customerStore from '../../../datastore/CustomerStore'
import { fireEvent } from '../../../cra'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface ModelProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onEnroll: () => void
  planName: string
  planType: MedicareQuotesType
  planId: string
  buttonLoading: boolean
  preferredPhoneNumber?: string
  setPreferredPhoneNumber?: (data: string) => void
}

const Container = styled.div`
  .highlight {
    color: ${theme.colors.secondary};
    font-size: 24px;
    font-weight: 700;
  }

  h2 {
    color: #272f69;
    padding-bottom: 30px;
  }
  p {
    padding-bottom: 10px;
    font-weight: 600;
    color: ${theme.colors.textGrey};
  }

  .buttons {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
`

const EnrollmentRequestConfirmation: React.FC<ModelProps> = ({
  setOpen,
  onEnroll,
  planName,
  planType,
  planId,
  buttonLoading,
  preferredPhoneNumber,
  setPreferredPhoneNumber,
}) => {
  const formatPhoneNumber = (phoneNumber: string): string => {
    phoneNumber = phoneNumber.replace('+1', '')
    const areaCode = phoneNumber.slice(0, 3)
    const firstPart = phoneNumber.slice(3, 6)
    const secondPart = phoneNumber.slice(6)

    return `(${areaCode}) ${firstPart}-${secondPart}`
  }
  const [isPreferredNumber, setIsPreferredNumber] =
    React.useState<boolean>(false)
  const [hasPreferredNumber, setHasPreferredNumber] = React.useState<string>('')
  const [preferredPhoneValue, setPreferredPhoneValue] =
    React.useState<string>('')
  const leadPhoneNumber = customerStore.get().phone.replace('+1', '')
  const formattedLeadPhoneNumber = formatPhoneNumber(customerStore.get().phone)
  const [state, setState] = React.useState({
    alternatePhoneError: false,
    alternatePhoneHelperText: '',
  })
  const validatePreferredPhone = (alternatePhone: string) => {
    var alternatePhoneError = true
    let conditionalStateData = {}
    ValidationUtils.verifyPhoneNumber(alternatePhone)
      .then((response: any) => {
        if (response.data.data.status === 'valid') {
          alternatePhoneError = false
          setState({
            ...state,
            alternatePhoneError:
              alternatePhoneError || leadPhoneNumber === alternatePhone,
            alternatePhoneHelperText: alternatePhoneError
              ? StringConstants.UI_ERROR_MSG_FOR_INVALID_ALTERNATE_PHONE
              : leadPhoneNumber === alternatePhone
              ? StringConstants.UI_ERROR_MSG_FOR_PREFERRED_PHONE_SAME_AS_PHONE
              : '',
            ...conditionalStateData,
          })
        }
      })
      .catch((err: Error) => {
        console.log(err)
      })
    setState({
      ...state,
      alternatePhoneError:
        alternatePhoneError || leadPhoneNumber === alternatePhone,
      alternatePhoneHelperText: alternatePhoneError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_ALTERNATE_PHONE
        : leadPhoneNumber === alternatePhone
        ? StringConstants.UI_ERROR_MSG_FOR_PREFERRED_PHONE_SAME_AS_PHONE
        : '',
      ...conditionalStateData,
    })
  }

  const handlePreferredPhoneChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let alternatePhone = e.target.value.replace(/[- )( _]/g, '')
    validatePreferredPhone(alternatePhone)
    setPreferredPhoneValue(alternatePhone)
    setPreferredPhoneNumber && setPreferredPhoneNumber(alternatePhone)
  }

  const handlePreferenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasPreferredNumber(e.target.value)
    e.target.value === 'Yes'
      ? setIsPreferredNumber(false)
      : e.target.value === 'No'
      ? setIsPreferredNumber(true)
      : setIsPreferredNumber(false)
  }

  return (
    <Container>
      <h2>Let's get you started!</h2>
      <p>
        We want to make sure we contact you at the right number. Is your
        existing phone number the preferred one for us to call?
      </p>
      <p className='highlight'>{formattedLeadPhoneNumber}</p>
      <RadioGroup
        radioButtons={['Yes', 'No']}
        row={true}
        value={hasPreferredNumber}
        radioGroupName='Gender'
        onChange={handlePreferenceChange}
        extraPaddingBottom='5px'
        disabledValues={buttonLoading ? ['Yes', 'No'] : []}
      />
      {isPreferredNumber && (
        <InputField
          error={state.alternatePhoneError}
          helperText={state.alternatePhoneHelperText}
          label='Enter your Preferred Phone Number'
          width='100%'
          value={preferredPhoneValue}
          onChange={handlePreferredPhoneChange}
          inputComponent={MaskedInput}
          inputProps={{ mask: '(111) 111-1111' }}
          shrink={preferredPhoneNumber ? true : undefined}
          placeholder='(123) 456-7890'
          disabled={buttonLoading}
        />
      )}
      <p>
        Hit "Continue" to confirm your interest in{' '}
        {planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
          ? 'applying'
          : 'enrolling'}
        {' for '}
        <div>
          <b>{planName}!</b>
        </div>
      </p>
      <div className='buttons'>
        <Button
          variant='contained'
          bold={false}
          color='secondary'
          onClick={(e) => {
            fireEvent(
              planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                ? 'confirmApply'
                : 'confirmEnroll',
              e,
              {
                description: `Confirmed ${
                  planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                    ? 'Application'
                    : 'Enrollment'
                } in ${planName}`,
                planType: planType,
                planName: planName,
                planId: planId,
              }
            )
            onEnroll()
          }}
          loading={buttonLoading}
          disabled={
            buttonLoading ||
            hasPreferredNumber === '' ||
            (hasPreferredNumber === 'No' &&
              (preferredPhoneNumber === '' ||
                state.alternatePhoneError ||
                preferredPhoneValue === ''))
          }
          fontSize='14px'
          description={`Requested ${
            planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
              ? 'Application'
              : 'Enrollment'
          } in ${planName}`}
          id={`confirmEnroll ${planName}`}
        >
          Continue
        </Button>

        <Button
          color='info'
          bold={false}
          onClick={(e) => {
            fireEvent(
              planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                ? 'rejectApply'
                : 'rejectEnroll',
              e,
              {
                description: `Rejected ${
                  planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                    ? 'Application'
                    : 'Enrollment'
                } in ${planName}`,
                planType: planType,
                planName: planName,
                planId: planId,
              }
            )
            setOpen(false)
          }}
          disabled={buttonLoading}
          fontSize='14px'
          description={`Cancelled ${
            planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
              ? 'Application'
              : 'Enrollment'
          } in ${planName}`}
        >
          Cancel
        </Button>
      </div>
    </Container>
  )
}

export default EnrollmentRequestConfirmation
