import { MNonSupplementPlan } from '../../../../interface/quotes/mNonSupplementPlans/MNonSupplementPlan'
import MedicarePrescriptionCardComponent from './MedicarePrescriptionCard.component'
import React from 'react'
import styled from '@emotion/styled'

interface RecommendedPrescriptionCardsProps {
  recommendedPlan: MNonSupplementPlan
  goodChoicePlan: MNonSupplementPlan | undefined
  onEnroll: () => void
  handleRemoveFavorite?: () => void
  showCompareCheckbox?: boolean
  userOption: boolean
  handleSpouseRemove: () => void
  setShowAddSpouseConfirmation: (flag: boolean) => void
  setCompletePlanDetailModalActive: (flag: boolean) => void
  setCompleteDetailPlanId: (planId: string) => void
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media screen and (max-width: 1200px) {
    display: block;
  }
`

const RecommendedPrescriptionCards: React.FC<
  RecommendedPrescriptionCardsProps
> = (props) => {
  return (
    <Container>
      <div>
        <MedicarePrescriptionCardComponent
          userOption={props.userOption}
          medicarePartDPlan={props.recommendedPlan}
          onEnroll={props.onEnroll}
          handleSpouseRemove={props.handleSpouseRemove}
          setShowAddSpouseConfirmation={props.setShowAddSpouseConfirmation}
          setCompleteDetailPlanId={props.setCompleteDetailPlanId}
          setCompletePlanDetailModalActive={
            props.setCompletePlanDetailModalActive
          }
          isRecommended
        />
      </div>
      {props.goodChoicePlan && (
        <div>
          <MedicarePrescriptionCardComponent
            userOption={props.userOption}
            medicarePartDPlan={props.goodChoicePlan}
            onEnroll={props.onEnroll}
            handleSpouseRemove={props.handleSpouseRemove}
            setShowAddSpouseConfirmation={props.setShowAddSpouseConfirmation}
            setCompleteDetailPlanId={props.setCompleteDetailPlanId}
            setCompletePlanDetailModalActive={
              props.setCompletePlanDetailModalActive
            }
            isGoodChoice
          />
        </div>
      )}
    </Container>
  )
}

export default RecommendedPrescriptionCards
