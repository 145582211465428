import MuiButton from '@mui/material/Button'
import React from 'react'
import SyncLoader from 'react-spinners/SyncLoader'
import { fireEvent } from '../../../cra'
import theme from '../../../global/theme'

interface ButtonProps {
  variant?: 'text' | 'outlined' | 'contained' | undefined
  textTransform?: 'capitalize' | 'uppercase' | 'lowercase' | undefined
  fullWidth?: boolean
  color?:
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'contrastPrimary'
    | undefined
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  children?: JSX.Element | string | undefined
  bold?: boolean
  type?: 'button' | 'reset' | 'submit' | undefined
  disabled?: boolean
  width?: string
  fontSize?: string
  height?: string
  borderRadius?: string
  justifyContent?: 'flex-start' | 'flex-end' | 'center'
  margin?: string
  textDecoration?: string
  value?: string
  href?: string
  endIcon?: JSX.Element
  loading?: boolean
  additionalPadding?: boolean
  description?: string
  id?: string
  handleHover?: React.MouseEventHandler<HTMLButtonElement> | undefined
  handleLeave?: React.MouseEventHandler<HTMLButtonElement> | undefined
  minWidth?: string
  isBottomStepNavigator?: boolean
  lineHeight?: string
  flexBasis?: string
}

const Button: React.FC<ButtonProps> = ({
  variant = 'outlined',
  fullWidth = false,
  color = 'primary',
  onClick,
  children,
  bold = true,
  type = 'button',
  textDecoration = 'none',
  disabled = false,
  width = '200px',
  textTransform = 'capitalize',
  fontSize = '14px',
  height = '40px',
  borderRadius = '20px',
  justifyContent = 'center',
  margin = '5px 0px',
  value,
  href,
  endIcon,
  loading = false,
  additionalPadding = false,
  description,
  id,
  handleHover,
  handleLeave,
  minWidth = '64px',
  isBottomStepNavigator = false,
  lineHeight,
  flexBasis = '',
}) => {
  return (
    <MuiButton
      variant={variant}
      disableElevation
      color={color}
      fullWidth={fullWidth}
      onClick={(e) => {
        fireEvent('click', e, {
          description: description || null,
        })
        onClick && onClick(e)
      }}
      id={id}
      type={type}
      disabled={disabled}
      value={value}
      href={href}
      endIcon={endIcon}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      sx={{
        fontWeight: bold ? '900' : '600',
        width: width,
        flexBasis: flexBasis,
        borderRadius: borderRadius,
        textTransform: textTransform,
        fontSize: fontSize,
        margin: margin,
        height: height,
        ...(isBottomStepNavigator && {
          '@media screen and (max-width: 850px)': {
            minWidth: minWidth,
            width: '35px',
            borderRadius: '50%',
          },
          lineHeight: lineHeight,
        }),
      }}
      style={{
        justifyContent: justifyContent,
        paddingTop: additionalPadding ? '22px' : '0px',
        paddingBottom: additionalPadding ? '22px' : '0px',
        fontSize: additionalPadding ? '12.5px' : fontSize,
        color: color,
        textDecoration: textDecoration,
      }}
    >
      {loading ? (
        <SyncLoader loading={true} color={theme.colors.white} size={8} />
      ) : (
        children
      )}
    </MuiButton>
  )
}

export default Button
