import { LuUser, LuUsers } from 'react-icons/lu'

import React from 'react'
import medicareAdvantageFilterQuoteStore from '../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

const Container = styled.div`
  display: flex;
  .wrapper {
    width: 100%;
    border-radius: 36px;
    border: 1px solid ${theme.colors.primary};
    display: flex;
    overflow: hidden;

    .button {
      padding: 5px 15px;
      white-space: nowrap;
      flex: 1;
      display: flex;
      align-items: center;
      gap: 5px;
      color: ${theme.colors.primary};
      font-weight: 600;
      cursor: pointer;
      transition: background-color 0.4s;
    }

    .selected {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.textLight};
      border-right: 1px solid ${theme.colors.primary};
      transition: background-color 0.2s;
    }
  }
`

interface PrimarySpouseToggleProps {
  handleUserChange: (value: boolean) => void
  forSpouse: boolean
}

const PrimarySpouseToggle: React.FC<PrimarySpouseToggleProps> = ({
  handleUserChange,
  forSpouse,
}) => {
  return (
    <Container>
      <div className='wrapper'>
        <div
          className={`button ${!forSpouse && 'selected'}`}
          onClick={() => {
            handleUserChange(false)
          }}
        >
          <LuUser />
          Primary
        </div>
        <div
          className={`button ${forSpouse && 'selected'}`}
          onClick={() => {
            handleUserChange(true)
          }}
        >
          <LuUsers />
          Spouse & Primary
        </div>
      </div>
    </Container>
  )
}

export default observer(PrimarySpouseToggle)
