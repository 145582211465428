import {
  getCustomerPreference,
  updateCustomerPreference,
} from '../../../service/customer'

import Button from '../../common/Button/Button.component'
import { CheckBoxType } from '../../../interface/common/CheckBoxType'
import CheckboxGroup from '../../common/CheckboxGroup/CheckboxGroup.component'
import { Divider } from '@mui/material'
import InputField from '../../common/InputField/InputField.component'
import React from 'react'
import RouteConstants from '../../../constants/RouteConstants'
import SectionRow from '../../common/SectionRow'
import SecureComponent from '../../../pages/common/SecureComponent'
import SkeletonCard from '../../DashboardPage/SkeletonCard'
import StringConstants from '../../../constants/StringConstants'
import customerStore from '../../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../../utils/StringUtils'
import snackbarStore from '../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../global/theme'
import { useNavigate } from 'react-router-dom'

const Container = styled.div`
  width: 100%;
  padding: 20px 0px;
`

const Label = styled.h4`
  font-size: 14px;
  padding: 20px 0px 5px 0px;
  font-weight: 600;
`
const SubLabel = styled.h5`
  font-size: 12px;
  color: ${theme.colors.textGrey};
  font-weight: 400;
  line-height: 20px;
`

export interface Preference {
  ratesToBeMailed: boolean
  weeklyNewsletter: boolean
  smsNotificationAboutLatestPlans: boolean
  timeZone: string
  phoneCall: boolean
  textMessage: boolean
}

interface ProfileProps {
  isSpouse: boolean
}

const Preferences: React.FC<ProfileProps> = (props) => {
  const navigate = useNavigate()
  const [ratesToBeMailed, setRatesToBeMailed] = React.useState(false)
  const [weeklyNewsLetter, setWeeklyNewsLetter] = React.useState(false)
  const [smsPreference, setSmsPreference] = React.useState(false)
  const [textMessage, setTextMessage] = React.useState(false)
  const [phoneCall, setPhoneCall] = React.useState(false)
  const [checkboxes, setCheckBoxes] = React.useState<CheckBoxType[]>([])

  const [state, setState] = React.useState({
    loading: true,
    buttonLoading: false,
  })

  React.useEffect(() => {
    getCustomerPreference(props.isSpouse)
      .then((res) => {
        const preference: Preference = res
        setRatesToBeMailed(preference.ratesToBeMailed)
        setWeeklyNewsLetter(preference.weeklyNewsletter)
        setSmsPreference(preference.smsNotificationAboutLatestPlans)
        setPhoneCall(preference.phoneCall)
        setTextMessage(preference.textMessage)
        setCheckBoxes([
          {
            id: Math.random(),
            checked: preference.phoneCall,
            label: 'Text Message',
            value: 'Text Message',
          },
          {
            id: Math.random(),
            checked: preference.textMessage,
            label: 'Phone call',
            value: 'Phone call',
          },
        ])
        setState({
          ...state,
          loading: false,
        })
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('fetch preference'),
          snackbarOpen: true,
          snackbarType: 'error',
        })
      })
  }, [])

  const handleSaveChanges = () => {
    setState({
      ...state,
      buttonLoading: true,
    })
    updateCustomerPreference({ phoneCall, textMessage }, props.isSpouse)
      .then((res) => {
        snackbarStore.set({
          snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
          snackbarOpen: true,
          snackbarType: 'success',
        })
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('update preference'),
          snackbarOpen: true,
          snackbarType: 'error',
        })
      })
      .finally(() => {
        setState({
          ...state,
          buttonLoading: false,
        })
      })
  }
  return state.loading ? (
    <SectionRow>
      <SkeletonCard quantity={10} />
    </SectionRow>
  ) : (
    <Container>
      <SectionRow row>
        <InputField
          width='100%'
          label='Username'
          placeholder='Enter your email address'
          value={customerStore.username}
          disabled={true}
        />
      </SectionRow>
      <Divider />
      {!props.isSpouse && (
        <>
          <div>
            <Label style={{ fontWeight: 600 }}>Change Password</Label>
            <SubLabel>
              It's a good idea to change your password every 60 - 90 days
            </SubLabel>
          </div>
          <SectionRow row>
            <Button
              onClick={() => navigate(RouteConstants.RESET_PASSWORD)}
              width='100%'
            >
              Change Password
            </Button>
          </SectionRow>
        </>
      )}
      <Divider sx={{ marginBottom: '20px' }} />
      <SectionRow>
        <CheckboxGroup
          label='Notification Preference'
          subLabel='Choose your notification Preferences'
          bottomPadding={false}
          optionsGap='0px'
          checkboxState={checkboxes}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            console.log('Selected value:', e.target.value)

            const updatedCheckboxes = checkboxes.map((checkbox) => {
              if (checkbox.label === e.target.value) {
                if (e.target.value === 'Text Message') {
                  setTextMessage(!checkbox.checked)
                } else if (e.target.value === 'Phone call') {
                  setPhoneCall(!checkbox.checked)
                }
                checkbox.checked = !checkbox.checked
              }
              return checkbox
            })

            setCheckBoxes(updatedCheckboxes)
          }}
          width='100%'
          type='unstyled'
          checkboxGroupName='Plan Type Filter - Medicare Supplement'
        />
      </SectionRow>
      <SectionRow row>
        <Button
          width='100%'
          color='secondary'
          variant='contained'
          onClick={handleSaveChanges}
          loading={state.buttonLoading}
        >
          Save Changes
        </Button>
      </SectionRow>
    </Container>
  )
}

export default SecureComponent(Preferences)
