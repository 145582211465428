import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`

const CustomSkeleton = styled(Skeleton)`
  border-radius: 8px;
`

const SkeletonCard: React.FC<{
  height?: number
  quantity?: number
}> = ({ height = 40, quantity = 5 }) => {
  return (
    <Container>
      {Array.from({ length: quantity }, (_, index) => (
        <CustomSkeleton
          key={index}
          variant='rectangular'
          width='100%'
          height={height}
          sx={{ marginBottom: '20px' }}
        />
      ))}
    </Container>
  )
}

export default SkeletonCard
