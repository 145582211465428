import RadioQuestion from '../Common/RadioQuestion'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface QuestionnaireRadioStepProps {
  id: string
  value: string
  question: string
  description: string
  handleResponseSelection: (id: any, responseArray: any[]) => void
  options: { option: string }[]
}

const QuestionWrapper = styled.div`
  text-align: start;
  padding: 20px 0px;
  h3 {
    padding-bottom: 5px;
    font-weight: 700;
    font-size: 18px;
    color: ${theme.colors.primary};
  }
  p {
    font-size: 14px;
    color: ${theme.colors.textGrey};
  }
`

const QuestionnaireRadioStep: React.FC<QuestionnaireRadioStepProps> = ({
  id,
  value,
  question,
  description,
  options,
  handleResponseSelection,
}) => {
  return (
    <div>
      <QuestionWrapper>
        <h3>{question}</h3>
        <p>{description}</p>
      </QuestionWrapper>
      <RadioQuestion
        radioButtonType='squared'
        handleResponseSelection={handleResponseSelection}
        id={id}
        options={options}
        value={value}
        radioGroupName={question || Math.random().toString()}
      />
    </div>
  )
}

export default observer(QuestionnaireRadioStep)
