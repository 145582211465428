import React from 'react'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'

interface InfoStepsStepperProps {
  stepDetails: {
    order: number
    label: string
    route: string
  }[]
}

const StepperLabelStyled = styled.span`
  @media screen and (max-width: 470px) {
    display: none;
  }
`

const InfoStepsStepper: React.FC<InfoStepsStepperProps> = ({ stepDetails }) => {
  const location = useLocation().pathname
  const getActiveStep = () => {
    const currentStep = stepDetails.find((iterator) => {
      return iterator.route.split('/')[2] === location.split('/')[2]
    })
    console.log(currentStep)
    return currentStep
  }

  return (
    <>
      <Stepper
        activeStep={getActiveStep()?.order || 0}
        sx={{ width: '100%' }}
        alternativeLabel={window.innerWidth < 600}
        orientation='vertical'
      >
        {stepDetails.map((iterator) => {
          return (
            <Step key={iterator.order}>
              <StepLabel style={{ width: 'max-content' }}>
                <StepperLabelStyled>{iterator.label}</StepperLabelStyled>
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </>
  )
}

export default InfoStepsStepper
