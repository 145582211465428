/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ActionContext } from '../../cra'
import RouteConstants from '../../constants/RouteConstants'
import customerStore from '../../datastore/CustomerStore'
import { isAuthenticatedUser } from '../../utils/AuthUtils'
import { isEmpty } from 'lodash'

export default function (Page: React.ComponentType<any>) {
  const SecureComponent = (props: any) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const { trackCurrentPage } = React.useContext(ActionContext)

    useEffect(() => {
      const isUserLoggedIn: boolean = isAuthenticatedUser()
      setIsLoggedIn(isUserLoggedIn)
      if (isEmpty(customerStore.id)) {
        let route = RouteConstants.REDIRECT
        if (location && location.pathname) {
          const searchParams = location.search
            ? '&' + location.search.slice(1)
            : ''
          route += '?redirect=' + location.pathname + searchParams
        }
        navigate(route)
      }
    }, [])

    React.useEffect(() => {
      trackCurrentPage('Quoting Tool')
    }, [])

    return (
      <>{isLoggedIn ? <Page {...props} /> : navigate(RouteConstants.LOGIN)}</>
    )
  }

  return SecureComponent
}
