import { SyncLoader } from 'react-spinners'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
`

const BouncingDotsLoader = () => {
  return (
    <Container>
      <SyncLoader size='12px' color={theme.colors.primary} />
    </Container>
  )
}

export default BouncingDotsLoader
