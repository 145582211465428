import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { Radio, Rating } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { isEmpty, isEqual, isNull, isUndefined } from 'lodash'

import CheckboxGroup from '../../../common/CheckboxGroup/CheckboxGroup.component'
import CloseIconButton from '../../../common/Button/CloseIconButton.component'
import { MParDPlanFiltersRequest } from '../../../../interface/quotes/mNonSupplementPlans/medicarePartD/request/MPartDPlanFiltersRequest'
import { MPartDPlanDisplayFilter } from '../../../../interface/quotes/mNonSupplementPlans/medicarePartD/MPartDPlanDisplayFilters'
import ModalComponent from '../../../common/Modal/Modal.component'
import OptionalMessage from '../../Common/OptionalMessageFilter'
import PaginationConstants from '../../../../constants/PaginationConstants'
import PlanYearChangeConfirmationPopUp from '../../Modals/PlanYearChangeConfirmationPopUp'
import QuotesConstants from '../../../../constants/QuotesConstants'
import RouteConstants from '../../../../constants/RouteConstants'
import SliderComponent from '../../../common/Slider/Slider'
import StringConstants from '../../../../constants/StringConstants'
import Tooltip from '../../../common/Tooltip/Tooltip.component'
import { convertToUSCurrency } from '../../../../utils/CurrencyConverterUtil'
import customerStore from '../../../../datastore/CustomerStore'
import { fireEvent } from '../../../../cra'
import mPartDPlansFilterStore from '../../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface FilterPrescriptionProps {
  showFilter: boolean
  handleShowFilter: () => void
  filterRef: React.MutableRefObject<any>
  clearFilterState: boolean
  clearButtonState: () => void
  setSelectedFilters?: (data: string[]) => void
  mPartDPlansFilters: MPartDPlanDisplayFilter
  setMPartDPlansFilters: (data: MPartDPlanDisplayFilter) => void
  mPartDPlansRequestBody: MParDPlanFiltersRequest
  handleRequestBodyChange: (
    fieldsChanged: MParDPlanFiltersRequest,
    forSpouse: boolean
  ) => void
  setPlanLoader: (data: boolean) => void
}

interface FilterContainerProps {
  showFilter?: boolean
  greyOut?: boolean
}

const FilterWrapper = styled.div`
  min-width: 350px;
  @media screen and (max-width: 950px) {
    min-width: 0px;
    width: 0px;
  }
`

const Container = styled.div<FilterContainerProps>`
  background-color: ${theme.colors.white};
  border-right: 1px solid ${theme.colors.borderColor};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  z-index: 500;
  position: fixed;
  top: 0px;
  left: 0;
  height: 100%;
  width: 350px;
  padding: 100px 40px;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (max-width: 950px) {
    max-height: 100vh;
    width: 320px;
    padding: 20px;
    z-index: 1001;
    display: ${({ showFilter }) => (showFilter ? 'block' : 'none')};
  }
  pointer-events: ${({ greyOut }) => (greyOut ? 'none' : 'auto')};
`

const Filter = styled.div`
  padding: 20px 0px;
`

const Label = styled.p`
  color: ${theme.colors.primary};
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 8px;
`
const CloseButtonWrapper = styled.div`
  display: none;
  position: absolute;
  top: 10px;
  right: 0px;
  @media screen and (max-width: 950px) {
    display: block;
  }
`

const SliderContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Overlay = styled.div<FilterContainerProps>`
  @media screen and (max-width: 950px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: ${({ showFilter }) => (showFilter ? 'block' : 'none')};
    transition: background-color 0.3s ease;
  }
`

const FilterPrescription: React.FC<FilterPrescriptionProps> = ({
  showFilter,
  handleShowFilter,
  filterRef,
  clearFilterState,
  clearButtonState,
  setSelectedFilters,
  mPartDPlansFilters,
  setMPartDPlansFilters,
  handleRequestBodyChange,
  mPartDPlansRequestBody,
  setPlanLoader,
}) => {
  const [
    openPlanYearFilterConfirmationPopup,
    setOpenPlanYearFilterConfirmationPopup,
  ] = useState<boolean>(false)
  const [tempPlanYear, setTempPlanYear] = useState('')

  const [monthlyPremiumSliderValue, setMonthlyPremiumSliderValue] =
    useState<number>(mPartDPlansFilters?.monthlyPremium?.currentValue)
  const [drugSliderValue, setDrugSliderValue] = useState<number>(
    mPartDPlansFilters?.deductible?.currentValue
  )

  const [carrierDropdown, setCarrierDropdown] = useState(false)
  const [drugsDropdown, setDrugsDropdown] = useState(false)
  const [providersDropDown, setProvidersDropDown] = useState(false)

  useEffect(() => {
    console.log('mPartDPlansFilters', mPartDPlansFilters)
  }, [mPartDPlansFilters])

  const handleDrugsCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (mPartDPlansFilters) {
      const checkedDrugs = mPartDPlansFilters.drugCheckBoxes?.map((item) =>
        item.value === e.target.value
          ? { ...item, checked: !item.checked }
          : item
      )
      setMPartDPlansFilters({
        ...mPartDPlansFilters,
        drugCheckBoxes: checkedDrugs,
      })

      handleRequestBodyChange(
        {
          prescriptions: checkedDrugs?.reduce((acc, item) => {
            if (item.checked) {
              acc.push(item.optionalValue!)
            }
            return acc
          }, [] as string[]),
          paginationDetail: {
            page: PaginationConstants.DEFAULT_PAGE,
            size: PaginationConstants.DEFAULT_PAGE_SIZE,
          },
        },
        mPartDPlansFilterStore.getIsPrimaryAndSpouseOption()
      )

      if (mPartDPlansFilterStore.isPrimaryAndSpouseOption) {
        mPartDPlansFilterStore.spouseMPartDPlanFilters.drugCheckBoxes =
          checkedDrugs
      } else {
        mPartDPlansFilterStore.mPartDPlanFilters.drugCheckBoxes = checkedDrugs
      }

      setPlanLoader(true)
    }
  }

  const handleProvidersCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (mPartDPlansFilters) {
      const checkedProviders = mPartDPlansFilters.providers?.map((item) =>
        item.value.toString() === e.target.value.toString()
          ? { ...item, checked: !item.checked }
          : item
      )
      setMPartDPlansFilters({
        ...mPartDPlansFilters,
        providers: checkedProviders,
      })

      handleRequestBodyChange(
        {
          providers: checkedProviders?.reduce((acc, item) => {
            if (item.checked) {
              acc.push(item.optionalValue!)
            }
            return acc
          }, [] as string[]),
          paginationDetail: {
            page: PaginationConstants.DEFAULT_PAGE,
            size: PaginationConstants.DEFAULT_PAGE_SIZE,
          },
        },
        mPartDPlansFilterStore.getIsPrimaryAndSpouseOption()
      )

      if (mPartDPlansFilterStore.isPrimaryAndSpouseOption) {
        mPartDPlansFilterStore.spouseMPartDPlanFilters.providers =
          checkedProviders
      } else {
        mPartDPlansFilterStore.mPartDPlanFilters.providers = checkedProviders
      }

      setPlanLoader(true)
    }
  }

  const handleRatingChange = (value: number | null) => {
    if (mPartDPlansFilters) {
      if (
        value &&
        value >= mPartDPlansFilterStore.mPartDPlanFilters.minStarRating.minValue
      ) {
        const sliderValue = {
          ...mPartDPlansFilters.minStarRating,
          currentValue: value,
        }

        setMPartDPlansFilters({
          ...mPartDPlansFilters,
          minStarRating: sliderValue,
        })

        handleRequestBodyChange(
          {
            minStarRating: {
              start: sliderValue.minValue,
              end: sliderValue.currentValue,
            },
          },
          mPartDPlansFilterStore.isPrimaryAndSpouseOption
        )

        if (mPartDPlansFilterStore.isPrimaryAndSpouseOption) {
          mPartDPlansFilterStore.spouseMPartDPlanFilters.minStarRating =
            sliderValue
        } else {
          mPartDPlansFilterStore.mPartDPlanFilters.minStarRating = sliderValue
        }

        setPlanLoader(true)
      }
    }
  }

  const togglePlanYearFilterPopupOpen = () => {
    setOpenPlanYearFilterConfirmationPopup(!openPlanYearFilterConfirmationPopup)
  }

  const handleOnConfirmPlanYearChange = () => {
    setMPartDPlansFilters({
      ...mPartDPlansFilters!,
      currentPlanYear: tempPlanYear,
    })
    handleRequestBodyChange(
      {
        planYear: tempPlanYear,
      },
      mPartDPlansFilterStore.isPrimaryAndSpouseOption
    )
    setPlanLoader(true)

    if (mPartDPlansFilterStore.isPrimaryAndSpouseOption) {
      mPartDPlansFilterStore.spouseMPartDPlanFilters.currentPlanYear =
        tempPlanYear
    } else {
      mPartDPlansFilterStore.mPartDPlanFilters.currentPlanYear = tempPlanYear
    }
    setPlanLoader(true)

    customerStore.setMPartDFilterYear(tempPlanYear)
    customerStore.setMPDPageSize(0)
    togglePlanYearFilterPopupOpen()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleOnRejectPlanYearChange = () => {
    setTempPlanYear('')
    togglePlanYearFilterPopupOpen()
  }

  const handlePlanYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (mPartDPlansFilters) {
      const year = e.target.value
      if (e.target.checked) {
        setTempPlanYear(year.toString())
        togglePlanYearFilterPopupOpen()
      }
    }
  }

  const handleMaxMonthlyPremium = (e: any) => {
    if (mPartDPlansFilters) {
      const mpValue = {
        ...mPartDPlansFilters.monthlyPremium,
        currentValue: monthlyPremiumSliderValue,
      }

      setMPartDPlansFilters({
        ...mPartDPlansFilters,
        monthlyPremium: mpValue,
      })

      handleRequestBodyChange(
        {
          monthlyPremium: {
            start: mpValue.minValue,
            end: mpValue.currentValue,
          },
        },
        mPartDPlansFilterStore.isPrimaryAndSpouseOption
      )
      setPlanLoader(true)

      if (mPartDPlansFilterStore.isPrimaryAndSpouseOption) {
        mPartDPlansFilterStore.spouseMPartDPlanFilters.monthlyPremium = mpValue
      } else {
        mPartDPlansFilterStore.mPartDPlanFilters.monthlyPremium = mpValue
      }
    }
  }

  const handleMaxDrugDeductible = (e: any) => {
    if (mPartDPlansFilters) {
      const drugDeductibleValue = {
        ...mPartDPlansFilters.deductible,
        currentValue: drugSliderValue,
      }

      setMPartDPlansFilters({
        ...mPartDPlansFilters,
        deductible: drugDeductibleValue,
      })

      handleRequestBodyChange(
        {
          monthlyPremium: {
            start: drugDeductibleValue.minValue,
            end: drugDeductibleValue.currentValue,
          },
        },
        mPartDPlansFilterStore.isPrimaryAndSpouseOption
      )
      setPlanLoader(true)

      if (mPartDPlansFilterStore.isPrimaryAndSpouseOption) {
        mPartDPlansFilterStore.spouseMPartDPlanFilters.deductible =
          drugDeductibleValue
      } else {
        mPartDPlansFilterStore.mPartDPlanFilters.monthlyPremium =
          drugDeductibleValue
      }
    }
  }

  const handleCarrierCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (mPartDPlansFilters) {
      const checkedCarriers = mPartDPlansFilters.carriers?.map((item) =>
        item.value === e.target.value
          ? { ...item, checked: !item.checked }
          : item
      )
      setMPartDPlansFilters({
        ...mPartDPlansFilters,
        carriers: checkedCarriers,
      })

      handleRequestBodyChange(
        {
          carriers: checkedCarriers?.reduce((acc, item) => {
            if (item.checked) {
              acc.push(item.id)
            }
            return acc
          }, [] as number[]),
          paginationDetail: {
            page: PaginationConstants.DEFAULT_PAGE,
            size: PaginationConstants.DEFAULT_PAGE_SIZE,
          },
        },
        mPartDPlansFilterStore.getIsPrimaryAndSpouseOption()
      )

      if (mPartDPlansFilterStore.isPrimaryAndSpouseOption) {
        mPartDPlansFilterStore.spouseMPartDPlanFilters.carriers =
          checkedCarriers
      } else {
        mPartDPlansFilterStore.mPartDPlanFilters.carriers = checkedCarriers
      }

      setPlanLoader(true)
    }
  }

  const handleDropdownClick = (id: string) => {
    switch (id) {
      case 'carrier':
        setCarrierDropdown(!carrierDropdown)
        setDrugsDropdown(false)
        setProvidersDropDown(false)
        break
      case 'drugs':
        setDrugsDropdown(!drugsDropdown)
        setCarrierDropdown(false)
        setProvidersDropDown(false)
        break
      case 'providers':
        setProvidersDropDown(!providersDropDown)
        setDrugsDropdown(false)
        setCarrierDropdown(false)
        break
    }
  }

  return (
    <FilterWrapper ref={filterRef}>
      {openPlanYearFilterConfirmationPopup && (
        <ModalComponent
          setOpen={handleOnRejectPlanYearChange}
          hideCloseButton={true}
        >
          <PlanYearChangeConfirmationPopUp
            onConfirm={handleOnConfirmPlanYearChange}
            onReject={handleOnRejectPlanYearChange}
            year={tempPlanYear}
          />
        </ModalComponent>
      )}
      <Overlay showFilter={showFilter} />
      <Container
        greyOut={mPartDPlansFilterStore.filterApplied}
        showFilter={showFilter}
      >
        <CloseButtonWrapper>
          <CloseIconButton
            fontSize='32px'
            onClick={() => {
              handleShowFilter()
            }}
          />
        </CloseButtonWrapper>
        {!isEmpty(mPartDPlansFilters) ? (
          <div>
            <OptionalMessage
              heading='Showing plans for'
              link={RouteConstants.PROFILE + '/myprofile/contact'}
              linkText={customerStore.getPostalCodeAndCounty()}
              showToolTip={true}
            />
            <div className='filters-wrapper'>
              {mPartDPlansFilters.planYear && (
                <Filter
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Label>Plan Year</Label>
                  {mPartDPlansFilters.planYear.planYears.map((planYear) => (
                    <>
                      <Radio
                        value={planYear}
                        onChange={handlePlanYear}
                        size='small'
                        checked={
                          customerStore.mPartDFilterYear === ''
                            ? mPartDPlansFilters.currentPlanYear.toString() ===
                              planYear.toString()
                            : customerStore.mPartDFilterYear ===
                              planYear.toString()
                        }
                      />
                      {planYear}
                    </>
                  ))}
                </Filter>
              )}
              {mPartDPlansFilters.minStarRating && (
                <Filter
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Label>
                    Star Rating
                    <Tooltip
                      title={StringConstants.TOOLTIP_MSG_MPARTD_STAR_RATING}
                    />
                  </Label>
                  <Rating
                    name='simple-controlled'
                    value={mPartDPlansFilters.minStarRating.currentValue}
                    precision={0.5}
                    onChange={(event, newValue) => {
                      fireEvent('starRatingInput', event, {
                        value: newValue,
                        label: 'Star Rating',
                        description:
                          'Star Rating Filter - Medicare Prescription Drugs',
                      })
                      handleRatingChange(newValue)
                    }}
                  />
                </Filter>
              )}

              {mPartDPlansFilters.carriers && (
                <Filter>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleDropdownClick('carrier')
                    }}
                  >
                    <Label>
                      Carrier
                      <Tooltip
                        title={StringConstants.TOOLTIP_MSG_MPARTD_CARRIERS}
                      />
                    </Label>

                    {carrierDropdown ? (
                      <BsChevronUp
                        style={{ cursor: 'pointer', marginLeft: 'auto' }}
                      />
                    ) : (
                      <BsChevronDown
                        style={{ cursor: 'pointer', marginLeft: 'auto' }}
                      />
                    )}
                  </div>
                  {carrierDropdown ? (
                    <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                      <CheckboxGroup
                        checkboxState={mPartDPlansFilters.carriers}
                        onChange={handleCarrierCheckboxChange}
                        width='100%'
                        type='unstyled'
                        checkboxGroupName='Carrier Filters - Medicare Prescription Drugs'
                      />
                    </div>
                  ) : null}
                </Filter>
              )}
              {mPartDPlansFilters.drugCheckBoxes &&
                mPartDPlansFilters.drugCheckBoxes.length > 0 && (
                  <Filter>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleDropdownClick('drugs')
                      }}
                    >
                      <Label>
                        Drugs Covered
                        <Tooltip
                          title={
                            StringConstants.TOOLTIP_MSG_MPARTD_DRUGS_COVERED
                          }
                        />
                      </Label>
                      {drugsDropdown ? (
                        <BsChevronUp
                          style={{ cursor: 'pointer', marginLeft: 'auto' }}
                        />
                      ) : (
                        <BsChevronDown
                          style={{ cursor: 'pointer', marginLeft: 'auto' }}
                        />
                      )}
                    </div>
                    {drugsDropdown ? (
                      <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                        <CheckboxGroup
                          checkboxState={mPartDPlansFilters.drugCheckBoxes}
                          onChange={handleDrugsCheckbox}
                          width='100%'
                          type='unstyled'
                          checkboxGroupName='Drugs Covered Filter - Medicare Prescription Drugs'
                        />
                      </div>
                    ) : null}
                  </Filter>
                )}
              {mPartDPlansFilters.providers?.length > 0 && (
                <Filter key={'Providers_Coverage'}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleDropdownClick('providers')
                    }}
                  >
                    <Label>
                      Physician Covered
                      <Tooltip
                        title={StringConstants.TOOLTIP_MSG_MA_Physician_COVERED}
                      />
                    </Label>
                    {providersDropDown ? (
                      <BsChevronUp
                        style={{ cursor: 'pointer', marginLeft: 'auto' }}
                      />
                    ) : (
                      <BsChevronDown
                        style={{ cursor: 'pointer', marginLeft: 'auto' }}
                      />
                    )}
                  </div>
                  {providersDropDown ? (
                    <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                      <CheckboxGroup
                        checkboxState={mPartDPlansFilters.providers}
                        onChange={handleProvidersCheckbox}
                        width='100%'
                        type='unstyled'
                        checkboxGroupName='Drugs Covered Filter - Medicare Advantage'
                      />
                    </div>
                  ) : null}
                </Filter>
              )}
              {mPartDPlansFilters.monthlyPremium && (
                <Filter>
                  <SliderComponent
                    label='Maximum Monthly Premium'
                    minValue={mPartDPlansFilters.monthlyPremium.minValue}
                    maxValue={mPartDPlansFilters.monthlyPremium.maxValue}
                    step={mPartDPlansFilters.monthlyPremium.stepValue}
                    size='small'
                    value={monthlyPremiumSliderValue}
                    onChange={(e: any) => {
                      setMonthlyPremiumSliderValue(e.target.value)
                    }}
                    onChangeCommitted={(e: any) => {
                      handleMaxMonthlyPremium(e)
                      const label = 'Maximum Monthly Premium'
                      const value = monthlyPremiumSliderValue
                      fireEvent('sliderInput', e, {
                        label,
                        value,
                        description: `Value of ${label} set to ${value}`,
                      })
                    }}
                    withTooltip={true}
                    tooltipContent={
                      StringConstants.TOOLTIP_MSG_MAXIMUM_MONTHLY_PREMIUM
                    }
                  />
                  <SliderContentContainer>
                    <div>
                      {convertToUSCurrency(
                        mPartDPlansFilters.monthlyPremium.minValue
                      )}
                    </div>
                    <div>
                      {convertToUSCurrency(
                        mPartDPlansFilters.monthlyPremium.maxValue
                      )}
                    </div>
                  </SliderContentContainer>
                </Filter>
              )}
              {!isUndefined(mPartDPlansFilters.deductible) && (
                <Filter>
                  <SliderComponent
                    label='Deductible'
                    minValue={mPartDPlansFilters.deductible.minValue}
                    maxValue={mPartDPlansFilters.deductible.maxValue}
                    step={mPartDPlansFilters.deductible.stepValue}
                    size='small'
                    value={drugSliderValue}
                    onChange={(e: any) => {
                      setDrugSliderValue(e.target.value)
                    }}
                    onChangeCommitted={(e: any) => {
                      handleMaxDrugDeductible(e)
                      const label = 'Deductible'
                      const value = drugSliderValue
                      fireEvent('sliderInput', e, {
                        label,
                        value,
                        description: `Value of ${label} set to ${value}`,
                      })
                    }}
                    withTooltip={true}
                    tooltipContent={
                      StringConstants.TOOLTIP_MSG_PDP_PLAN_DEDUCTIBLE
                    }
                  />
                  <SliderContentContainer>
                    <div>
                      {convertToUSCurrency(
                        mPartDPlansFilters.deductible.minValue
                      )}
                    </div>
                    <div>
                      {convertToUSCurrency(
                        mPartDPlansFilters.deductible.maxValue
                      )}
                    </div>
                  </SliderContentContainer>
                </Filter>
              )}
            </div>
          </div>
        ) : null}
      </Container>
    </FilterWrapper>
  )
}

export default observer(FilterPrescription)
