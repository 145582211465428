import { Link, useLocation, useNavigate } from 'react-router-dom'
import { fireEvent, getCrossTrackUrl } from '../../cra'
import { isEmpty, isNull } from 'lodash'

import ApiManager from '../../api/ApiManager'
import Button from '../common/Button/Button.component'
import Filter from 'bad-words'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import InputField from '../common/InputField/InputField.component'
import Loader from '../common/Loader'
import PasswordField from '../common/InputField/PasswordField.component'
import ReCAPTCHA from 'react-google-recaptcha'
import React from 'react'
import RouteConstants from '../../constants/RouteConstants'
import { SnackbarTypes } from '../../enums/SnackbarTypesEnum'
import StringConstants from '../../constants/StringConstants'
import UrlConstants from '../../constants/UrlConstants'
import ValidationUtils from '../../utils/validation/ValidationUtils'
import { addQueryParamsToUrl } from '../../api/UrlGenerator'
import customerStore from '../../datastore/CustomerStore'
import { getParamsFromUrl } from '../../utils/UrlUtils'
import loginPageBackground from '../../assets/background/loginPageBackground.jpg'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import userActionStore from '../../datastore/UserActionStore'

const LoaderWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 20px 100px;

  @media screen and (max-width: 850px) {
    background-position: 40% 20%;
    justify-content: center;
    padding: 10px 20px;
  }
`

const ContainerWrapper = styled.div`
  background-image: url(${loginPageBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 20px 100px;

  @media screen and (max-width: 850px) {
    background-position: 40% 20%;
    justify-content: center;
    padding: 10px 20px;
  }
`

const Container = styled.div`
  background-color: ${theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  // width: 500px;
  padding: 3rem 2.5rem;

  @media (max-width: 600px) {
    padding: 3rem 2rem;
  }
`

const Heading = styled.h1`
  color: ${theme.colors.primary};
  font-size: 1.75rem;
  font-weight: 800;

  @media (max-width: 600px) {
    font-size: 1.25rem;
  }
`

const SubHeading = styled.p`
  margin: 1rem 0rem;
  font-weight: 700;
  color: ${theme.colors.textDark};
`

const Form = styled.form`
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const ButtonContainer = styled.div`
  text-decoration: none;
  width: 100%;
`

const LinkContainer = styled(Link)`
  display: flex;
  text-decoration: underline;
  padding: 10px;
  font-weight: 600;
  font-size: 16px;
  color: ${theme.colors.primary};
`

const SignupWrapper = styled.p`
  margin: 2rem 0rem 0 10px;
  font-size: 16px;
  font-weight: 600;

  a {
    text-decoration: underline;
    font-weight: 700;
    color: ${theme.colors.primary};
    font-size: 16px;
    @media screen and (max-width: 600px) {
      display: block;
    }
  }

  @media (max-width: 600px) {
    margin: 1rem 0 0 10px;
  }
`

const CaptchaWrapper = styled.div`
  .grecaptcha-badge {
    visibility: hidden;
  }
`

const LoginForm: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const isMounted = React.useRef(true)
  const [state, setState] = React.useState({
    username: '',
    password: '',
    usernameError: false,
    passwordError: false,
    apiError: false,
    apiErrorText: '',
    loading: false,
  })

  const reCaptchaRef = React.useRef<ReCAPTCHA>(null)
  const filter = new Filter()

  const [isAttemptingAutoLogin, setIsAttemptingAutoLogin] =
    React.useState<boolean>(true)
  const [isSignedIn, setIsSignedIn] = React.useState<boolean>(false)
  const [leadBrand, setLeadBrand] = React.useState<string>('')
  const params = new URLSearchParams(window.location.search)

  React.useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )

  React.useEffect(() => {
    if (location.search) {
      const autoLoginUsername = getParamsFromUrl(location.search, 'username')
      const autoLoginPwd = getParamsFromUrl(location.search, 'token')

      if (
        !isNull(autoLoginUsername) &&
        isValidUsername(autoLoginUsername) &&
        !isNull(autoLoginPwd)
      ) {
        setIsAttemptingAutoLogin(true)
        userActionStore.setIsLoading(true)
        if (isAttemptingAutoLogin === true && !isSignedIn) {
          signIn(autoLoginUsername, 'passthrough', autoLoginPwd)
          setIsSignedIn(true)
        }
      } else {
        setIsAttemptingAutoLogin(false)
        userActionStore.setIsLoading(false)
      }
    } else {
      setIsAttemptingAutoLogin(false)
      userActionStore.setIsLoading(false)
    }
  }, [location])

  React.useEffect(() => {
    const leadBrand = params.has('leadBrand') ? params.get('leadBrand') : ''
    setLeadBrand(leadBrand as string)
  }, [])

  const isValidUsername = (username: string) => {
    return ValidationUtils.validateUsername(username)
  }

  const isValidPassword = (password: string) => {
    return ValidationUtils.validateCurrentPassword(password)
  }
  const handleUsernameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let username = isEmpty(e.target.value)
      ? ''
      : filter.clean(e.target.value).replaceAll('*', '')
    setState({
      ...state,
      username,
      usernameError: !isValidUsername(username),
    })
  }

  const validateUsernameOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (isMounted) {
      setState({
        ...state,
        usernameError: !isValidUsername(state.username),
      })
    }
  }

  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      password: e.target.value,
      passwordError: !isValidPassword(e.target.value),
    })
  }

  const validatePasswordOnBlur = (_e: React.FocusEvent<HTMLInputElement>) => {
    if (isMounted) {
      setState({
        ...state,
        passwordError: !isValidPassword(state.password),
      })
    }
  }

  const signIn = (
    username: string,
    password: string,
    autoLoginPwd = '',
    reCaptchaToken?: string | undefined | null,
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setState({
      ...state,
      loading: true,
    })
    let data: Partial<{
      username: string
      password: string
      passthrough: string
      reCaptchaToken: string
    }> = {
      username,
      password,
    }

    if (!isEmpty(reCaptchaToken)) data.reCaptchaToken = reCaptchaToken!
    if (!isEmpty(autoLoginPwd)) data.passthrough = autoLoginPwd
    ApiManager.makeApiCall(
      UrlConstants.SIGNIN.USECASE,
      HttpMethods.POST_METHOD,
      data
    )
      .then((response) => {
        if (response.status === 200) {
          if (response.data && response.data.data && response.data.data.token) {
            const pc = params.has('pc') ? params.get('pc') : ''
            const url = addQueryParamsToUrl(RouteConstants.REDIRECT, {
              token: response.data.data.token,
              pc: pc,
              leadBrand: leadBrand,
            })
            navigate(url, { replace: true })
            e &&
              fireEvent('loginSuccessful', e, {
                value: state.username,
                label: 'Login Form Submit Button',
              })
          } else {
            throw response
          }
        } else {
          throw response
        }
      })
      .catch((err) => {
        let message = err.data
          ? err.data.message
            ? err.data.message.code
              ? StringConstants.UI_ERROR_MSG_FOR_CUSTOMER_AUTH_FAILURE
              : err.data.message.message ===
                StringConstants.API_ERROR_MSG_FOR_CUSTOMER_AUTH_FAILURE
                ? StringConstants.UI_ERROR_MSG_FOR_CUSTOMER_AUTH_FAILURE
                : err.data.message
            : err.data.message
          : StringConstants.UI_ERROR_MSG_SERVER_NOT_RESPONDING
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: message,
          snackbarType: SnackbarTypes.ERROR,
        })
        setState({
          ...state,
          apiError: true,
          apiErrorText: err.data?.message,
        })
        e &&
          fireEvent('loginFailed', e, {
            value: state.username,
            label: 'Login Form Submit Button',
          })
      })
      .finally(() => {
        setIsAttemptingAutoLogin(false)
        userActionStore.setIsLoading(false)
        setState({
          ...state,
          loading: false,
        })
      })
  }

  const handleSignin = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    fireEvent('loginAttempt', e, {
      value: state.username,
      description: 'Login Form Submit Button',
    })
    if (isMounted) {
      e.preventDefault()
      var usernameError = !isValidUsername(state.username),
        passwordError = !isValidPassword(state.password)
      if (usernameError || passwordError) {
        setState({
          ...state,
          usernameError: usernameError,
          passwordError: passwordError,
        })
      } else {
        setState({
          ...state,
          usernameError: false,
          passwordError: false,
          apiError: false,
          apiErrorText: '',
          loading: true,
        })
        // const token = await reCaptchaRef.current
        //   ?.executeAsync()
        //   .catch((err) => {
        //     console.log(err.message)
        //   })
        // if (token) signIn(state.username, state.password, '', token, e)
        // else
        signIn(state.username, state.password, '', undefined, e)
      }
    }
  }

  const { usernameError, passwordError } = state
  return isAttemptingAutoLogin ? (
    <LoaderWrapper>
      <Loader
        message={StringConstants.AUTO_LOGIN_LOADER_MESSAGE}
        contents={StringConstants.AUTO_LOGIN_LOADER_CAROUSEL_CONTENT}
        leadBrand={
          leadBrand
            ? leadBrand
            : customerStore.brandName
              ? customerStore.brandName
              : ''
        }
      />
    </LoaderWrapper>
  ) : (
    <ContainerWrapper>
      <Container>
        <Heading>Login to your account</Heading>
        <SubHeading>Please enter your account details below</SubHeading>
        <Form>
          <InputField
            error={usernameError}
            helperText={
              usernameError
                ? StringConstants.UI_ERROR_MSG_FOR_INVALID_USERNAME
                : ''
            }
            label='Enter your username'
            placeholder='username'
            color='info'
            fullWidth={true}
            onChange={handleUsernameInput}
            onBlur={validateUsernameOnBlur}
            value={state.username || ''}
            width='100%'

          />
          <PasswordField
            width='100%'
            error={passwordError}
            helperText={
              passwordError
                ? StringConstants.UI_ERROR_MSG_FOR_INVALID_PASSWORD
                : ''
            }
            label='Enter your password'
            placeholder='password'
            color='info'
            fullWidth={true}
            onChange={handlePasswordInput}
            onBlur={validatePasswordOnBlur}
          />
          <LinkContainer
            to={RouteConstants.FORGOT_PASSWORD}
            onClick={(e) => {
              fireEvent('click', e, {
                description: 'Quoting Tool - Forgot Password from Login Form',
              })
            }}
          >
            Forgot Password?
          </LinkContainer>
          <ButtonContainer>
            <Button
              width='100%'
              height='40px'
              borderRadius='20px'
              variant='contained'
              color='secondary'
              onClick={handleSignin}
              loading={state.loading}
              disabled={state.loading}
            >
              Login
            </Button>
          </ButtonContainer>
          <CaptchaWrapper>
            <ReCAPTCHA
              ref={reCaptchaRef}
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY!}
              size='invisible'
            />
          </CaptchaWrapper>

          <SignupWrapper>
            New here? Get a more personalized experience: <br />
            <a
              href={getCrossTrackUrl(
                process.env.REACT_APP_LANDING_PAGE_URL
                  ? process.env.REACT_APP_LANDING_PAGE_URL
                  : ''
              )}
              target='_blank'
              rel='noreferrer'
            >
              Create an account
            </a>
          </SignupWrapper>
        </Form>
      </Container>
    </ContainerWrapper>
  )
}

export default LoginForm
