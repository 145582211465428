import React, { useEffect } from 'react'

import { BsTelephone as CallIcon } from 'react-icons/bs'
import customerStore from '../../../../datastore/CustomerStore'
import { getContact } from '../../../../utils/getContact'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface CallUsNowProps {
  heading?: string
  message?: string
  phoneNumber?: number | string
}

const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  border: 1px solid ${theme.colors.primary};
  margin: 20px 0px;
  padding: 30px 60px;
  border-radius: 16px;
  @media screen and (max-width: 640px) {
    padding: 20px;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`
const Left = styled.div`
  h2 {
    padding-bottom: 12px;
    color: ${theme.colors.primary};
    @media screen and (max-width: 1250px) {
      font-size: 20px;
    }
    @media screen and (max-width: 530px) {
      font-size: 18px;
    }
  }
  p {
    font-size: 18px;
    font-weight: 600;
    color: ${theme.colors.textDark};
    @media screen and (max-width: 1250px) {
      font-size: 14px;
    }
    @media screen and (max-width: 530px) {
      font-size: 13px;
    }
  }
`

const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 10px;
  justify-content: center;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    font-size: 36px;
    border-radius: 50%;
    background-color: ${theme.colors.contrastPrimary};
  }
  h1 {
    color: ${theme.colors.secondary};
    @media screen and (max-width: 1250px) {
      font-size: 24px;
    }
    @media screen and (max-width: 530px) {
      font-size: 20px;
    }
  }
  h2 {
    @media screen and (max-width: 1250px) {
      font-size: 20px;
    }
    @media screen and (max-width: 530px) {
      font-size: 18px;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`

const CallUsNow: React.FC<CallUsNowProps> = ({
  heading = 'Heading here',
  message = 'Your Message Here',
  phoneNumber = getContact(customerStore.getBrandName()),
}) => {
  useEffect(() => {
    window.Invoca && window.Invoca.PNAPI.run()
  }, [])
  return (
    <Container>
      <Left>
        <h2>{heading}</h2>
        <p>{message}</p>
      </Left>
      <Right>
        <div className='icon'>
          <CallIcon />
        </div>
        <a href={`tel:${phoneNumber}`} className='phone-number-fade'>
          <h2>Call Us Now</h2>
          <h1>{phoneNumber}</h1>
        </a>
      </Right>
    </Container>
  )
}

export default CallUsNow
