import {
  IoIosArrowDown as ArrowDown,
  IoIosArrowUp as ArrowUp,
} from 'react-icons/io'
import { Divider, Rating, Table, TableBody } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getApiErrorMessage, titleCase } from '../../../../utils/StringUtils'
import { isEmpty, isUndefined, startCase } from 'lodash'

import AdditionalCost from '../../AdditionalCosts.component'
import Button from '../../../common/Button/Button.component'
import CarrierLogo from '../../../common/CarrierLogo'
import Coverage from '../../Coverage.component'
import EnrollmentRequestConfirmation from '../../Modals/EnrollmentRequestConfirmation'
import { MNonSupplementPlan } from '../../../../interface/quotes/mNonSupplementPlans/MNonSupplementPlan'
import { MedicareQuotesType } from '../../../../enums/MedicareQuoteTypeEnum'
import ModalComponent from '../../../common/Modal/Modal.component'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableRow from '@mui/material/TableRow'
import PlanCardFooter from '../../Common/PlanCardFooter'
import PrescriptionDrugCost from '../../PrescriptionDrugCost.component'
import { PrescriptionMonthlyCost } from '../../../../interface/prescriptionDrug/PrescriptionMonthlyCost'
import RemoveSpouseConfirmationPopUp from '../../Modals/RemoveSpouseConfirmation'
import StringConstants from '../../../../constants/StringConstants'
import Tooltip from '../../../common/Tooltip/Tooltip.component'
import { convertToUSCurrency } from '../../../../utils/CurrencyConverterUtil'
import customerStore from '../../../../datastore/CustomerStore'
import { fetchPrescriptionMonthlyCostForPlan } from '../../../../service/prescriptions'
import { fireEvent } from '../../../../cra'
import { observer } from 'mobx-react'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import { updateEnrollmentRequest } from '../../../../service/quotes'
import userActionStore from '../../../../datastore/UserActionStore'

interface MedicareAdvantageCardProps {
  medicareAdvantagePlan: MNonSupplementPlan
  onEnroll: () => void
  handleRemoveFavorite?: () => void
  showCompareCheckbox?: boolean
  userOption: boolean
  handleSpouseRemove: () => void
  setShowAddSpouseConfirmation: (flag: boolean) => void
  setCompletePlanDetailModalActive: (flag: boolean) => void
  setCompleteDetailPlanId: (planId: string) => void
  isRecommended?: boolean
  isGoodChoice?: boolean
}
interface PlanBenefit {
  name: string
  covered: boolean
  deductible?: number
}

const Container = styled.div<{
  isRecommended?: boolean
  isGoodChoice?: boolean
}>`
  max-width: 1200px;
  width: 100%;
  overflow: hidden;
  border: ${(props) =>
    props.isRecommended
      ? `2px solid ${theme.colors.success}`
      : props.isGoodChoice
      ? `2px solid ${theme.colors.primary}`
      : `1px solid ${theme.colors.borderColor}`};
  border-radius: 16px;
  margin: 20px 0px;
  transition: box-shadow 0.4s;
  &:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.4s;
  }

  .recommended-banner {
    background-color: ${theme.colors.contrastGreen};
    padding: 8px 12px;
    text-align: center;
    h2 {
      color: ${theme.colors.green};
      font-size: 18px;
    }
  }

  .good-choice-banner {
    background-color: ${theme.colors.contrastPrimary};
    padding: 8px 12px;
    text-align: center;
    h2 {
      color: ${theme.colors.primary};
      font-size: 18px;
    }
  }
`

const Header = styled.div`
  padding: 20px 40px;
  border-bottom: 1px solid ${theme.colors.borderColor};
  color: ${theme.colors.textDark};
  div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .title {
    gap: 30px;
    padding-bottom: 10px;
    img {
      max-width: 100px;
      max-height: 40px;
    }
    h2 {
      font-size: 18px;
    }
  }
  .sub_title {
    color: ${theme.colors.textGrey};
    font-size: 14px;
    display: flex;
    align-items: center;
    .star {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  @media screen and (max-width: 600px) {
    border-bottom: none;
    padding: 10px 20px;
    .title {
      gap: 15px;
    }
    .sub_title {
      flex-direction: column;
      justify-content: center;
      gap: 5px;
    }
  }
`

const MonthlyPremiumWrapper = styled.div<{ isInGrid: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isInGrid ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  border-bottom: 1px solid ${theme.colors.borderColor};
  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding: 10px 20px;
    .premium_details {
      flex-direction: column;
    }
  }
  .premium_details {
    display: flex;
    flex-direction: ${(props) => (props.isInGrid ? 'column' : 'row')};
  }
  .premium_info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: ${theme.colors.textGrey};
    .premium_value {
      font-size: 24px;
      font-weight: 700;
      color: ${theme.colors.primary};
    }
  }
`

const OutOfPocketExpenses = styled.div`
  display: flex;
  padding: 20px 40px;
  align-items: center;
  gap: 10px;
  span {
    font-weight: 600;
    font-size: 14px;
    color: ${theme.colors.textGrey};
  }
  p {
    color: ${theme.colors.primary};
    font-weight: 700;
  }
  @media screen and (max-width: 600px) {
    padding: 20px;
    flex-direction: column;
    justify-content: center;
  }
`

const MoreDetailsWrapper = styled.div`
  padding: 10px 40px;
  .detail-header {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-bottom: 10px;
    p {
      font-size: 14px;
      font-weight: 700;
      color: ${theme.colors.textGrey};
    }
    span {
      text-decoration: underline;
      font-weight: 700;
      color: ${theme.colors.primary};
      font-size: 12px;
    }
  }
  @media screen and (max-width: 500px) {
    padding: 10px 20px;
  }
`

const TableRow = styled(MuiTableRow)`
  &:nth-of-type(odd) {
    background-color: ${theme.colors.backgroundColorContainer};
  }
`

const TableCell = styled(MuiTableCell)<{ customClass?: string }>`
  font-weight: 700;
  color: ${theme.colors.textGrey};
  font-size: 12px;
  padding: 15px 10px;
  ${({ customClass }) =>
    customClass === 'plan-benefit-coverage' &&
    `
      font-size: 10px;
      color:${theme.colors.textGrey};
      font-weight:400;
    `}
  @media screen and (max-width: 600px) {
    width: 25%;
    padding: 15px 10px;
  }
`

const NavigationWrapper = styled.div`
  text-decoration: underline;
  font-weight: 600;
  color: ${theme.colors.primary};
  font-size: 14px;
`

const CollapsibleDetailsWrapper = styled.div<{
  isViewMorCoverageDetailsExpanded: boolean
}>`
  .collapsible-button {
    display: none;
    text-align: center;
    @media screen and (max-width: 600px) {
      display: block;
    }
  }
  .collapsible-content {
    @media screen and (max-width: 600px) {
      display: ${({ isViewMorCoverageDetailsExpanded }) =>
        isViewMorCoverageDetailsExpanded ? 'block' : 'none'};
    }
  }
`

const AdditionalCostWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 20px 40px;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.borderColor};
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
`

const MedicareAdvantageCard: React.FC<MedicareAdvantageCardProps> = ({
  medicareAdvantagePlan,
  onEnroll,
  handleRemoveFavorite,
  showCompareCheckbox = true,
  userOption,
  handleSpouseRemove,
  setShowAddSpouseConfirmation,
  setCompletePlanDetailModalActive,
  setCompleteDetailPlanId,
  isGoodChoice = false,
  isRecommended = false,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  //For Collapsible sections
  const [isPlanBenefitExpanded, setIsPlanBenefitExpanded] =
    React.useState(false)
  const [isPrescriptionExpanded, setIsPrescriptionExpanded] =
    React.useState(false)
  const [isPharmacyExpanded, setIsPharmacyExpanded] = React.useState(false)
  const [isDoctorCoverageExpanded, setIsDoctorCoverageExpanded] =
    React.useState(false)

  const [showRemoveSpouseConfirmation, setShowRemoveSpouseConfirmation] =
    React.useState(false)

  const [drugCostDetails, setDrugCostDetails] = React.useState<
    PrescriptionMonthlyCost[]
  >([] as PrescriptionMonthlyCost[])

  const [isDrugCostDetailsLoading, setIsDrugCostDetailsLoading] =
    React.useState<boolean>(false)

  const [
    isViewMorCoverageDetailsExpanded,
    setIsViewMorCoverageDetailsExpanded,
  ] = React.useState(false)

  const handleIsPlanBenefitExpansion = (flag: boolean) => {
    setIsPlanBenefitExpanded(flag)
  }
  const handleIsPrescriptionExpanded = (flag: boolean) => {
    setIsPrescriptionExpanded(flag)
  }
  const handleIsPharmacyExpanded = (flag: boolean) => {
    setIsPharmacyExpanded(flag)
  }
  const handleIsDoctorCoverageExpanded = (flag: boolean) => {
    setIsDoctorCoverageExpanded(flag)
  }

  const [primaryEnrollmentStatus, setPrimaryEnrollmentStatus] = React.useState(
    medicareAdvantagePlan.enrollRequested
  )

  const [spouseEnrollmentStatus, setSpouseEnrollmentStatus] = React.useState(
    medicareAdvantagePlan.spouseEnrollRequested
  )

  const [spouseEnrollmentRequested, setSpouseRequestedEnrollment] =
    React.useState<boolean>(false)

  const [enrollmentRequestPromptActive, setEnrollmentRequestPromptActive] =
    React.useState(false)

  const [loading, setLoading] = React.useState(false)
  const handleExpandCard = (maPlanName: string) => {
    if (isExpanded === true) {
      customerStore.setSearchPlan('')
      customerStore.setScrollPosition('')
      setIsExpanded(false)
    }
    if (isExpanded === false) {
      setIsExpanded(true)
    }
    if (medicareAdvantagePlan.name === customerStore.searchPlan) {
      setIsExpanded(false)
      customerStore.setSearchPlan('')
      customerStore.setScrollPosition('')
    }
  }
  const [maPlanCard, setMAPlanCard] = useState<MNonSupplementPlan>()
  const [preferredPhone, setPreferredPhone] = React.useState(
    customerStore.get().phone.replace('+1', '')
  )

  let planBenefits: PlanBenefit[] = []

  const handleEnrollRequest = () => {
    setLoading(true)
    updateEnrollmentRequest(
      spouseEnrollmentRequested
        ? medicareAdvantagePlan.spousePlanId!
        : medicareAdvantagePlan.id,
      MedicareQuotesType.MEDICARE_NON_SUPPLEMENT,
      spouseEnrollmentRequested,
      `+1${preferredPhone.toString()}`
    )
      .then(() => {
        setLoading(false)
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: 'Enroll request saved successfully',
          snackbarType: 'success',
        })
        if (spouseEnrollmentRequested) setSpouseEnrollmentStatus(true)
        else setPrimaryEnrollmentStatus(true)
        setEnrollmentRequestPromptActive(false)
        onEnroll()
      })
      .catch((err) => {
        if (
          err.data?.message ===
          'Maximum limit reached for requesting enrollment for plans'
        ) {
          setLoading(false)
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage(
              'save enroll request. Maximum limit reached'
            ),
            snackbarType: 'error',
          })
          setPrimaryEnrollmentStatus(false)
          setEnrollmentRequestPromptActive(false)
        } else {
          setLoading(false)
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage('save enroll request'),
            snackbarType: 'error',
          })
          setPrimaryEnrollmentStatus(false)
          setEnrollmentRequestPromptActive(false)
        }
      })
  }

  useEffect(() => {
    if (
      maPlanCard &&
      maPlanCard?.drugCosts?.length > 0 &&
      isExpanded &&
      isEmpty(drugCostDetails)
    ) {
      setIsDrugCostDetailsLoading(true)
      fetchPrescriptionMonthlyCostForPlan(
        maPlanCard?.sunfirePlanId!.toString(),
        maPlanCard?.contractYear.toString(),
        userOption
      )
        .then((drugCostResponse) => {
          setDrugCostDetails(drugCostResponse)
        })
        .catch((err) => {})
        .finally(() => {
          setIsDrugCostDetailsLoading(false)
        })
    }
  }, [isExpanded])

  useEffect(() => {
    setMAPlanCard(medicareAdvantagePlan)
  }, [medicareAdvantagePlan])

  if (maPlanCard === null) {
    return null
  }

  let packageBenefits: any = {
    hasVision: medicareAdvantagePlan.hasVision,
    hasTransport: medicareAdvantagePlan.hasTransport,
    hasDental: medicareAdvantagePlan.hasDental,
    hasHearing: medicareAdvantagePlan.hasHearing,
    hasOTC: medicareAdvantagePlan.hasOTC,
    hasPreferredPharmacies: medicareAdvantagePlan.hasPreferredPharmacies,
    hasInsulinSavings: medicareAdvantagePlan.hasInsulinSavings,
    acceptsMailOrder: medicareAdvantagePlan.acceptsMailOrder,
    partBReimbursement: medicareAdvantagePlan.partBReimbursement,
  }

  Object.keys(packageBenefits).forEach((benefit) => {
    let covered = !(
      packageBenefits[benefit] === 'N' ||
      packageBenefits[benefit] === false ||
      packageBenefits[benefit] === -1
    )
    benefit = /^has/.test(benefit) ? benefit.slice(3) : benefit
    planBenefits.push({
      name: startCase(benefit).replace(/\s/g, ' '),
      covered: covered,
      deductible: undefined,
    })
  })

  const handleAddToCompare = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      // If same plan type -> Check if length > 3. If no, send request. If yes, show message
      if (
        userActionStore.comparePlanCards.planType ===
        MedicareQuotesType.MEDICARE_ADVANTAGE
      ) {
        if (userActionStore.comparePlanCards.compareCardsData.length < 3) {
          userActionStore.addCard(MedicareQuotesType.MEDICARE_ADVANTAGE, {
            id: medicareAdvantagePlan.id,
            name: medicareAdvantagePlan.name,
            premium: medicareAdvantagePlan.premium,
            carrier: medicareAdvantagePlan.carrierName,
            enrollRequestStatus: medicareAdvantagePlan.enrollRequested,
            planType: MedicareQuotesType.MEDICARE_NON_SUPPLEMENT,
            sunfirePlanId: medicareAdvantagePlan.sunfirePlanId,
            rating: medicareAdvantagePlan.fmtRating,
          })
        } else {
          snackbarStore.set({
            snackbarMessage: 'You can only add up to 3 plans for comparison',
            snackbarOpen: true,
            snackbarType: 'default',
          })
        }
      } else {
        // If switched to a different plan type, just send the information. The state will
        // reset and count will start from 0
        userActionStore.addCard(MedicareQuotesType.MEDICARE_ADVANTAGE, {
          id: medicareAdvantagePlan.id,
          name: medicareAdvantagePlan.name,
          premium: medicareAdvantagePlan.premium,
          carrier: medicareAdvantagePlan.carrierName,
          enrollRequestStatus: medicareAdvantagePlan.enrollRequested,
          planType: MedicareQuotesType.MEDICARE_NON_SUPPLEMENT,
          sunfirePlanId: medicareAdvantagePlan.sunfirePlanId,
          rating: medicareAdvantagePlan.fmtRating,
        })
      }
    }

    if (!e.currentTarget.checked) {
      userActionStore.removeCard(medicareAdvantagePlan.id)
    }
  }

  const storeScrollPosition = () => {
    const scrollPosition = window.scrollY
    customerStore.setScrollPosition(scrollPosition.toString())
  }

  return (
    <>
      {maPlanCard && (
        <Container isGoodChoice={isGoodChoice} isRecommended={isRecommended}>
          {isRecommended && (
            <div className='recommended-banner'>
              <h2>RECOMMENDATION</h2>
            </div>
          )}
          {isGoodChoice && (
            <div className='good-choice-banner'>
              <h2>GOOD CHOICE</h2>
            </div>
          )}
          {enrollmentRequestPromptActive && (
            <ModalComponent
              setOpen={() => {
                setEnrollmentRequestPromptActive(false)
              }}
              description='Popup - Request for Enrollment Confirmation'
              hideCloseButton={true}
            >
              <EnrollmentRequestConfirmation
                setOpen={() => {
                  setEnrollmentRequestPromptActive(false)
                }}
                onEnroll={() => {
                  handleEnrollRequest()
                }}
                planName={medicareAdvantagePlan.name}
                planType={MedicareQuotesType.MEDICARE_ADVANTAGE}
                planId={medicareAdvantagePlan.id}
                buttonLoading={loading}
                preferredPhoneNumber={preferredPhone}
                setPreferredPhoneNumber={setPreferredPhone}
              />
            </ModalComponent>
          )}

          {showRemoveSpouseConfirmation && (
            <ModalComponent
              setOpen={() => {
                setShowRemoveSpouseConfirmation(!showRemoveSpouseConfirmation)
              }}
              description='Popup - Request for Enrollment Confirmation'
              hideCloseButton={true}
            >
              <RemoveSpouseConfirmationPopUp
                onConfirm={() => {
                  handleSpouseRemove()
                }}
                onReject={() => {
                  setShowRemoveSpouseConfirmation(!showRemoveSpouseConfirmation)
                }}
              />
            </ModalComponent>
          )}

          <Header>
            <div className='title'>
              <CarrierLogo carrier={maPlanCard.carrierName} useAltLogo={true} />
              <h2>{maPlanCard.name}</h2>
            </div>
            <div className='sub_title'>
              <p>{`${maPlanCard.contractId}-${maPlanCard.planId}-${maPlanCard.segmentId}`}</p>
              <Divider flexItem orientation='vertical' />
              <p className='star'>
                <span>
                  Star Rating
                  <Tooltip
                    title={
                      StringConstants.TOOLTIP_NON_ELIGIBLE_STAR_RATING_MESSAGE
                    }
                  />
                </span>
                <Rating
                  name='simple-controlled'
                  value={maPlanCard.fmtRating || 0}
                  precision={0.5}
                  readOnly={true}
                  size='medium'
                />
              </p>
            </div>
          </Header>

          <MonthlyPremiumWrapper isInGrid={isRecommended || isGoodChoice}>
            <div className='premium_details'>
              {maPlanCard.premium !== -1 && (
                <div className='premium_info'>
                  <p>{`${customerStore.firstName}'s Monthly Premium :`}</p>
                  <p className='premium_value'>
                    {convertToUSCurrency(maPlanCard.premium)}
                  </p>
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                      setSpouseRequestedEnrollment(false)
                      setEnrollmentRequestPromptActive(true)
                    }}
                    disabled={primaryEnrollmentStatus}
                    description={`Attempted Enrollment in ${medicareAdvantagePlan.name}`}
                    id={`enroll ${medicareAdvantagePlan.name}`}
                    width='250px'
                  >
                    {primaryEnrollmentStatus
                      ? 'Enrollment Requested'
                      : 'ENROLL'}
                  </Button>
                </div>
              )}

              {userOption && !isUndefined(maPlanCard.spousePremium) ? (
                <>
                  {maPlanCard.premium !== -1 && (
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{ margin: '0px 10px' }}
                    />
                  )}
                  <div className='premium_info'>
                    <p>{`${customerStore.spouseFirstName}'s Monthly Premium :`}</p>
                    <p className='premium_value'>
                      {convertToUSCurrency(maPlanCard.spousePremium)}
                    </p>
                    <Button
                      variant='outlined'
                      onClick={() => {
                        setSpouseRequestedEnrollment(true)
                        setEnrollmentRequestPromptActive(true)
                      }}
                      disabled={spouseEnrollmentStatus}
                      description={`Attempted Enrollment in ${medicareAdvantagePlan.name}`}
                      id={`enroll ${medicareAdvantagePlan.name}`}
                      width='250px'
                    >
                      {spouseEnrollmentStatus
                        ? 'Enrollment Requested'
                        : 'ENROLL'}
                    </Button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            {customerStore.spouseOptIn ? (
              <NavigationWrapper
                style={{
                  cursor: 'pointer',
                  padding: '0px',
                  marginTop: '3px',
                }}
                onClick={() => {
                  setShowRemoveSpouseConfirmation(!showRemoveSpouseConfirmation)
                }}
              >
                - Remove Spouse/Partner
              </NavigationWrapper>
            ) : (
              <NavigationWrapper
                style={{
                  cursor: 'pointer',
                  padding: '0px',
                  marginTop: '3px',
                }}
                onClick={() => {
                  setShowAddSpouseConfirmation(true)
                }}
              >
                + Add Spouse/Partner
              </NavigationWrapper>
            )}
          </MonthlyPremiumWrapper>

          <CollapsibleDetailsWrapper
            isViewMorCoverageDetailsExpanded={isViewMorCoverageDetailsExpanded}
          >
            <div className='collapsible-button'>
              <Button
                variant='text'
                color='primary'
                bold={false}
                endIcon={
                  isViewMorCoverageDetailsExpanded ? (
                    <ArrowUp style={{ fontSize: '24px' }} />
                  ) : (
                    <ArrowDown style={{ fontSize: '24px' }} />
                  )
                }
                onClick={(e) => {
                  setIsViewMorCoverageDetailsExpanded(
                    !isViewMorCoverageDetailsExpanded
                  )
                }}
              >
                <>
                  View {isViewMorCoverageDetailsExpanded ? 'less' : 'more'}{' '}
                  details
                </>
              </Button>
              <Divider orientation='horizontal' flexItem />
            </div>
            <div className='collapsible-content'>
              <AdditionalCostWrapper>
                {maPlanCard.additionalCosts?.map((additionalCost, index) => {
                  return (
                    <div>
                      <AdditionalCost
                        name={additionalCost.name}
                        toolTip={additionalCost.toolTip}
                        value={additionalCost.value}
                      />
                    </div>
                  )
                })}
              </AdditionalCostWrapper>
              <OutOfPocketExpenses>
                <span>Maximum Out of Pocket :</span>
                <p>
                  {maPlanCard.fmtMoop} (in-network) /
                  {maPlanCard.combMoop === -1
                    ? ' N/A '
                    : ` ${maPlanCard.fmtCombMoop} `}
                  (combined)
                </p>
              </OutOfPocketExpenses>
              <Divider orientation='horizontal' flexItem />
              <Coverage
                heading='Plan Benefits'
                coveragesData={planBenefits}
                isContentExpanded={isPlanBenefitExpanded}
                setIsContentExpanded={handleIsPlanBenefitExpansion}
                toolTipContent={StringConstants.TOOLTIP_MSG_PLAN_BENEFITS}
              ></Coverage>
              <Coverage
                heading='Doctor Coverage'
                subtitle='Doctors'
                isSpouseSection={userOption}
                coveragesData={
                  maPlanCard.providerCoverage?.length > 0
                    ? maPlanCard.providerCoverage?.map((coverage) => {
                        return {
                          name: coverage.provider.name,
                          covered: coverage.isCovered,
                        }
                      })
                    : []
                }
                spouseCoverageData={
                  !isUndefined(maPlanCard.spouseProviders) &&
                  maPlanCard.spouseProviders.length > 0
                    ? maPlanCard.spouseProviders?.map((coverage) => {
                        return {
                          name: coverage.provider.name,
                          covered: coverage.isCovered,
                        }
                      })
                    : []
                }
                isContentExpanded={isDoctorCoverageExpanded}
                setIsContentExpanded={handleIsDoctorCoverageExpanded}
                toolTipContent={StringConstants.TOOLTIP_MSG_PLAN_BENEFITS}
                hyperlinkDetails={{
                  label: 'Add Doctors',
                  path: '/profile/myprofile/doctors',
                }}
              ></Coverage>
              {medicareAdvantagePlan.type !== 'MA' && (
                <>
                  <Coverage
                    heading='Pharmacy'
                    subtitle='Pharmacies'
                    coveragesData={
                      maPlanCard.drugCosts?.length > 0
                        ? maPlanCard.drugCosts?.map((drugCost) => {
                            return {
                              name: titleCase(drugCost.pharmacyName),
                              covered:
                                drugCost.pharmacyNetworkStatus === '0' ||
                                drugCost.pharmacyNetworkStatus === '-1'
                                  ? false
                                  : true,
                            }
                          })
                        : []
                    }
                    spouseCoverageData={
                      !isUndefined(maPlanCard.spouseDrugCosts) &&
                      maPlanCard.spouseDrugCosts?.length > 0
                        ? maPlanCard.spouseDrugCosts?.map((drugCost) => {
                            return {
                              name: titleCase(drugCost.pharmacyName),
                              covered:
                                drugCost.pharmacyNetworkStatus === '0' ||
                                drugCost.pharmacyNetworkStatus === '-1'
                                  ? false
                                  : true,
                            }
                          })
                        : []
                    }
                    isSpouseSection={userOption}
                    isContentExpanded={isPharmacyExpanded}
                    setIsContentExpanded={handleIsPharmacyExpanded}
                    toolTipContent={StringConstants.TOOLTIP_MSG_PLAN_BENEFITS}
                    hyperlinkDetails={{
                      label: 'Add Preferred Pharmacy',
                      path: '/profile/myprofile/pharmacy',
                    }}
                  ></Coverage>
                  <Coverage
                    heading='Prescription Drug Coverage'
                    subtitle='Prescriptions'
                    coveragesData={
                      maPlanCard.drugInformations?.length > 0
                        ? maPlanCard.drugInformations?.map(
                            (coverage, index) => {
                              return {
                                name: titleCase(coverage.name),
                                covered: coverage.tier !== -1,
                              }
                            }
                          )
                        : []
                    }
                    spouseCoverageData={
                      !isUndefined(maPlanCard.spouseDrugInformation) &&
                      maPlanCard.spouseDrugInformation?.length > 0
                        ? maPlanCard.spouseDrugInformation?.map(
                            (coverage, index) => {
                              return {
                                name: titleCase(coverage.name),
                                covered: coverage.tier !== -1,
                              }
                            }
                          )
                        : []
                    }
                    isSpouseSection={userOption}
                    isContentExpanded={isPrescriptionExpanded}
                    setIsContentExpanded={handleIsPrescriptionExpanded}
                    toolTipContent={StringConstants.TOOLTIP_MSG_PLAN_BENEFITS}
                    hyperlinkDetails={{
                      label: 'Add Prescription Drugs',
                      path: '/profile/myprofile/prescriptions',
                    }}
                  ></Coverage>
                </>
              )}
            </div>
          </CollapsibleDetailsWrapper>
          {(isExpanded ||
            medicareAdvantagePlan.name === customerStore.searchPlan) && (
            <>
              <PlanCardFooter
                isInGrid={isGoodChoice || isRecommended}
                id={maPlanCard.id}
                forSpouse={userOption}
                spousePlanId={userOption ? maPlanCard.spousePlanId : undefined}
                isPrimarySaved={maPlanCard.planSaved}
                planId={maPlanCard.planId}
                favourite={
                  userOption ? maPlanCard.spousePlanSaved : maPlanCard.planSaved
                }
                handleRemoveFavourite={handleRemoveFavorite}
                showCompareCheckbox={showCompareCheckbox}
                planType={maPlanCard.type}
                planName={maPlanCard.name}
                handleAddToCompare={handleAddToCompare}
                isExpanded={isExpanded}
                handleExpandCard={handleExpandCard}
                medicareQuoteType={MedicareQuotesType.MEDICARE_NON_SUPPLEMENT}
              />
              <MoreDetailsWrapper>
                {planBenefits?.length > 0 && (
                  <>
                    <div className='detail-header'>
                      <p>Plan Benefits </p>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          fireEvent('viewCompletePlanDetails', e, {
                            description:
                              'View Complete Plan Details of Medicare Advantage Card',
                            planId: maPlanCard.id,
                            planType: maPlanCard.type,
                            planName: maPlanCard.name,
                          })
                          // navigate({
                          //   pathname: '/plan-details',
                          //   search: `id=${maPlanCard.id}&&type=ma`,
                          // })
                          setCompleteDetailPlanId(maPlanCard.id)
                          setCompletePlanDetailModalActive(true)
                          storeScrollPosition()
                        }}
                      >
                        View complete plan details
                      </span>
                    </div>

                    <Table
                      style={{
                        marginBottom: '12px',
                        border: '1px solid #E5E5E5',
                      }}
                    >
                      <TableBody>
                        {planBenefits.map((planBenefit, index) => (
                          <TableRow>
                            <TableCell
                              sx={{ width: '50%' }}
                              style={{
                                textAlign: 'left',
                                borderRight: '1px solid #E5E5E5',
                              }}
                            >
                              {planBenefit.name}
                            </TableCell>
                            <TableCell
                              sx={{ width: '50%' }}
                              customClass='plan-benefit-coverage'
                              style={{ textAlign: 'left' }}
                            >
                              {planBenefit.covered ? 'Covered' : 'No Covered'}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                )}
                {maPlanCard.drugCosts?.length > 0 && (
                  <PrescriptionDrugCost
                    drugCostDetails={drugCostDetails}
                    loading={isDrugCostDetailsLoading}
                    mNonSupplementPlan={maPlanCard}
                  />
                )}
              </MoreDetailsWrapper>
            </>
          )}
          <PlanCardFooter
            isInGrid={isGoodChoice || isRecommended}
            planId={maPlanCard.planId}
            id={maPlanCard.id}
            forSpouse={userOption}
            spousePlanId={userOption ? maPlanCard.spousePlanId : undefined}
            isPrimarySaved={maPlanCard.planSaved}
            favourite={
              userOption ? maPlanCard.spousePlanSaved : maPlanCard.planSaved
            }
            handleRemoveFavourite={handleRemoveFavorite}
            showCompareCheckbox={showCompareCheckbox}
            planType={maPlanCard.type}
            planName={maPlanCard.name}
            handleAddToCompare={handleAddToCompare}
            isExpanded={isExpanded}
            handleExpandCard={handleExpandCard}
            medicareQuoteType={MedicareQuotesType.MEDICARE_NON_SUPPLEMENT}
          />
        </Container>
      )}
    </>
  )
}

export default observer(MedicareAdvantageCard)
