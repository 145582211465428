import Button from '../common/Button/Button.component'
import CircularProgress from '../common/CircularProgress/CircularProgress'
import CloseIconButton from '../common/Button/CloseIconButton.component'
import Icon from '../common/Icon/Icon.component'
import React from 'react'
import RouteConstants from '../../constants/RouteConstants'
import customerStore from '../../datastore/CustomerStore'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useNavigate } from 'react-router-dom'

interface LeftNavbarProps {
  navLinks: {
    label: string
    icon: React.ReactNode
    link: string
  }[]
  activeNav: string
  onClick: (link: string) => void
  showLeftNav?: boolean
  showLeftNavHandler?: () => void
  isSpouse?: boolean
}

interface LeftNavbarContainerProps {
  showNav?: boolean
}

const SidebarWrapper = styled.div`
  min-width: 350px;
  @media screen and (max-width: 950px) {
    min-width: 0px;
    width: 0px;
  }
`

const Container = styled.div<LeftNavbarContainerProps>`
  background-color: ${theme.colors.white};
  border-right: 1px solid ${theme.colors.borderColor};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  z-index: 500;
  position: fixed;
  top: 0px;
  left: 0;
  height: 100%;
  width: 350px;
  padding: 120px 0px 50px 0px;
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (max-width: 950px) {
    max-height: 100vh;
    padding: 20px 0px;
    width: 320px;
    z-index: 1001;
    display: ${({ showNav }) => (showNav ? 'block' : 'none')};
  }
`

const Overlay = styled.div<LeftNavbarContainerProps>`
  @media screen and (max-width: 950px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: ${({ showNav }) => (showNav ? 'block' : 'none')};
    transition: background-color 0.3s ease;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 0px 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${theme.colors.borderColor};
`

const UserNameWrapper = styled.div`
  h2 {
    color: ${theme.colors.primary};
    font-size: 18px;
    word-break: break-all;
  }
  p {
    font-size: 14px;
    color: ${theme.colors.textDark};
  }
`

const NotificationContainer = styled.div`
  font-size: 14px;
  color: ${theme.colors.textGrey};
  padding: 20px;
  border-bottom: 1px solid ${theme.colors.borderColor};
`

const NavigationContainer = styled.div`
  padding: 20px;
`

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 0px;
  display: none;
  @media screen and (max-width: 900px) {
    display: block;
  }
`

const ProfileLink = styled.p`
  font-weight: 600;
  padding: 2px 0px;
  font-size: 12px;
  color: ${theme.colors.primary};
  text-decoration: underline;
  cursor: pointer;
`

const LeftNavbar: React.FC<LeftNavbarProps> = ({
  navLinks,
  activeNav,
  onClick,
  showLeftNav,
  showLeftNavHandler,
  isSpouse = false,
}) => {
  const [progress, setProgress] = React.useState(10)
  const navigate = useNavigate()

  React.useEffect(() => {
    let progress: number = 10
    if (customerStore.infoSteps.length !== 0) {
      if (customerStore.infoSteps.includes('prescription')) progress += 10
      if (customerStore.infoSteps.includes('healthQuestion')) progress += 10
      if (customerStore.infoSteps.includes('pharmacy')) progress += 10
      if (customerStore.infoSteps.includes('currentPlan')) progress += 10
      if (customerStore.infoSteps.includes('doctor')) progress += 10
      if (customerStore.gender) progress += 10
      if (customerStore.dateOfBirth) progress += 10
    }
    if (customerStore.spouseOptIn) {
      progress += 20
    }

    console.log('progress', progress)
    setProgress(progress)
  }, [
    customerStore.infoSteps,
    customerStore.spouseOptIn,
    customerStore.gender,
    customerStore.dateOfBirth,
  ])

  return (
    <SidebarWrapper>
      <Overlay showNav={showLeftNav} />
      <Container showNav={showLeftNav}>
        <CloseButtonWrapper>
          <CloseIconButton fontSize='32px' onClick={showLeftNavHandler} />
        </CloseButtonWrapper>
        <HeaderContainer>
          {!isSpouse && <CircularProgress progress={progress} />}
          <UserNameWrapper>
            <h2>
              {isSpouse && customerStore.spouseOptIn
                ? customerStore.getSpouseFullName()
                : customerStore.getFullName().length > 33
                ? customerStore.getFullName().slice(0, 30) + '...'
                : customerStore.getFullName()}
            </h2>
            <p>{customerStore.getPostalCodeAndCounty()}</p>
          </UserNameWrapper>
        </HeaderContainer>
        {!isSpouse && (
          <NotificationContainer>
            {progress !== 100 && <p>To complete your profile - </p>}
            {!customerStore.spouseOptIn && (
              <ProfileLink
                onClick={() => {
                  onClick('/profile/spouseprofile/profile')
                }}
              >
                Add your spouse/partner
              </ProfileLink>
            )}
            {progress === 100 && (
              <strong style={{ color: theme.colors.primary }}>
                Profile status - 100% complete
              </strong>
            )}
            {!customerStore.infoSteps.includes('prescription') && (
              <ProfileLink
                onClick={() => {
                  onClick('prescriptions')
                }}
              >
                Add your prescription drugs
              </ProfileLink>
            )}
            {!customerStore.infoSteps.includes('healthQuestion') && (
              <ProfileLink
                onClick={() => {
                  onClick('health')
                }}
              >
                Answer health questions
              </ProfileLink>
            )}
            {!customerStore.infoSteps.includes('pharmacy') && (
              <ProfileLink
                onClick={() => {
                  onClick('pharmacy')
                }}
              >
                Add your pharmacy
              </ProfileLink>
            )}
            {!customerStore.infoSteps.includes('doctor') && (
              <ProfileLink
                onClick={() => {
                  onClick('doctors')
                }}
              >
                Add your physician
              </ProfileLink>
            )}
            {!customerStore.infoSteps.includes('currentPlan') && (
              <ProfileLink
                onClick={() => {
                  navigate(RouteConstants.INFO_STEPS_CURRENT_PLAN)
                }}
              >
                Add your Medicare Plan Info
              </ProfileLink>
            )}
          </NotificationContainer>
        )}
        <NavigationContainer>
          {navLinks.map((navLink) => (
            <Button
              key={navLink.label}
              value={navLink.link}
              width='100%'
              borderRadius='4px'
              justifyContent='flex-start'
              margin='10px 0px'
              variant={activeNav === navLink.link ? 'contained' : 'text'}
              onClick={() => onClick(navLink.link)}
              bold={false}
            >
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Icon
                  childComponent={navLink.icon}
                  padding='0px 5px'
                  fontSize='20px'
                />
                {navLink.label}
              </span>
            </Button>
          ))}
        </NavigationContainer>
      </Container>
    </SidebarWrapper>
  )
}

export default observer(LeftNavbar)
