import React from 'react'
import styled from '@emotion/styled'

interface DivStyledProps {
  row?: boolean
}

interface SectionRowProps
  extends React.HTMLAttributes<HTMLDivElement>,
    DivStyledProps {}

const Div = styled.div<DivStyledProps>`
  max-width: 750px;
  padding-bottom: 20px;
  display: ${({ row }) => (row === true ? 'grid' : 'block')};
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

const SectionRow: React.FC<SectionRowProps> = ({ children, ...props }) => {
  return <Div {...props}>{children}</Div>
}

export default SectionRow
