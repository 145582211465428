import { Link } from 'react-router-dom'
import React from 'react'
import RouteConstants from '../../../constants/RouteConstants'
import StringConstants from '../../../constants/StringConstants'
import Tooltip from '../../common/Tooltip/Tooltip.component'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface OptionalMessageProps extends ContainerProps {
  heading?: string
  link?: string
  linkText?: string
  showToolTip?: boolean
}

interface ContainerProps {
  fontSize?: string
  fontcolor?: string
  padding?: string
  margin?: string
}

const HeadingContainer = styled.div<ContainerProps>`
  padding: 20px 0px;
  border-bottom: 1px solid ${theme.colors.borderColor};
  h3 {
    font-size: 14px;
    font-weight: 700;
    color: ${theme.colors.textDark};
    display: flex;
    align-items: center;
  }
  p {
    color: ${(props) => props.fontcolor};
    font-weight: normal;
    font-size: ${(props) => props.fontSize};
    text-decoration: none;
  }
  a {
    font-weight: 700;
    color: ${theme.colors.primary};
  }
`

const OptionalMessage: React.FC<OptionalMessageProps> = ({
  heading = '',
  link,
  linkText = 'change your preference',
  padding = '10px 0px',
  margin = '0px',
  fontcolor = `${theme.colors.textGrey}`,
  fontSize = '14px',
  showToolTip = false,
}) => {
  return (
    <HeadingContainer
      fontcolor={fontcolor}
      fontSize={fontSize}
      padding={padding}
      margin={margin}
    >
      <h3>
        {heading}
        {showToolTip && (
          <Tooltip title={StringConstants.TOOLTIP_AUTO_APPLY_INFOSTEPS} />
        )}
      </h3>
      <Link to={link || RouteConstants.PROFILE + '/myprofile/profile'}>
        {linkText}
      </Link>
    </HeadingContainer>
  )
}

export default OptionalMessage
