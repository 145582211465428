import { Outlet, useLocation } from 'react-router-dom'

import CircularProgress from '../../components/common/CircularProgress/CircularProgress'
import InfoStepsStepper from '../../components/InfoStepsPage/Stepper.InfoSteps'
import LayoutComponent from '../../components/common/Layout/Layout.component'
import RouteConstants from '../../constants/RouteConstants'
import SecureComponent from '../common/SecureComponent'
import { convertNameToTitleCase } from '../../utils/StringUtils'
import customerStore from '../../datastore/CustomerStore'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../global/theme'

const stepDetails: {
  order: number
  label: string
  route: string
}[] = [
  {
    order: 0,
    label: 'Questionnaire',
    route: RouteConstants.INFO_STEPS_QUESTIONNAIRE,
  },
  {
    order: 1,
    label: 'Prescriptions',
    route: RouteConstants.INFO_STEPS_PRESCRIPTION,
  },
  {
    order: 2,
    label: 'Health',
    route: RouteConstants.INFO_STEPS_HEALTH,
  },
  {
    order: 3,
    label: 'Pharmacy',
    route: RouteConstants.INFO_STEPS_PHARMACY,
  },
  {
    order: 4,
    label: 'Physicians',
    route: RouteConstants.INFO_STEPS_DOCTORS,
  },
  {
    order: 5,
    label: 'Current Plan',
    route: RouteConstants.INFO_STEPS_CURRENT_PLAN,
  },
]

const Container = styled.div`
  height: 100%;
  background-color: ${theme.colors.white};
  display: grid;
  grid-template-columns: 1fr 5fr;
  width: 100%;

  @media screen and (max-width: 700px) {
    display: block;
  }
`

const StepperWrapper = styled.div`
  padding: 20px 30px;
  padding-bottom: 50px;
  .MuiStepper-root {
    height: 100%;
    align-items: baseline;
    .MuiStepConnector-root {
      padding-left: 11px;
    }
    .MuiStepConnector-line {
      height: 100%;
      min-height: 50px;
    }
  }
`

const ProgressContainer = styled.div`
  padding: 20px 15px;
  display: flex;
  gap: 10px;
  border-bottom: 1px solid ${theme.colors.borderColor};

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      color: ${theme.colors.primary};
      font-size: 16px;
    }

    p {
      font-size: 14px;
      color: ${theme.colors.textGrey};
    }
  }
`

const Sidebar = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.06);
  min-width: 240px;

  @media screen and (max-width: 700px) {
    display: none;
  }
`

const CardWrapper = styled.div`
  padding: 50px;

  @media screen and (max-width: 700px) {
    padding: 20px;
  }
`

const Card = styled.div`
  border: 1px solid ${theme.colors.borderColor};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  width: 100%;
  height: max-content;
  padding: 20px 40px;

  @media screen and (max-width: 600px) {
    padding: 20px 20px;
  }
`

const InfoStepsPage = () => {
  const customerFirstName = convertNameToTitleCase(
    customerStore.firstName.trim()
  )

  const { pathname } = useLocation()

  const getProgress = () => {
    const currentStep = stepDetails.find(
      (iterator) => iterator.route.split('/')[2] === pathname.split('/')[2]
    )
    if (currentStep) {
      return (100 * currentStep.order) / stepDetails.length
    }
    return 0
  }

  return (
    <LayoutComponent>
      <Container>
        <Sidebar>
          <ProgressContainer>
            <CircularProgress progress={getProgress()} />
            <div className='content'>
              <h2>
                {customerFirstName.length > 11
                  ? customerFirstName.slice(0, 8) + '...'
                  : customerFirstName.trim()}
              </h2>
              <p>
                {customerStore.postalCode}, {customerStore.county}
              </p>
            </div>
          </ProgressContainer>
          <StepperWrapper>
            <InfoStepsStepper stepDetails={stepDetails} />
          </StepperWrapper>
        </Sidebar>
        <CardWrapper>
          <Card>
            <Outlet />
          </Card>
        </CardWrapper>
      </Container>
    </LayoutComponent>
  )
}

export default observer(SecureComponent(InfoStepsPage))
