import { useNavigate, useSearchParams } from 'react-router-dom'

import AddDoctor from './AddDoctor.component'
import BottomStepNavigator from '../../BottomStepNavigator.component'
import Question from '../Common/Question'
import React from 'react'
import RouteConstants from '../../../../constants/RouteConstants'
import SecureComponent from '../../../../pages/common/SecureComponent'
import customerStore from '../../../../datastore/CustomerStore'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'

interface DoctorScreenProps {
  showBottomStepNavigator?: boolean
  redirect?: boolean
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const DoctorsScreen: React.FC<DoctorScreenProps> = ({
  showBottomStepNavigator = true,
  redirect = true,
}) => {
  const [questionResponse, setQuestionResponse] = React.useState('')
  const [forSpouse, setForSpouse] = React.useState(false)
  const [searchParams] = useSearchParams()

  React.useEffect(() => {
    setForSpouse(searchParams.get('forSpouse') === 'true')
  }, [searchParams])

  const navigate = useNavigate()

  const handleNext = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (customerStore.getSpouseOptIn() === false || forSpouse) {
      navigate(RouteConstants.INFO_STEPS_CURRENT_PLAN)
    } else if (customerStore.getSpouseOptIn() && !forSpouse) {
      navigate(`${RouteConstants.INFO_STEPS_DOCTORS}/?forSpouse=true`)
    }
  }

  return (
    <Container>
      {questionResponse === 'Yes' ? (
        <AddDoctor
          isSpouse={forSpouse}
          handleNext={handleNext}
          isInfoSteps={true}
        />
      ) : (
        <Question
          handleYes={() => setQuestionResponse('Yes')}
          handleNo={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            navigate(RouteConstants.INFO_STEPS_CURRENT_PLAN)
          }}
          width='500px'
          heading='Do you have a Primary Care Physician who you’d like to be covered in your plan?'
          description='This can help us in suggesting the right plan for you'
        />
      )}
      {showBottomStepNavigator && (
        <BottomStepNavigator
          handleNext={handleNext}
          handleBack={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            if (forSpouse) {
              navigate(RouteConstants.INFO_STEPS_DOCTORS)
            } else {
              navigate(RouteConstants.INFO_STEPS_PHARMACY)
            }
          }}
          handleViewPlans={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            navigate(RouteConstants.DASHBOARD)
          }}
          disableNext={questionResponse === 'Yes' ? false : true}
          currentInfoStep='Physicians'
        />
      )}
    </Container>
  )
}

export default SecureComponent(observer(DoctorsScreen))
