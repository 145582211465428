import { MSPlan } from '../../../../interface/quotes/mSupplementPlans/MSPlan'
import MedicareSupplementCard from './MedicareSupplementCard'
import React from 'react'
import styled from '@emotion/styled'

interface RecommendedMSCardsProps {
  recommendedPlan: MSPlan
  goodChoicePlan: MSPlan | undefined
  onEnroll: () => void
  handleRemoveFavorite?: () => void
  showCompareCheckbox?: boolean
  userOption: boolean
  handleSpouseRemove: () => void
  setShowAddSpouseConfirmation: (flag: boolean) => void
  setCompletePlanDetailModalActive: (flag: boolean) => void
  setCompleteDetailPlanId: (planId: string) => void
  medigapChartDetails: any
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media screen and (max-width: 1200px) {
    display: block;
  }
`

const RecommendedMSCards: React.FC<RecommendedMSCardsProps> = (props) => {
  return (
    <Container>
      <div>
        <MedicareSupplementCard
          userOption={props.userOption}
          msPlan={props.recommendedPlan}
          onEnroll={props.onEnroll}
          handleSpouseRemove={props.handleSpouseRemove}
          setShowAddSpouseConfirmation={props.setShowAddSpouseConfirmation}
          setCompleteDetailPlanId={props.setCompleteDetailPlanId}
          setCompletePlanDetailModalActive={
            props.setCompletePlanDetailModalActive
          }
          medigapChartDetails={props.medigapChartDetails}
          isRecommended
        />
      </div>
      {props.goodChoicePlan && (
        <div>
          <MedicareSupplementCard
            userOption={props.userOption}
            msPlan={props.goodChoicePlan}
            onEnroll={props.onEnroll}
            handleSpouseRemove={props.handleSpouseRemove}
            setShowAddSpouseConfirmation={props.setShowAddSpouseConfirmation}
            setCompleteDetailPlanId={props.setCompleteDetailPlanId}
            setCompletePlanDetailModalActive={
              props.setCompletePlanDetailModalActive
            }
            medigapChartDetails={props.medigapChartDetails}
            isGoodChoice
          />
        </div>
      )}
    </Container>
  )
}

export default RecommendedMSCards
