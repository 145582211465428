import {
  addResponsesForQuestionnaires,
  getQuestionnaires,
} from '../../../service/questionnaire'
import { getCustomerDetails, updateInfoStep } from '../../../service/customer'

import { ActionContext } from '../../../cra'
import Button from '../../common/Button/Button.component'
import CheckboxQuestion from '../common/CheckboxQuestion'
import { InfoStepEnum } from '../../../enums/InfoStepEnum'
import { QuestionnaireI } from '../../../interface/questionnaire/QuestionnaireInterface'
import RadioQuestion from '../common/RadioQuestion'
import React from 'react'
import SectionRow from '../../common/SectionRow'
import SecureComponent from '../../../pages/common/SecureComponent'
import SkeletonCard from '../../DashboardPage/SkeletonCard'
import { SnackbarTypes } from '../../../enums/SnackbarTypesEnum'
import StringConstants from '../../../constants/StringConstants'
import customerStore from '../../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../../utils/StringUtils'
import { observer } from 'mobx-react'
import snackbarStore from '../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'
import { withRouter } from '../../common/WithRouter/WithRouter.component'

const Container = styled.div`
  width: 100%;
  padding: 20px 0px;
`

const Health = () => {
  const currentRoute = useLocation().pathname
  const [forSpouse, setForSpouse] = React.useState(
    currentRoute.split('/')[2] === 'myprofile' ? false : true
  )
  const [healthQuestionData, setHealthQuestionData] = React.useState<any>([])
  const [healthResponseData, setHealthResponseData] = React.useState<any>([])

  const [state, setState] = React.useState({
    loading: true,
    buttonLoading: false,
  })

  const { trackCurrentPage } = React.useContext(ActionContext)

  const [isCoverageFieldVisible, setIsCoverageFieldVisible] =
    React.useState(false)
  const [coverages, setCoverages] = React.useState<string>('')
  const [coverageError, setCoverageError] = React.useState(false)
  const [coverageHelperText, setCoverageHelperText] = React.useState<string>('')

  React.useEffect(() => {
    currentRoute.split('/')[2] === 'myprofile'
      ? setForSpouse(false)
      : setForSpouse(true)
  }, [currentRoute])

  React.useEffect(() => {
    trackCurrentPage(
      forSpouse
        ? 'Profile - Spouse/Partner’s Health Questions'
        : 'Profile - Health Questions'
    )

    getQuestionnaires(forSpouse, 'HEALTH')
      .then((res: QuestionnaireI[]) => {
        const response = res.sort(function (a: any, b: any) {
          if (a.displayOrder > b.displayOrder) {
            return 1
          } else {
            return -1
          }
        })

        setHealthQuestionData(response)

        const resultArr = [
          ...response.map((data: any) => {
            return {
              id: data.id,
              inputType: data.inputType,
              isRequired: data.isRequired,
              options: data.options,
              question: data.question,
              response:
                data.responses.length > 0 ? data.responses[0].response : [],
            }
          }),
        ]

        setHealthResponseData(resultArr)
        setState({
          ...state,
          loading: false,
        })
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('fetch health questionnaire'),
          snackbarOpen: true,
          snackbarType: 'error',
        })
      })
  }, [forSpouse])

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])

  const fetchCustomerDetails = async (
    doNotSuppress = true
  ): Promise<boolean> => {
    let success = false
    getCustomerDetails()
      .then((response) => {
        const data = response.data.data
        customerStore.set(data)
        success = true
        return success
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('fetch customer details'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
        return success
      })
    return success
  }

  const handleResponseSelection = (id: any, responseArray: any) => {
    const updatedHealthResponse = healthResponseData.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          response: responseArray,
        }
      } else {
        return item
      }
    })
    setHealthResponseData(updatedHealthResponse)
  }

  const checkResponseSelectionCorrectness = () => {
    if (
      (isCoverageFieldVisible && coverages.length === 0) ||
      coverages.length > 255
    )
      return false

    const inCorrectResponses = healthResponseData.find(
      (responseObject: any) =>
        responseObject.isRequired &&
        responseObject.response.length === 0 &&
        (responseObject.inputType === 'RADIO' ||
          responseObject.inputType === 'CHECKBOX')
    )

    if (inCorrectResponses) {
      return false
    } else {
      return true
    }
  }

  const handleSaveResponse = () => {
    if (checkResponseSelectionCorrectness()) {
      setState({
        ...state,
        buttonLoading: true,
      })

      addResponsesForQuestionnaires(forSpouse, healthQuestionData)
        .then((res) => {
          snackbarStore.set({
            snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
            snackbarOpen: true,
            snackbarType: 'success',
          })
          if (forSpouse === false) {
            updateInfoStep(InfoStepEnum.HEALTH_QUESTION)
              .then(async (res) => {
                if ((await fetchCustomerDetails(false)) === true) {
                  customerStore.setInfoSteps([
                    ...customerStore.infoSteps,
                    'healthQuestion',
                  ])
                }
              })
              .catch((err) => {
                snackbarStore.set({
                  snackbarMessage: 'Something went wrong',
                  snackbarOpen: true,
                  snackbarType: 'error',
                })
              })
          }
        })
        .catch((err) => {
          snackbarStore.set({
            snackbarMessage: getApiErrorMessage('save health questionnaire'),
            snackbarOpen: true,
            snackbarType: 'error',
          })
        })
        .finally(() => {
          setState({
            ...state,
            buttonLoading: false,
          })
        })
    } else {
      if (coverages.length > 255) {
        snackbarStore.set({
          snackbarMessage:
            StringConstants.UI_ERROR_MSG_FOR_MORE_CHARACTERS_THAN_SF_LIMIT,
          snackbarOpen: true,
          snackbarType: 'warning',
        })
      } else {
        snackbarStore.set({
          snackbarMessage: 'Some fields are empty',
          snackbarOpen: true,
          snackbarType: 'warning',
        })
      }
    }
  }

  return (
    <Container>
      {state.loading ? (
        <SectionRow>
          <SkeletonCard quantity={10} />
        </SectionRow>
      ) : (
        <>
          <SectionRow>
            {healthQuestionData.map((data: any) => (
              <div key={data.id}>
                {data.inputType === 'RADIO' && (
                  <RadioQuestion
                    options={data.options}
                    value={
                      data.responses &&
                      data.responses.length > 0 &&
                      data.responses[0].response &&
                      data.responses[0].response.length > 0 &&
                      data.responses[0].response[0]
                    }
                    question={data.question}
                    isRequired={data.isRequired}
                    id={data.id}
                    handleResponseSelection={handleResponseSelection}
                    radioGroupName={data.question || ''}
                  />
                )}
                {data.inputType === 'CHECKBOX' && (
                  <CheckboxQuestion
                    response={
                      data.responses &&
                      data.responses.length > 0 &&
                      data.responses[0].response &&
                      data.responses[0].response
                    }
                    options={data.options}
                    id={data.id}
                    question={data.question}
                    isRequired={data.isRequired}
                    handleResponseSelection={handleResponseSelection}
                    checkboxGroupName={data.question || ''}
                    displayOrder={data.displayOrder}
                    isCoverageFieldVisible={isCoverageFieldVisible}
                    coverages={coverages}
                    setIsCoverageFieldVisible={setIsCoverageFieldVisible}
                    setCoverages={setCoverages}
                    coverageError={coverageError}
                    coverageHelperText={coverageHelperText}
                    setCoverageError={setCoverageError}
                    setCoverageHelperText={setCoverageHelperText}
                  />
                )}
              </div>
            ))}
          </SectionRow>
          <SectionRow>
            <Button
              color='secondary'
              variant='contained'
              onClick={handleSaveResponse}
              loading={state.buttonLoading}
              disabled={state.buttonLoading}
            >
              Save Changes
            </Button>
          </SectionRow>
        </>
      )}
    </Container>
  )
}

export default SecureComponent(withRouter(observer(Health)))
