import { APIResponse } from '../interface/APIResponseInterface'
import ApiManager from '../api/ApiManager'
import { AxiosResponse } from 'axios'
import { HttpMethods } from '../enums/HttpMethodsEnum'
import { MAPlanFiltersRequest } from '../interface/quotes/mNonSupplementPlans/medicareAdvantage/request/MAPlanFiltersRequest'
import { MNonSupplementPlan } from '../interface/quotes/mNonSupplementPlans/MNonSupplementPlan'
import { MParDPlanFiltersRequest } from '../interface/quotes/mNonSupplementPlans/medicarePartD/request/MPartDPlanFiltersRequest'
import { MSPlan } from '../interface/quotes/mSupplementPlans/MSPlan'
import { MSPlanRequestFilter } from '../interface/quotes/mSupplementPlans/request/MSPlanRequestFilter'
import { MedicareQuotesType } from '../enums/MedicareQuoteTypeEnum'
import { QuotesPullStatus } from '../interface/quotes/QuotesPullStatus'
import StringConstants from '../constants/StringConstants'
import UrlConstants from '../constants/UrlConstants'
import { isEmpty } from 'lodash'
import snackbarStore from '../datastore/SnackbarStore'

export const getPlanCount = (planYear?: number): Promise<QuotesPullStatus> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_MEDICARE_PLANS_COUNT.USECASE,
      HttpMethods.GET_METHOD,
      planYear ? { 'plan-year': planYear } : {}
    )
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else if (response.status === 401) {
          reject(
            new Error(
              'User logged out, hence unable to fetch Quotes Pull Status.'
            )
          )
        } else reject(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getSavedPlans = (
  forSpouse: boolean
): Promise<
  | {
      convertedMAPlans: MNonSupplementPlan[]
      convertedMPartDPlans: MNonSupplementPlan[]
      convertedMSPlans: MSPlan[]
    }
  | boolean
> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_SAVED_PLANS.USECASE,
      HttpMethods.GET_METHOD,
      { $forSpouse: forSpouse }
    )
      .then((response) => {
        if (response.status === 200) {
          const convertedMAPlans: MNonSupplementPlan[] = []
          const convertedMPartDPlans: MNonSupplementPlan[] = []
          const convertedMSPlans: MSPlan[] = []
          if (response.data.data.length > 0) {
            response.data.data.forEach((plan: any) => {
              if (plan.medicareQuoteType === 'medicareNonSupplement') {
                if (plan.mnsPlan.type === 'PD')
                  convertedMPartDPlans.push(plan.mnsPlan)
                else convertedMAPlans.push(plan.mnsPlan)
              } else if (plan.medicareQuoteType === 'medicareSupplement') {
                convertedMSPlans.push(plan.msPlan)
              }
            })
            resolve({
              convertedMAPlans,
              convertedMPartDPlans,
              convertedMSPlans,
            })
          } else {
            snackbarStore.set({
              snackbarMessage: 'No Plans have been saved',
              snackbarOpen: true,
              snackbarType: 'success',
            })
            resolve(false)
          }
        } else {
          reject(response)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const savePlan = (
  planId: string,
  planType: MedicareQuotesType,
  forSpouse: boolean
): Promise<string> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.SAVE_PLAN.USECASE,
      HttpMethods.POST_METHOD,
      {
        planId,
        medicareQuoteType: planType,
        forSpouse,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
            snackbarType: 'success',
          })
          resolve(response.data.data)
        } else reject(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const undoSavePlan = (
  planId: string,
  planType: MedicareQuotesType,
  forSpouse: boolean
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.DELETE_SAVED_PLAN.USECASE,
      HttpMethods.DELETE_METHOD,
      {
        $planId: planId,
        'quote-type': planType,
        'for-spouse': forSpouse,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else reject(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getMNSPlansFilters = <T>(
  quoteType: MedicareQuotesType,
  planYear: string,
  forSpouse: boolean
): Promise<T> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      quoteType === MedicareQuotesType.MEDICARE_ADVANTAGE
        ? UrlConstants.GET_MEDICARE_ADVANTAGE_PLANS_FILTERS.USECASE
        : UrlConstants.GET_MEDICARE_PART_D_PLANS_FILTERS.USECASE,
      HttpMethods.GET_METHOD,
      {
        'filter-year': planYear,
        'for-spouse': forSpouse,
      }
    )
      .then((response: APIResponse<T>) => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else reject(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getMAPlans = (payload: MAPlanFiltersRequest): Promise<any> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_MEDICARE_ADVANTAGE_PLANS.USECASE,
      HttpMethods.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getMPartDPPlans = (
  payload: MParDPlanFiltersRequest
): Promise<any> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_MEDICARE_PART_D_PLANS.USECASE,
      HttpMethods.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getMSFilters = (forSpouse: boolean): Promise<any> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_MEDICARE_SUPPLEMENT_PLANS_FILTERS.USECASE,
      HttpMethods.GET_METHOD,
      {
        'for-spouse': forSpouse,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else {
          reject(response)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getMSPlans = (
  msPlansFiltersPayload?: MSPlanRequestFilter
): Promise<any> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_MEDICARE_SUPPLEMENT_PLANS.USECASE,
      HttpMethods.POST_METHOD,
      msPlansFiltersPayload ? msPlansFiltersPayload : {}
    )
      .then((msPlansResponse) => {
        if (msPlansResponse.status === 200) {
          resolve(msPlansResponse.data)
        } else {
          reject(msPlansResponse)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getMedigapChartDetails = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCall(
      UrlConstants.GET_MEDIGAP_CHART_DETAILS.USECASE,
      HttpMethods.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else {
          reject(response)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updateEnrollmentRequest = (
  planId: string,
  quoteType: MedicareQuotesType,
  forSpouse: boolean,
  preferredPhoneNumber: string,
  riders?: string
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const payload: any = {
      $planId: planId,
      quoteType,
      enrollRequested: true,
      versionId: process.env.REACT_APP_VERSION_ID,
      forSpouse,
      preferredPhoneNumber,
    }

    if (!isEmpty(riders)) payload['rider'] = riders

    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.PATCH_ENROLL_REQUESTED.USECASE,
      HttpMethods.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response)
        }
      })
      .catch((Err) => {
        reject(Err)
      })
  })
}

export const getEnrollmentCount = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_ENROLLMENT_COUNT.USECASE,
      HttpMethods.GET_METHOD,
      { 'for-spouse': false }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else reject(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getCompleteDetailsOfMNSPlans = (planId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_COMPLETE_PLAN_DETAILS_SF.USECASE,
      HttpMethods.GET_METHOD,
      {
        $planId: planId,
      }
    )
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else reject(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getCompleteDetailOfMSPlan = (planId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_MEDICARE_SUPPLEMENT_COMPLETE_PLAN_DETAILS.USECASE,
      HttpMethods.GET_METHOD,
      {
        $planId: planId,
      }
    )
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else reject(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
