import {
  addResponsesForQuestionnaires,
  getQuestionnaires,
} from '../../../../service/questionnaire'
import { useNavigate, useSearchParams } from 'react-router-dom'

import BottomStepNavigator from '../../BottomStepNavigator.component'
import CheckboxQuestion from '../Common/CheckboxQuestion'
import { InfoStepEnum } from '../../../../enums/InfoStepEnum'
import { QuestionnaireI } from '../../../../interface/questionnaire/QuestionnaireInterface'
import RadioQuestion from '../Common/RadioQuestion'
import React from 'react'
import RouteConstants from '../../../../constants/RouteConstants'
import SecureComponent from '../../../../pages/common/SecureComponent'
import SkeletonCard from '../../../DashboardPage/SkeletonCard'
import StringConstants from '../../../../constants/StringConstants'
import customerStore from '../../../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../../../utils/StringUtils'
import { observer } from 'mobx-react'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import { updateInfoStep } from '../../../../service/customer'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const Heading = styled.h3`
  font-size: 20px;
  font-weight: 700px;
  padding-bottom: 20px;
  color: ${theme.colors.primary};
`

const Description = styled.p`
  padding-bottom: 20px;
`

const QuestionNumber = styled.p`
  font-weight: 700;
`
const Question = styled.div`
  padding: 10px 0px;
`

const HealthQuestion: React.FC = () => {
  const [activeStep, setActiveStep] = React.useState(0)
  const [healthQuestionData, setHealthQuestionData] = React.useState<any>([])
  const [healthResponseData, setHealthResponseData] = React.useState<any>([])
  const [state, setState] = React.useState({
    buttonLoading: false,
  })

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isCoverageFieldVisible, setIsCoverageFieldVisible] =
    React.useState(false)
  const [coverages, setCoverages] = React.useState<string>('')
  const [coverageError, setCoverageError] = React.useState(false)
  const [coverageHelperText, setCoverageHelperText] = React.useState<string>('')
  const [forSpouse, setForSpouse] = React.useState(false)

  React.useEffect(() => {
    setForSpouse(searchParams.get('forSpouse') === 'true')
  }, [searchParams])

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })

    getQuestionnaires(forSpouse, 'HEALTH')
      .then((res: QuestionnaireI[]) => {
        const response = res.sort(function (a: any, b: any) {
          if (a.displayOrder > b.displayOrder) {
            return 1
          } else {
            return -1
          }
        })

        setHealthQuestionData(response)

        const resultArr = [
          ...response.map((data: any) => {
            return {
              id: data.id,
              inputType: data.inputType,
              isRequired: data.isRequired,
              options: data.options,
              question: data.question,
              response:
                data.responses.length > 0 ? data.responses[0].response : [],
            }
          }),
        ]

        setHealthResponseData(resultArr)
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('fetch health questionnaire'),
          snackbarOpen: true,
          snackbarType: 'error',
        })
      })
  }, [forSpouse])

  const handleResponseSelection = (id: any, responseArray: any) => {
    const updatedHealthResponse = healthResponseData.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          response: responseArray,
        }
      } else {
        return item
      }
    })

    setHealthResponseData(updatedHealthResponse)
  }

  const checkResponseSelectionCorrectness = () => {
    if (
      healthResponseData[3].response.length > 0 &&
      healthQuestionData[3].responses.length > 0 &&
      healthQuestionData[3].responses[0].response[0] !==
        healthResponseData[3].response[0]
    )
      customerStore.isMSQuotesProcessing = true
    if (
      (isCoverageFieldVisible && coverages.length === 0) ||
      coverages.length > 255
    )
      return false

    const inCorrectResponses = healthResponseData.find(
      (responseObject: any) =>
        responseObject.isRequired &&
        responseObject.response.length === 0 &&
        responseObject.inputType === 'RADIO'
    )

    if (inCorrectResponses) {
      return false
    } else {
      return true
    }
  }

  const handleSaveResponse = async () => {
    if (checkResponseSelectionCorrectness()) {
      try {
        setState({
          ...state,
          buttonLoading: true,
        })
        await addResponsesForQuestionnaires(forSpouse, healthResponseData)
        if (customerStore.getSpouseOptIn() === false || forSpouse) {
          snackbarStore.set({
            snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
            snackbarOpen: true,
            snackbarType: 'success',
          })
          await updateInfoStep(InfoStepEnum.HEALTH_QUESTION)
          navigate(RouteConstants.INFO_STEPS_PHARMACY)
        } else if (customerStore.getSpouseOptIn() && !forSpouse) {
          setActiveStep(0)
          navigate(`${RouteConstants.INFO_STEPS_HEALTH}/?forSpouse=true`)
        }
      } catch (error) {
        snackbarStore.set({
          snackbarMessage: 'Something went wrong',
          snackbarOpen: true,
          snackbarType: 'error',
        })
      } finally {
        setState({
          ...state,
          buttonLoading: false,
        })
      }
    } else {
      if (coverages.length > 255) {
        snackbarStore.set({
          snackbarMessage:
            StringConstants.UI_ERROR_MSG_FOR_MORE_CHARACTERS_THAN_SF_LIMIT,
          snackbarOpen: true,
          snackbarType: 'warning',
        })
      } else {
        snackbarStore.set({
          snackbarMessage: 'Some fields are empty',
          snackbarOpen: true,
          snackbarType: 'warning',
        })
      }
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleNextQuestion = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (activeStep < healthQuestionData.length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBackQuestion = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return healthQuestionData.length === 0 ? (
    <SkeletonCard />
  ) : (
    <Container>
      <Heading>
        Please answer a few simple health related questions{' '}
        {forSpouse ? 'for your spouse/partner' : ''}
      </Heading>
      {activeStep < healthQuestionData.length && (
        <>
          <Description>
            {healthQuestionData[activeStep].description}
          </Description>
          <QuestionNumber>
            Question {healthQuestionData[activeStep].displayOrder}/
            {healthQuestionData.length}
          </QuestionNumber>
          <Question>
            {healthQuestionData[activeStep].question}{' '}
            {healthQuestionData[activeStep].isRequired && (
              <span style={{ color: theme.colors.secondary }}>*</span>
            )}
          </Question>
          {healthQuestionData[activeStep].inputType === 'RADIO' && (
            <RadioQuestion
              radioButtonType='squared'
              value={
                (healthResponseData[activeStep] &&
                  healthResponseData[activeStep].response &&
                  healthResponseData[activeStep].response.length > 0 &&
                  healthResponseData[activeStep].response[0]) ||
                (healthQuestionData[activeStep].responses &&
                  healthQuestionData[activeStep].responses.length > 0 &&
                  healthQuestionData[activeStep].responses[0].response &&
                  healthQuestionData[activeStep].responses[0].response.length >
                    0 &&
                  healthQuestionData[activeStep].responses[0].response[0])
              }
              options={healthQuestionData[activeStep].options}
              id={healthQuestionData[activeStep].id}
              handleResponseSelection={handleResponseSelection}
              radioGroupName={
                healthQuestionData[activeStep].question || Math.random()
              }
            />
          )}
          {healthQuestionData[activeStep].inputType === 'CHECKBOX' && (
            <CheckboxQuestion
              response={
                (healthResponseData[activeStep] &&
                  healthResponseData[activeStep].response) ||
                (healthQuestionData[activeStep].responses &&
                  healthQuestionData[activeStep].responses[0].response &&
                  healthQuestionData[activeStep].responses[0].response)
              }
              options={healthQuestionData[activeStep].options}
              id={healthQuestionData[activeStep].id}
              handleResponseSelection={handleResponseSelection}
              checkboxGroupName={healthQuestionData[activeStep].question || ''}
              displayOrder={healthQuestionData[activeStep].displayOrder}
              isCoverageFieldVisible={isCoverageFieldVisible}
              coverages={coverages}
              setIsCoverageFieldVisible={setIsCoverageFieldVisible}
              setCoverages={setCoverages}
              coverageError={coverageError}
              coverageHelperText={coverageHelperText}
              setCoverageError={setCoverageError}
              setCoverageHelperText={setCoverageHelperText}
            />
          )}

          <BottomStepNavigator
            handleNext={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' })
              if (activeStep < healthQuestionData.length - 1)
                handleNextQuestion()
              else handleSaveResponse()
            }}
            handleBack={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' })
              if (activeStep === 0 && !forSpouse) {
                navigate(RouteConstants.INFO_STEPS_PRESCRIPTION)
              } else if (activeStep === 0 && forSpouse) {
                navigate(RouteConstants.INFO_STEPS_HEALTH)
              } else handleBackQuestion()
            }}
            handleViewPlans={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' })
              navigate(RouteConstants.DASHBOARD)
            }}
            disableNext={
              healthQuestionData[activeStep].isRequired &&
              healthResponseData[activeStep]?.response.length === 0
            }
            isLoading={state.buttonLoading}
            currentInfoStep='Health Questions'
          />
        </>
      )}
    </Container>
  )
}

export default SecureComponent(observer(HealthQuestion))
