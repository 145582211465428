import {
  IoIosArrowDown as ArrowDown,
  IoIosArrowUp as ArrowUp,
} from 'react-icons/io'
import {
  AiOutlineCheck as Covered,
  AiOutlineClose as NotCovered,
} from 'react-icons/ai'
import { Divider, IconButton } from '@mui/material'

import Icon from '../common/Icon/Icon.component'
import Tooltip from '../common/Tooltip/Tooltip.component'
import { isEmpty } from 'lodash'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useNavigate } from 'react-router-dom'

interface CollapsibleCoverageProps {
  heading: string
  toolTipContent: string
  isContentExpanded: boolean
  setIsContentExpanded: (flag: boolean) => void
  subtitle?: string
  hyperlinkDetails?: {
    path: string
    label: string
  }
  coveragesData: {
    name: string
    covered: boolean
  }[]
  isSpouseSection?: boolean
  spouseCoverageData?: {
    name: string
    covered: boolean
  }[]
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 40px;
  @media screen and (max-width: 600px) {
    padding: 10px 20px;
  }
`

const Content = styled.div`
  padding: 20px 0px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  p {
    color: ${theme.colors.textGrey};
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 2px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    font-size: 14px;
    font-weight: 700;
    color: ${theme.colors.textDark};

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: initial;
    }
  }
`

const NavigationWrapper = styled.div`
  text-decoration: underline;
  font-weight: 600;
  color: ${theme.colors.primary};
  font-size: 14px;
  cursor: pointer;
`

const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 500;
`

const Coverage: React.FC<CollapsibleCoverageProps> = ({
  heading,
  toolTipContent,
  isContentExpanded,
  setIsContentExpanded,
  hyperlinkDetails = undefined,
  coveragesData,
  isSpouseSection: containsSpouseInfo = false,
  spouseCoverageData = undefined,
  subtitle = '',
}) => {
  const navigate = useNavigate()
  return (
    <>
      <Container>
        <Header>
          <div className='title'>
            <div>
              <p>
                {heading} <Tooltip title={toolTipContent} />
              </p>
            </div>
            {hyperlinkDetails && (
              <NavigationWrapper
                onClick={() => {
                  navigate({
                    pathname: hyperlinkDetails.path,
                  })
                }}
              >
                + {hyperlinkDetails.label}
              </NavigationWrapper>
            )}
          </div>
          <IconButton
            onClickCapture={() => {
              setIsContentExpanded(!isContentExpanded)
            }}
          >
            {isContentExpanded ? (
              <ArrowUp color={theme.colors.primary} />
            ) : (
              <ArrowDown color={theme.colors.primary} />
            )}
          </IconButton>
        </Header>
        {isContentExpanded && (
          <>
            {!isEmpty(coveragesData) && (
              <>
                {containsSpouseInfo && (
                  <Subtitle className='subTitle'>Primary {subtitle}</Subtitle>
                )}

                <Content>
                  {coveragesData.map((coverageData, index) => {
                    return (
                      <p>
                        {coverageData.covered ? (
                          <Icon
                            childComponent={<Covered />}
                            color={theme.colors.success}
                            fontSize='12px'
                          />
                        ) : (
                          <Icon
                            childComponent={<NotCovered />}
                            color={theme.colors.danger}
                            fontSize='12px'
                          />
                        )}
                        <span>{coverageData.name}</span>
                      </p>
                    )
                  })}
                </Content>
              </>
            )}

            {containsSpouseInfo && !isEmpty(spouseCoverageData) ? (
              <div>
                <Subtitle className='subTitle'>Spouse's {subtitle}</Subtitle>
                <Content>
                  {spouseCoverageData?.map((coverageData, index) => {
                    return (
                      <p>
                        {coverageData.covered ? (
                          <Icon
                            childComponent={<Covered />}
                            color={theme.colors.success}
                            fontSize='12px'
                          />
                        ) : (
                          <Icon
                            childComponent={<NotCovered />}
                            color={theme.colors.danger}
                            fontSize='12px'
                          />
                        )}
                        <span>{coverageData.name}</span>
                      </p>
                    )
                  })}
                </Content>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </Container>
      <Divider orientation='horizontal' flexItem />
    </>
  )
}

export default Coverage
