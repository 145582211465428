import React, { useState } from 'react'

import { MedicareConstants } from '../../../../constants/MedicareConstants'
import Select from '../../../common/Select/Select.component'
import currentPlanStore from '../../../../datastore/CurrentPlanStore'
import { isEmpty } from 'lodash'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface SelectSupplementPlanProps extends ContainerProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  disableMainNext?: () => void
  enableMainNext?: () => void
  handleHasNext?: (value: boolean) => void
  forSpouse: boolean
}

interface ContainerProps {
  width?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  @media screen and (max-width: 600px) {
    padding: 10px 5px;
    width: 100%;
  }
`

const Heading = styled.h3`
  font-size: 20px;
  font-weight: 700px;
  padding-bottom: 20px;
  color: ${theme.colors.primary};
`

const Description = styled.p`
  font-weight: 600;
  text-align: left;
`

const SelectWrapper = styled.div<{ screenWidth: number }>`
  max-width: 465px;
  width: 100%;
  flex: 1;
  align-items: center;
  margin-top: 10px;
  .MuiSelect-select {
    display: block;
    left: auto !important;
    text-align: left !important;
  }
  @media screen and (min-width: 601px) and (max-width: 750px) {
    .MuiPopover-paper {
      min-width: ${(props) =>
    ((750 - props.screenWidth) / 50) * 4 + 60}% !important;
    }
  }

  @media screen and (max-width: 600px) {
    .MuiPopover-paper {
      min-width: ${(props) =>
    90 - ((600 - props.screenWidth) / 50) * 1.75}% !important;
    }
  }
`
const SelectSupplementPlan: React.FC<SelectSupplementPlanProps> = ({
  onClick,
  disableMainNext,
  enableMainNext,
  handleHasNext,
  forSpouse
}) => {
  const [plans] = React.useState<string[]>(
    MedicareConstants.CARRIER_LIST.concat(MedicareConstants.otherOption)
  )
  const [dimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })
  const [selectedCarrier, setSelectedCarrier] = useState<string>(
    forSpouse ? currentPlanStore.spouseQuoteType === MedicareConstants.INSURANCE_TYPE.MS
      ? currentPlanStore.spouseProvider
      : '' :
      currentPlanStore.get().quoteType === MedicareConstants.INSURANCE_TYPE.MS
        ? currentPlanStore.get().provider
        : ''
  )

  React.useEffect(() => {
    if (forSpouse) {
      if (
        currentPlanStore.spouseQuoteType === MedicareConstants.INSURANCE_TYPE.MS &&
        currentPlanStore.spouseProvider
      )
        currentPlanStore.setSpouseProvider(currentPlanStore.spouseProvider)
      if (currentPlanStore.spousePlanType !== '') {
        enableMainNext && enableMainNext()
      }
    } else {
      if (
        currentPlanStore.quoteType === MedicareConstants.INSURANCE_TYPE.MS &&
        currentPlanStore.provider
      )
        currentPlanStore.setProvider(currentPlanStore.provider)
      if (currentPlanStore.get().planType !== '') {
        enableMainNext && enableMainNext()
      }
    }
  })

  const handlePlanSelection = (e: any) => {
    setSelectedCarrier(e)
    if (forSpouse) {
      currentPlanStore.setSpouseProvider(e)
    } else
      currentPlanStore.setProvider(e)
    enableMainNext && enableMainNext()
  }

  React.useEffect(() => {
    handleHasNext && handleHasNext(false)
    if (forSpouse) {
      if (!isEmpty(currentPlanStore.spouseProvider)) {
        enableMainNext && enableMainNext()
      } else {
        disableMainNext && disableMainNext()
      }
    } else {
      if (!isEmpty(currentPlanStore.get().provider)) {
        enableMainNext && enableMainNext()
      } else {
        disableMainNext && disableMainNext()
      }
    }
  })

  return (
    <Container>
      <Heading>Please choose your Medicare Supplement (Medigap) Plan</Heading>
      <Description>Select your health care provider</Description>
      <SelectWrapper screenWidth={dimensions.width}>
        <Select
          formLabel='Select your carrier'
          options={plans}
          onChange={(e) => {
            handlePlanSelection(e.target.value)
          }}
          height='39px'
          value={selectedCarrier}
          width='100%'
          isInfoSteps={true}
        ></Select>
      </SelectWrapper>
    </Container>
  )
}

export default SelectSupplementPlan
